import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { logout } from '../auth/Login';
import { Modal } from 'react-bootstrap';
import './Support.css';
import Swal from 'sweetalert2';

const Support = () => {
    const navigate = useNavigate();
    const [supportTimes, setSupportTimes] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedSupportTime, setSelectedSupportTime] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showNoteModal, setShowNoteModal] = useState(false);
    const [noteMessage, setNoteMessage] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
const [totalPages, setTotalPages] = useState(0);


    useEffect(() => {
        const token = localStorage.getItem('token');
        const user_type = localStorage.getItem('user_type');

        if (!token) {
            logout(navigate);
            return;
        }

        if (user_type === "referee" || user_type === "client") {
            navigate(-1);
            return;
        }
        fetchSupportTimes();
    }, [navigate]);

    const fetchSupportTimes = (page = 1) => {
        const token = localStorage.getItem('token');
        if (!token) {
            logout(navigate);
            return;
        }
        axios.get(`https://te.prize.pap.sa/api/support-times?page=${page}`, {
            headers: { Authorization: `Bearer ${token}` }
        })
        .then(response => {
            setSupportTimes(response.data.data);
            setTotalPages(response.data.meta.last_page); // Adjusted to access nested 'last_page'
            setCurrentPage(response.data.meta.current_page);
        })
            .catch(error => {
                console.error('There was an error fetching the data', error);
            });
    };
    
    const PaginationControls = () => {
        const pages = Array.from({ length: totalPages }, (_, i) => i + 1);
    
        return (
            <nav>
                <ul className="pagination text-black">
                    {pages.map(page => (
                        <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                            <button className="page-link" onClick={() => fetchSupportTimes(page)}>
                                {page}
                            </button>
                        </li>
                    ))}
                </ul>
            </nav>
        );
    };
    



    const handleDelete = (id) => {
        setSuccessMessage('');
        setErrorMessage('');
        axios.delete(`https://te.prize.pap.sa/api/support-times/${id}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        })
            .then(response => {
                fetchSupportTimes();
                setSuccessMessage(response.data.success); // Set success message
            })
            .catch(error => {
                console.error('Error deleting item', error);
                setErrorMessage(error.response.data.error); // Set error message
            });
    };

    const handleView = (id) => {
        axios.get(`https://te.prize.pap.sa/api/support-times/${id}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        })
            .then(response => {
                setSelectedSupportTime(response.data.data);
                setShowModal(true);
            })
            .catch(error => {
                console.error('Error fetching details', error);
            });
    };


    const handleSendNote = () => {
       
        setShowNoteModal(true);
    };

    const handleSend = () => {
        if (!noteMessage.trim()) {
            console.error("Message is empty");
            return;
        }
    
        if (!selectedSupportTime || !selectedSupportTime.user || !selectedSupportTime.user.email) {
            console.error("No email address available");
            return;
        }
    
        const email = selectedSupportTime.user.email;
       
    
        axios.post('https://te.prize.pap.sa/api/send-email', {
            email: email,
            message: noteMessage
        }, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        })
        .then(response => {
           

            Swal.fire(
                'Success',
                'Email sent successfully!',
                'success'
            );
        })
        .catch(error => {
            console.error("Error sending email:", error);
            
            Swal.fire(
                'Error',
                'Failed to send email.',
                'error'
            );
        });
    
        // Reset state and close modal
        setNoteMessage('');
        setShowNoteModal(false);
    };
    
    
    
  

    const handleExecuteRequest = (clientId, clientRequest , requestId) => {

        Swal.fire({
            title: 'تأكيد الطلب',
            text: 'هل توافق على تنفيذ هذا الطلب؟',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'نعم، تنفيذ الطلب',
            cancelButtonText: 'إلغاء'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post('https://te.prize.pap.sa/api/execute/request/client', {
                    clientId: clientId,
                    clientRequest: clientRequest,
                    requestId: requestId
                }, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                })
                .then(response => {
                    if(response.data.success) {
                        Swal.fire(
                            'تم!',
                            response.data.success,
                            'success'
                        );
                    }
                    fetchSupportTimes();
                    setShowModal(false)
                })
                .catch(error => {
                    if (error.response && error.response.data.error) {
                        Swal.fire(
                            'خطأ!',
                            error.response.data.error,
                            'error'
                        );
                        
                    } else {
                        Swal.fire(
                            'خطأ!',
                            'حدث خطأ أثناء تنفيذ الطلب.',
                            'error'
                        );
                    }
                });
            }
        });
    };



    function renderRequestType(requestType) {
        const requestTypes = {
            'request_to_modify_phone_number': 'طلب تعديل رقم الهاتف',
            'request_to_modify_the_email': 'طلب تعديل البريد الالكتروني',
            'request_to_modify_the_national_id_number': 'طلب تعديل رقم الهوية الوطنية',
            'request_to_open_personal_file' : 'طلب فتح الملف الشخصي',
            'request_to_open_business_file':'طلب فتح الملف التجاري',
            'another':'اخري',

        };
        return requestTypes[requestType] || 'N/A';
    }
    


    return (
      <div className="container-fluid mt-2">
        <div className="row admin_page_header">
          <div className="admin_page_title">الدعم الفني </div>
          <div className="admin_page_subtitle mt-2">الرئيسية / الدعم الفني</div>
        </div>

        <div className="admin_page_content col-12 row">
          {successMessage && (
            <div className="alert alert-success mt-3 mb-3">
              {successMessage}
            </div>
          )}
          {errorMessage && (
            <div className="alert alert-danger mt-3 mb-3">{errorMessage}</div>
          )}
          <div className="col-12 row d-flex justify-content-between">
            <div className="col-md-4">
              <div className="search-container">
                <input
                  type="text"
                  placeholder=" ابحث عن الطلب...."
                  //   value={searchQuery}
                  //   onChange={(e) => setSearchQuery(e.target.value)}
                  className="search-input admin_candi_search_input form-control"
                />
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="table-responsive col-12">
              <table className="table admin_table">
                <thead className="admin_table_thead">
                  <tr>
                    <th>رقم الطلب</th>
                    <th>نوع الطلب</th>
                    <th>المستخدم</th>
                    <th>الإجراء الأساسي</th>
                  </tr>
                </thead>
                <tbody>
                  {supportTimes.map((supportTime) => (
                    <tr key={supportTime.id}>
                      <td>{supportTime.id}</td>
                      <td>
                        {supportTime.request ===
                          "request_to_modify_phone_number" &&
                          "طلب تعديل رقم الهاتف"}
                        {supportTime.request ===
                          "request_to_modify_the_email" &&
                          "طلب تعديل البريد الالكتروني"}
                        {supportTime.request ===
                          "request_to_modify_the_national_id_number" &&
                          "طلب تعديل رقم الهوية الوطنية"}
                        {supportTime.request ===
                          "request_to_open_personal_file" &&
                          "طلب فتح الملف الشخصي"}
                        {supportTime.request ===
                          "request_to_open_business_file" &&
                          "طلب فتح الملف التجاري"}
                        {supportTime.request === "another" && "اخري"}
                      </td>
                      <td>
                        {supportTime.user
                          ? supportTime.user.first_name +
                            " " +
                            supportTime.user.last_name
                          : "N/A"}
                      </td>
                      <td>
                        <button
                          className="admin_table_delete_btn"
                          onClick={() => handleDelete(supportTime.id)}
                        >
                          حذف
                        </button>
                        <button
                          className="admin_table_show_btn"
                          onClick={() => handleView(supportTime.id)}
                        >
                          معاينه الطلب
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <PaginationControls />
        </div>

        {selectedSupportTime && (
          <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            centered
            size="lg"
            className="support-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>معاينة الطلب</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {selectedSupportTime ? (
                <div className="preview-card">
                  <p className="modal-text">
                    <strong>المستخدم:</strong>{" "}
                    {selectedSupportTime.user
                      ? `${selectedSupportTime.user.first_name} ${selectedSupportTime.user.last_name}`
                      : "N/A"}
                  </p>
                  <p className="modal-text">
                    <strong>نوع الطلب:</strong>{" "}
                    {renderRequestType(selectedSupportTime.request)}
                  </p>
                  <p className="modal-text">
                    <strong>ملاحظات الطلب:</strong>{" "}
                    {selectedSupportTime.content}
                  </p>
                  <p className="modal-text">
                    <strong>تاريخ الارسال:</strong>{" "}
                    {selectedSupportTime.created_at}
                  </p>
                </div>
              ) : (
                <p>Loading details...</p>
              )}
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-primary" onClick={handleSendNote}>
                ارسال ملاحظه للمتسابق
              </button>
              {selectedSupportTime &&
                selectedSupportTime.request !== "another" && (
                  <button
                    className="btn btn-success"
                    onClick={() =>
                      handleExecuteRequest(
                        selectedSupportTime.user.id,
                        selectedSupportTime.request,
                        selectedSupportTime.id
                      )
                    }
                  >
                    تنفيذ الطلب
                  </button>
                )}

              <button
                className="btn btn-secondary"
                onClick={() => setShowModal(false)}
              >
                Close
              </button>
            </Modal.Footer>
          </Modal>
        )}

        {showNoteModal && (
          <Modal
            show={showNoteModal}
            onHide={() => setShowNoteModal(false)}
            centered
            size="md"
            className="note-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>إرسال ملاحظة</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <textarea
                className="form-control"
                value={noteMessage}
                onChange={(e) => setNoteMessage(e.target.value)}
                placeholder="أكتب ملاحظتك هنا..."
              ></textarea>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-primary" onClick={handleSend}>
                إرسال
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => setShowNoteModal(false)}
              >
                إغلاق
              </button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    );
};

export default Support;
