import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './styles.css';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../auth/Login';
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import ModeEditRoundedIcon from "@mui/icons-material/ModeEditRounded";

const Employees = () => {

    const [employees, setEmployees] = useState([]);
    const [error, setError] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchQuery);
  const [employee_premission, setEmployeePremission] = useState({});
  const [userType, setUserType] = useState("");

  const fetchDataEmployee = async () => {
    try {
       
           const user_id = localStorage.getItem('user_id');
            const response = await axios.get(`https://te.prize.pap.sa/api/employees/${user_id}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
  
            setEmployeePremission(response.data.employee);
  
    } catch (err) {
        if (err.response && err.response.data && err.response.data.error) {
            console.log(`خطاء: ${err.response.data.error}`);
        } else {
            console.log('حدث خطأ أثناء جلب الحقول.');
        }
    }
  };
    useEffect(() => {
        const timerId = setTimeout(() => {
            setDebouncedSearchTerm(searchQuery);
        }, 500);

        return () => {
            clearTimeout(timerId);
        };
    }, [searchQuery]);


    useEffect(() => {
        fetchEmployee(1, debouncedSearchTerm);
    }, [debouncedSearchTerm]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        const user_type = localStorage.getItem('user_type');
        if (!token) {
            logout(navigate);
            navigate('/');
        }

        if (user_type === "referee" || user_type === "client") {
            navigate(-1);
        }
    setUserType(localStorage.getItem('user_type'));

        fetchDataEmployee();
        fetchEmployee();
    }, [currentPage, navigate]);

    const fetchEmployee = async (page = 1) => {
        try {
            const response = await axios.get(`https://te.prize.pap.sa/api/employees?page=${page}&search=${searchQuery}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setEmployees(response.data.employees);
            setTotalPages(response.data.last_page);

        } catch (err) {
            if (err.response && err.response.data && err.response.data.error) {
                setError(`خطاء: ${err.response.data.error}`);
            } else {
                setError('حدث خطأ أثناء جلب الحقول.');
            }
        }
    };

    const handleDelete = async (id) => {
        const confirmDelete = window.confirm("انتبه انت علي وشك حذف  الموظف  ");
        if (!confirmDelete) return;

        try {
            await axios.post(`https://te.prize.pap.sa/api/delete/employee/${id}`, {}, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            fetchEmployee();
        } catch (err) {
            if (err.response && err.response.data && err.response.data.error) {
                setError(`خطاء: ${err.response.data.error}`);
            } else {
                setError('حدث خطأ أثناء جلب الحقول.');
            }
        }
    };

    const handleEdit = (employeesId) => {
        navigate('/admin/dashboard/employee/edit', { state: { employeesId } });
    };



    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const NewEmployees = () => {
        navigate('/admin/dashboard/employee/new');
    };

    const buttonStyle = {
        padding: '10px 20px',
        margin: '5px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    };
    const renderNationality = (nationality) => {
      switch (nationality) {
        case 'sa':
          return 'السعودية';
        case 'son_or_daughter_home':
          return 'ابن / ابنة مواطنة';
        // Add more cases as needed
        default:
          return nationality; // Return the same value if no match is found
      }
    };
    
    return (
      <div className="container-fluid mt-2">
        <div className="row admin_page_header">
          <div className="admin_page_title">استعراض الموظفين</div>
          <div className="admin_page_subtitle mt-2">
            الموظفين / استعراض الموظفين
          </div>
        </div>
        <div className="row">
          <div className="admin_page_content col-12 row">
            <div className="col-12 row d-flex justify-content-between">
              {error && (
                <p style={{ color: "red", marginBottom: "15px" }}>{error}</p>
              )}
              <div className="col-md-4">
                <div className="search-container">
                  <input
                    type="text"
                    placeholder=" ابحث عن الموظف...."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="search-input admin_candi_search_input form-control"
                  />
                </div>
              </div>

              <div className="col-md-4 d-flex justify-content-end">
              {employee_premission.employees?.store==true?(
                 <button
                 onClick={NewEmployees}
                 style={buttonStyle}
                 className="admin_page_btn_new"
               >
                 + اضافة جديد
               </button>
                ):(
                  userType !== 'employee' &&(
                    <button
                    onClick={NewEmployees}
                    style={buttonStyle}
                    className="admin_page_btn_new"
                  >
                    + اضافة جديد
                  </button>
                  )
                )}
                
              </div>
            </div>
            <div>
              <div className="row mt-3">
                <div className="table-responsive col-12">
                  <table className="table admin_table">
                    <thead className="admin_table_thead">
                      <tr>
                        <th>#</th>
                        <th>الاسم الاول</th>
                        <th>اسم العائة</th>
                        <th>الجنسية</th>
                        <th>الجوال</th>
                        <th>الابريد الالكتروني</th>
                        <th>الاجراء الأساسي</th>
                      </tr>
                    </thead>
                    <tbody>
                      {employees.length > 0 ? (
                        employees.map((employee) => (
                          <tr key={employee.id}>
                            <td>{employee.id}</td>
                            <td>{employee.user.first_name}</td>
                            <td>{employee.user.last_name}</td>
                            <td>{renderNationality(employee.user.nationality)}</td>
                            <td>{employee.user.phone}</td>
                            <td>{employee.user.email}</td>
                            <td>
                            {employee_premission.employees?.destroy==true?(
                  <button
                  className="admin_table_delete_btn"
                  onClick={() => handleDelete(employee.id)}
                >
                  <DeleteForeverRoundedIcon />
                </button>
                ):(
                  userType !== 'employee' &&(
                    <button
                  className="admin_table_delete_btn"
                  onClick={() => handleDelete(employee.id)}
                >
                  <DeleteForeverRoundedIcon />
                </button>
                  )
                )}
                  {employee_premission.employees?.update==true?(
                 <button
                 className="admin_table_edit_btn"
                 onClick={() => handleEdit(employee.id)}
               >
                 <ModeEditRoundedIcon />
               </button>
                ):(
                  userType !== 'employee' &&(
                    <button
                 className="admin_table_edit_btn"
                 onClick={() => handleEdit(employee.id)}
               >
                 <ModeEditRoundedIcon />
               </button>
                  )
                )}          
                              
                             
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="7" style={{ textAlign: "center" }}>
                            لا يوجد بيانات
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="pagination">
                {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                  (page) => (
                    <button
                      key={page}
                      className="button"
                      onClick={() => handlePageChange(page)}
                      disabled={page === currentPage}
                    >
                      {page}
                    </button>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );


};
export default Employees;