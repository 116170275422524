import React, { useState, useEffect } from "react";
import axios from "axios";

import { useNavigate } from "react-router-dom";
import { logout } from "../../auth/Login";

const NewBranches = () => {
  const navigate = useNavigate();
  const [fields, setFields] = useState([]);
  const [name, setName] = useState("");
  const [fieldId, setFieldId] = useState("");
  const [mandatory, setMandatory] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    const user_type = localStorage.getItem("user_type");
    const token = localStorage.getItem("token");
    if (!token) {
      logout(navigate);
    }

    if (user_type === "referee" || user_type === "client") {
      navigate(-1);
    }

    fetchFields();
  }, [navigate]);

  useEffect(() => {
    fetchFields();
  }, []);

  const fetchFields = async () => {
    try {
      const response = await axios.get("https://te.prize.pap.sa/api/fields", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      setFields(response.data.fields);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        setError(`خطاء: ${err.response.data.error}`);
      } else {
        setError("حدث خطأ أثناء جلب الحقول.");
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");
    setMessage("");

    try {
      const response = await axios.post(
        "https://te.prize.pap.sa/api/branch",
        {
          name,
          field_id: fieldId,
          mandatory,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      setMessage(response.data.message);
      setName("");
      setFieldId("");
      setMandatory(false);
      navigate("/admin/dashboard/branches");
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        setError(`خطاء: ${err.response.data.error.name}`);
      } else {
        setError("حدث خطأ أثناء جلب الحقول.");
      }
    }
  };

  const inputStyle = {
    padding: "10px",
    marginBottom: "10px",
    width: "100%",
  };

  const labelStyle = {
    fontWeight: "bold",
  };

  const buttonStyle = {
    padding: "10px 20px",
    backgroundColor: "#4CAF50",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  };

  return (
    <div className="container-fluid mt-2">
      <div className="row admin_page_header">
        <div className="admin_page_title">اضافة الافرع</div>
        <div className="admin_page_subtitle mt-2">الافرع / اضافة الافرع</div>
      </div>
      <div className="admin_page_content  p-5 col-12 row mb-4">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="form-group mb-5 col-md-6">
              <label>اسم الفرع:</label>
              <i
                className="fa-solid fa-star user_star"
                style={{ color: "#fd1212" }}
              ></i>
              <input
                className="form-control"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group mb-5 col-md-6 ">
              <label>المجال:</label>
              <i
                className="fa-solid fa-star user_star"
                style={{ color: "#fd1212" }}
              ></i>
              <select
                className="form-control"
                value={fieldId}
                onChange={(e) => setFieldId(e.target.value)}
              >
                <option value="">اختر المجال</option>
                {fields.map((field) => (
                  <option key={field.id} value={field.id}>
                    {field.title}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group mb-5 col-md-12 d-flex justify-content-center">
              <label>إلزامي:</label>
              <input
                type="checkbox"
                checked={mandatory}
                onChange={(e) => setMandatory(e.target.checked)}
              />
            </div>

            <div className="d-flex justify-content-center mb-5">
              <button type="submit" className="ms-2 user_info_btn_produce p-2">
                حفظ
              </button>
            </div>
          </div>
        </form>
        {error && <div style={{ color: "red" }}>{error}</div>}
        {message && <div style={{ color: "green" }}>{message}</div>}
      </div>
    </div>
  );
};

export default NewBranches;
