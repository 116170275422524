import React, { useState, useEffect } from "react";
import axios from 'axios'; // Ensure axios is imported
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom'; 
import CompanyLogo from '../../public/assets/logo.png'; // Adjust the path based on your file structure
import { logout } from "../../pages/auth/Login";
import  Profile  from "../../pages/auth/Profile";
import { useUser } from "../../context/UserContext";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: 'white', // Set the background color to white
  ...(open && {
    marginRight: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const LogoImage = styled('img')(({ open }) => ({
  display: open ? 'none' : 'block', 
  height: '50px', 
  marginLeft: 'auto', 
}));

const TopBar = ({ open, handleDrawerOpen }) => {
  const navigate = useNavigate(); 
  const [anchorEl, setAnchorEl] = React.useState(null);
 

  const openmenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


    const { userName } = useUser();
   

 
  

  return (
    <div>
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <LogoImage src={CompanyLogo} alt="Company Logo" open={open} />
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginLeft: 2,
              marginRight: 2,
              backgroundColor: '#3ab09e',
              '&:hover': { backgroundColor: '#2a3042' },
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            <div style={{ position: 'relative' }}>
              <InputBase
                placeholder="بحث..."
                inputProps={{ 'aria-label': 'search' }}
                sx={{ marginLeft: 2, flexGrow: 1, backgroundColor: '#f0f0f0', borderRadius: 2, padding: '5px', paddingRight: '10px' }}
              />
            </div>
          </Typography>
          <Button
            id="basic-button"
            aria-controls={openmenu ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openmenu ? 'true' : undefined}
            onClick={handleClick}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <img src={CompanyLogo} alt="" width="30px" style={{ marginRight: '5px' }} />
            <span style={{ color: '#3ab09e' }}>{userName}</span>
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openmenu}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={()=>navigate('/profile')}>الملف الشخصي</MenuItem>
            <MenuItem onClick={() => logout(navigate)}>تسجيل الخروج</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default TopBar;
