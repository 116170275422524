import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import REGIONS from "../../../common/REGIONS";
import CITIES from "../../../common/CITIES";
import CreatableSelect from "react-select/creatable";

// icons
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import ModeEditRoundedIcon from "@mui/icons-material/ModeEditRounded";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
// end icons
import "./Candidates.css";
import { Input } from "@mui/material";

const Candidates = () => {
  const [clients, setClients] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false); // State for tracking errors
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [filteredCities, setFilteredCities] = useState([]);
  const [occupation, setOccupation] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [age, setAge] = useState(0);
  const [employee_premission, setEmployeePremission] = useState({});

  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchQuery);
  const [userType, setUserType] = useState("");
  const [activeTab, setActiveTab] = useState("personalInfo");
  const [files, setFiles] = useState([]);
  const [countfiles, setCountFiles] = useState(1);
  const [videos, setVideos] = useState([]);
  const [countVideos, setCountVideos] = useState(1);
  const [wetsite, setWrbsite] = useState([]);
  const [countWevsite, setCountWebsite] = useState(1);
  const [keyWebsite, setKeyWebsite] = useState("");
  const [url_website_sale, setUrl_websit_sale] = useState("");
  const [userStep, setUserStep] = useState("");

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    fetchData(1, searchQuery);
  };

  useEffect(() => {
    fetchData(1, "", userStep);
  }, [userStep]);

  const fetchDataEmployee = async () => {
    try {
      const user_id = localStorage.getItem("user_id");
      const response = await axios.get(
        `https://te.prize.pap.sa/api/employees/${user_id}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      setEmployeePremission(response.data.employee);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        console.log(`خطاء: ${err.response.data.error}`);
      } else {
        console.log("حدث خطأ أثناء جلب الحقول.");
      }
    }
  };
  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchQuery);
    }, 500);

    return () => {
      clearTimeout(timerId);
    };
  }, [searchQuery]);

  useEffect(() => {
    fetchData(1, debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  const navigate = useNavigate();

  const calculateAge = (birthDate) => {
    const birthday = new Date(birthDate);
    const today = new Date();

    if (birthday > today) {
      
      return "";
    }

    let age = today.getFullYear() - birthday.getFullYear();
    const m = today.getMonth() - birthday.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
      age--;
    }
    return age;
  };
  useEffect(() => {
    setUserType(localStorage.getItem("user_type"));
    fetchDataEmployee();

    fetchData();
  }, []);

  const fetchData = async (page = 1, search = "") => {
    try {
      const response = await axios
        .get(
          `https://te.prize.pap.sa/api/client?page=${page}&search=${encodeURIComponent(
            search
          )}&userStep=${userStep}`
        )
        .then((response) => {
          setClients(response.data.data);
          setError(false);
          setTotalPages(response.data.meta.last_page);
          setCurrentPage(response.data.meta.current_page);
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
          setError(true);
        });
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const handleOpenModal = (user_id) => {
    fetch(`https://te.prize.pap.sa/api/client/${user_id}`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);

        setSelectedUser(data);
        setOpen(true);
        const currentday = new Date();
        const birthday2 = new Date(data.user_data.birth_date);
        if (data.user_data.birth_date != null) {
          const neAge = currentday.getFullYear() - birthday2.getFullYear();
          setAge(neAge);
        }
        if (data.user_data.region) {
          const cities = CITIES.filter(
            (city) => city.region_id === parseInt(data.user_data.region)
          );
          console.log(data.user_data.city);
          setFilteredCities(cities);
          setSelectedCity(data.user_data.city);
        }

        const filesArray = JSON.parse(data.commerce.files_document);
        setFiles(filesArray);
        if (filesArray.length > 0) {
          // Get the last element in the array
          const lastFile = filesArray[filesArray.length - 1];
          setCountFiles(lastFile.id + 1);
        }
        const videosArray = JSON.parse(data.commerce.videos);
        setVideos(videosArray);
        if (videosArray.length > 0) {
          // Get the last element in the array
          const lastVideo = videosArray[videosArray.length - 1];
          setCountVideos(lastVideo.id + 1);
        }

        const websiteArray = JSON.parse(data.commerce.website_sale);
        setWrbsite(websiteArray);
        if (websiteArray.length > 0) {
          // Get the last element in the array
          const lastWebsite = websiteArray[websiteArray.length - 1];
          setCountWebsite(lastWebsite.id + 1);
        }
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  };

  // Function to handle closing the modal
  const handleCloseModal = () => {
    setSelectedUser(null);
    setOpen(false);
  };

  const handleAddNew = () => {
    navigate("/admin/dashboard/candidates/new");
  };
  const handleDelete = (clientId) => {
    console.log(`Delete client with ID: ${clientId}`);
    Swal.fire({
      title: "تأكيد",
      text: "سيتم مسح المرشح؟",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "تأكيد",
      cancelButtonText: "إلغاء",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`https://te.prize.pap.sa/api/client/${clientId}`)
          .then((response) => {
            Swal.fire({
              title: "تم الحذف!",
              text: "تم مسح البيانات بنجاح.",
              icon: "success",
              confirmButtonText: "حسنا",
            }).then(() => {
              // Call fetchData() here to refresh data
              fetchData();
            });

            // Update local state to reflect the deletion
            const updatedClients = clients.filter(
              (client) => client.user.id !== clientId
            );
            setClients(updatedClients);
          })
          .catch((error) => {
            console.error("Error processing your request:", error);
            Swal.fire({
              text: "يوجد خطأ ! تأكد أن الكلاينت موجود",
              icon: "error",
              confirmButtonText: "حسنا",
            });
          });
      }
    });
  };

  const PaginationControls = () => {
    const pages = Array.from({ length: totalPages }, (_, i) => i + 1);

    return (
      <nav>
        <ul className="pagination text-black">
          {pages.map((page) => (
            <li
              key={page}
              className={`page-item ${page === currentPage ? "active" : ""}`}
            >
              <button className="page-link" onClick={() => fetchData(page)}>
                {page}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    );
  };

  const handleShow = (clientId) => {
    console.log(`Show details for client ID: ${clientId}`);
  };

  const handleEdit = (clientId) => {
    navigate(`/admin/dashboard/candidates/edit/${clientId}`);
  };

  const handleEditClient = (clientId, status) => {
    const token = localStorage.getItem("token");
    axios
      .post(
        "https://te.prize.pap.sa/api/edit/status/client",
        {
          clientId: clientId,
          status: status,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data.message);
        fetchData();
      })
      .catch((error) => {
        // Handle error
        console.error(
          "Error: ",
          error.response ? error.response.data.message : error.message
        );
      });
  };
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)", // Centers the modal
    width: "50%",
    maxWidth: "100%", // Adjusted for a maximum width
    maxHeight: "90vh", // Maximum height is 90% of the viewport height
    bgcolor: "rgb(0, 166, 147)",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
    p: "50px",
    borderRadius: "10px", // Added border radius
    "&::-webkit-scrollbar": {
      width: "12px", // Width of the scrollbar
      borderRadius: "10px", // Border radius of the scrollbar
      backgroundColor: "rgb(0, 166, 147)", // Color of the scrollbar track
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "white", // Color of the scrollbar thumb
      borderRadius: "10px", // Border radius of the scrollbar thumb
    },
  };

  const closeButtonStyle = {
    position: "absolute",
    top: "10px",
    right: "15px",
    border: "none",
    background: "white",
    borderRadius: "50%",
    cursor: "pointer",
    fontSize: "2rem",
    color: "red",
    height: "30px",
    width: "30px",
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setUserStep(value);
  };

  return (
    <div className="container-fluid mt-2">
      <div className="row admin_page_header">
        <div className="admin_page_title">استعراض المرشحين</div>
        <div className="admin_page_subtitle mt-2">
          المرشحين / استعراض المرشحين
        </div>
      </div>

      <div className="row">
        <div className="admin_page_content col-12 row">
          <div className="col-12 row d-flex justify-content-between">
            <div className="col-md-4">
              <form onSubmit={handleSearch} className="mb-4">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control admin_candi_search_input"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="ابحث عن المرشحيين...."
                  />
                </div>
              </form>
            </div>

            <div className="col-md-4">
              <select
                className="form-field form-control"
                name="referee_type"
                value={userStep}
                onChange={handleChange}
                required
              >
                <option value="">اختر المرحلة </option>
                <option value="1">حفظ كمسوده</option>
                <option value="2"> تقديم الطلب</option>
                <option value="3"> مرحله التقيم</option>
                <option value="4">مرحله الترحيل </option>
                <option value="5"> مرحلة تقييم النهائية </option>
              </select>
            </div>

            <div className="col-md-4 d-flex justify-content-end">
              {userType == "employee" && employee_premission.clients?.store ? (
                <button className="admin_page_btn_new" onClick={handleAddNew}>
                  + اضافة جديد
                </button>
              ) : (
                userType !== "employee" && (
                  <button className="admin_page_btn_new" onClick={handleAddNew}>
                    + اضافة جديد
                  </button>
                )
              )}
            </div>
          </div>

          {/* display table data */}
          <div className="row mt-3">
            <div className="table-responsive col-12">
              <table className="table admin_table">
                <thead className="admin_table_thead">
                  <tr>
                    <th>#</th>
                    <th>الاسم الاول</th>
                    <th>اسم العائلة</th>
                    <th>رقم الهوية</th>
                    <th>رقم الجوال</th>
                    <th>البريد الالكتروني</th>
                    <th>الحالة</th>
                    <th>الملف الشحصي</th>
                    <th>الملف التجاري</th>
                    <th>الإجراء الأساسي</th>
                  </tr>
                </thead>
                <tbody>
                  {clients?.length > 0 ? (
                    clients.map((client) => (
                      <tr key={client.id}>
                        <td>{client.id}</td>
                        <td>{client.user?.first_name}</td>
                        <td>{client.user?.last_name}</td>
                        <td>{client.user?.ssn}</td>
                        <td>{client.user?.phone}</td>
                        <td>{client.user?.email}</td>
                        <td>
                          {(() => {
                            const stepId = client.step?.id;
                            let stepLabel = "";
                            let buttonClass = "btn ";

                            switch (stepId) {
                              case 2:
                                stepLabel = "تم تقديم الطلب";
                                buttonClass += "btn-primary";
                                break;
                              case 3:
                                stepLabel = "في مرحلة التقييم الأولي";
                                buttonClass += "btn-secondary";
                                break;
                              case 4:
                                stepLabel = "في مرحلة الترحيل";
                                buttonClass += "btn-success";
                                break;
                              case 5:
                                stepLabel = "في مرحلة التقييم النهائي";
                                buttonClass += "btn-warning";
                                break;
                              default:
                                stepLabel =
                                  stepId < 3 ? "حفظ كمسودة" : "Unknown Step";
                                buttonClass += "btn-dark";
                                break;
                            }

                            return (
                              <span
                                className={`${buttonClass} btn-sm`}
                                style={{ pointerEvents: "none", opacity: 0.65 }}
                              >
                                {stepLabel}
                              </span>
                            );
                          })()}
                        </td>

                        <td>
                          {client.personal_status != true ? (
                            <button
                              className="btn admin_table_open_btn"
                              disabled
                            >
                              الملف مفتوح
                            </button>
                          ) : (
                            <button
                              className="btn btn-danger admin_table_open_btn2"
                              onClick={() =>
                                handleEditClient(client.id, "personal_status")
                              }
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              الملف مغلق
                            </button>
                          )}
                        </td>
                        <td>
                          {client.commerce_status != true ? (
                            <button
                              className="btn admin_table_open_btn"
                              disabled
                            >
                              الملف مفتوح
                            </button>
                          ) : (
                            <button
                              className="btn btn-danger admin_table_open_btn2"
                              onClick={() =>
                                handleEditClient(client.id, "commerce_status")
                              }
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              الملف مغلق
                            </button>
                          )}
                        </td>

                        <td>
                          <div className="user_table_contain_btn">
                            {userType == "employee" &&
                            employee_premission.clients?.destroy == true ? (
                              <button
                                className="admin_table_delete_btn"
                                onClick={() => handleDelete(client.id)}
                              >
                                <DeleteForeverRoundedIcon />
                              </button>
                            ) : (
                              userType !== "employee" && (
                                <button
                                  className="admin_table_delete_btn"
                                  onClick={() => handleDelete(client.id)}
                                >
                                  <DeleteForeverRoundedIcon />
                                </button>
                              )
                            )}
                            {userType == "employee" &&
                            employee_premission.clients?.update == true ? (
                              <button
                                className="admin_table_edit_btn"
                                onClick={() => handleEdit(client.user.id)}
                              >
                                <ModeEditRoundedIcon />
                              </button>
                            ) : (
                              userType !== "employee" && (
                                <button
                                  className="admin_table_edit_btn"
                                  onClick={() => handleEdit(client.user.id)}
                                >
                                  <ModeEditRoundedIcon />
                                </button>
                              )
                            )}
                            {employee_premission.clients?.index == true ? (
                              <button
                                className="admin_table_show_btn"
                                onClick={() => handleOpenModal(client.user.id)}
                              >
                                <VisibilityOutlinedIcon />
                              </button>
                            ) : (
                              userType != "employee" && (
                                <button
                                  className="admin_table_show_btn"
                                  onClick={() =>
                                    handleOpenModal(client.user.id)
                                  }
                                >
                                  <VisibilityOutlinedIcon />
                                </button>
                              )
                            )}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="10" className="text-center">
                        لا يوجد متسابقين
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {/* end table data */}

          <PaginationControls />
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-custom"
      >
        <Box sx={modalStyle}>
          <nav>
            <ul className="nav-tabs admin_nav_items">
              <li
                className={activeTab === "personalInfo" ? "active" : ""}
                onClick={() => handleTabChange("personalInfo")}
              >
                <button>المعلومات الشخصية</button>
              </li>
              <li
                className={activeTab === "companyInfo" ? "active" : ""}
                onClick={() => handleTabChange("companyInfo")}
              >
                <button>المعلومات التجارية</button>
              </li>
            </ul>
          </nav>
          <button
            style={closeButtonStyle}
            onClick={handleCloseModal}
            className="d-flex justify-content-center align-items-center"
          >
            &times;
          </button>{" "}
          {/* Close button */}
          {activeTab === "personalInfo" && selectedUser && (
            <>
              <div className="w-100 d-flex justify-content-center row">
                <Typography
                  id="modal-modal-title"
                  className="admin_model_title text-center"
                  component="h2"
                >
                  عرض بيانات المرشح
                </Typography>
                <Typography
                  id="modal-modal-description"
                  className="admin_model_exit text-center"
                >
                  اضغط خارج الموديل للغلق
                </Typography>
              </div>

              <div className="container">
                <div className="row mb-3">
                  <div className="col">
                    <label htmlFor="first_name" className="form-label">
                      الاسم الأول
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="first_name"
                      value={selectedUser.user_data.first_name}
                      disabled
                    />
                  </div>
                  {/* ... similar structure for other input fields ... */}
                </div>

                <div className="row mb-3">
                  <div className="col-md-4">
                    <label htmlFor="first_name" className="form-label">
                      اسم الاب
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="first_name"
                      value={selectedUser.client_data.father_name}
                      disabled
                    />
                  </div>

                  {/* Second Field */}
                  <div className="col-md-4">
                    <label htmlFor="last_name">اسم الجد</label>
                    <input
                      type="text"
                      className="form-control"
                      id="last_name"
                      value={selectedUser.client_data.grandfather_name}
                      disabled
                    />
                  </div>

                  {/* Third Field */}
                  <div className="col-md-4">
                    <label htmlFor="last_name">اسم العائلة</label>
                    <input
                      type="text"
                      className="form-control"
                      id="family_name"
                      value={selectedUser.user_data.last_name}
                      disabled
                    />
                  </div>
                </div>

                {/* SSN Field */}
                <div className="row mb-3">
                  <div className="col">
                    <label htmlFor="ssn" className="form-label">
                      رقم الهوية
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="ssn"
                      value={selectedUser.user_data.ssn}
                      disabled
                    />
                  </div>
                </div>

                {/* Gender and Nationality Fields */}
                <div className="row mb-3">
                  {/* Gender */}
                  <div className="col-md-6">
                    <label htmlFor="gender" className="form-label">
                      الجنس
                    </label>
                    <select
                      className="form-control"
                      id="gender"
                      value={selectedUser.user_data.gender}
                      disabled
                    >
                      <option value="male">ذكر</option>
                      <option value="female">أنثى</option>
                    </select>
                  </div>

                  {/* Nationality */}
                  <div className="col-md-6">
                    <label htmlFor="nationality" className="form-label">
                      الجنسية
                    </label>
                    <select
                      className="form-control"
                      id="nationality"
                      value={selectedUser.user_data.nationality}
                      disabled
                    >
                      <option value="sa">السعودية</option>
                      <option value="son_or_daughter_home">
                        ابن / ابنة مواطنة
                      </option>
                    </select>
                  </div>
                 
                  <div className="col-md-6">
                    <label htmlFor="region">المنطقة</label>
                    <select
                      className="form-control"
                      id="region"
                      value={selectedUser.user_data.region}
                      disabled
                    >
                      <option value="">اختر المنطقة</option>
                      {REGIONS.map((region) => (
                        <option key={region.region} value={region.region_id}>
                          {region.name_ar}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="city">المدينة</label>
                    <select
                      className="form-control"
                      id="city"
                      value={selectedUser.user_data.city}
                      disabled
                    >
                      <option value="">اختر المدينة</option>
                      {CITIES.map((city) => (
                        <option key={city.city_id} value={city.city_id}>
                          {city.name_ar}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                 
               

           
                      
                {/* Birth Date and Age */}
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label htmlFor="birth_date">
                      العمر(اختر تاريخ الميلاد)
                    </label>
                    <div className="input-group">
                      <input
                        type="date"
                        className="form-control user_phone_input"
                        id="birth_date"
                        value={selectedUser.user_data.birth_date}
                        disabled
                      />
                      <div className="input-group-append">
                        <button className="btn user_btn" type="button">
                          {age !== "" ? `${age} سنة` : ""}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col">
                    <label htmlFor="phone">رقم الجوال</label>
                    <div className="input-group">
                      <input
                        type="tel"
                        className="form-control user_phone_input"
                        id="phone"
                        placeholder="5xxxxxxxx"
                        value={
                          selectedUser.user_data.phone
                            ? selectedUser.user_data.phone.slice(4)
                            : ""
                        }
                        disabled
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">966+</span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Occupation and Current Workplace */}
                <div className="row mb-3">
                  {/* Occupation */}
                  <div className="col-md-6">
                    <label htmlFor="occupation">المهنة</label>
                    <select
                      className="form-control"
                      id="occupation"
                      value={
                        selectedUser &&
                        selectedUser.work &&
                        selectedUser.work.occupation
                      }
                      disabled
                    >
                      <option value="student">طالب</option>
                      <option value="government">موظف حكومي</option>
                      <option value="private">موظف قطاع خاص</option>
                      <option value="trader">تاجر</option>
                      <option value="freelancer">عمل حر</option>
                      <option value="retired">متقاعد</option>
                      <option value="unemployed">بدون عمل</option>
                    </select>
                  </div>

                  {/* Current Workplace */}
                  <div className="col-md-6">
                    <label htmlFor="current_workplace">جهة العمل الحالية</label>
                    <input
                      type="text"
                      className="form-control"
                      id="current_workplace"
                      value={
                        selectedUser &&
                        selectedUser.work &&
                        selectedUser.work.current_workplace
                      }
                      disabled
                    />
                  </div>
                </div>

                {/* Previous Workplaces */}
                <div className="row mb-3">
                  <div className="col">
                    <label htmlFor="previous_workplaces">
                      جهة العمل السابقة
                    </label>
                    <CreatableSelect
                      isMulti
                      value={
                        selectedUser &&
                        selectedUser.work &&
                        selectedUser.work
                          .previous_workplaces /* ...parse JSON logic... */
                      }
                      placeholder="اكتب اسم جهات عمل سابقه"
                      isClearable={true}
                      isDisabled={true}
                    />
                  </div>
                </div>

                {/* Skills and Experience */}
                <div className="row mb-3">
                  {/* Skills */}
                  <div className="col-md-6">
                    <label htmlFor="skills">المهارات</label>
                    <CreatableSelect
                      isMulti
                      value={
                        selectedUser &&
                        selectedUser.work &&
                        selectedUser.work.skills /* ...parse JSON logic... */
                      }
                      placeholder="اكتب اسم مهارة"
                      isClearable={true}
                      isDisabled={true}
                    />
                  </div>

                  {/* Years of Experience */}
                  <div className="col-md-6">
                    <label htmlFor="years_of_experience">سنوات الخبرة</label>
                    <input
                      type="number"
                      className="form-control"
                      id="years_of_experience"
                      value={
                        selectedUser &&
                        selectedUser.work &&
                        selectedUser.work.years_of_experience
                      }
                      min={0}
                      disabled
                    />
                  </div>
                </div>

                {/* ... Previous fields ... */}

                {/* Position, Academic, and Academic Level Fields */}
                {occupation !== "student" &&
                  occupation !== "retired" &&
                  occupation !== "unemployed" && (
                    <div className="row mb-3">
                      <div className="col">
                        <label htmlFor="position">المنصب</label>
                        <input
                          type="text"
                          className="form-control"
                          id="position"
                          value={
                            selectedUser &&
                            selectedUser.work &&
                            selectedUser.work.position
                          }
                          disabled
                        />
                      </div>
                    </div>
                  )}

                {occupation === "student" && (
                  <div className="row mb-3">
                    {/* Academic Institution */}
                    <div className="col-md-6">
                      <label htmlFor="academic">الجهة الدراسية</label>
                      <input
                        type="text"
                        className="form-control"
                        id="academic"
                        value={
                          selectedUser &&
                          selectedUser.work &&
                          selectedUser.work.academic
                        }
                        disabled
                      />
                    </div>

                    {/* Academic Level */}
                    <div className="col-md-6">
                      <label htmlFor="academic_level">المستوى الدراسي</label>
                      <input
                        type="text"
                        className="form-control"
                        id="academic_level"
                        value={
                          selectedUser &&
                          selectedUser.work &&
                          selectedUser.work.academic_level
                        }
                        disabled
                      />
                    </div>
                  </div>
                )}

                {/* Years of Experience */}
                <div className="row mb-3">
                  <div className="col">
                    <label htmlFor="years_of_experience">سنوات الخبرة</label>
                    <input
                      type="number"
                      className="form-control"
                      id="years_of_experience"
                      value={
                        selectedUser &&
                        selectedUser.work &&
                        selectedUser.work.years_of_experience
                      }
                      min={0}
                      disabled
                    />
                  </div>
                </div>

                {/* Skills */}
                <div className="row mb-3">
                  <div className="col">
                    <label htmlFor="skills">المهارات</label>
                    <CreatableSelect
                      isMulti
                      value={
                        selectedUser &&
                        selectedUser.work &&
                        selectedUser.work.skills
                          ? (() => {
                              try {
                                return JSON.parse(selectedUser.work.skills);
                              } catch (error) {
                                console.error("Error parsing JSON:", error);
                                return [{ value: "", label: "" }];
                              }
                            })()
                          : [{ value: "", label: "" }]
                      }
                      placeholder="اكتب اسم مهارة"
                      isClearable={true}
                      isDisabled={true}
                    />
                  </div>
                </div>

                {/* Personal Summary */}
                <div className="row mb-3">
                  <div className="col">
                    <label htmlFor="personal_summary">
                      نبذة شخصية تقدم بها نفسك
                    </label>
                    <textarea
                      className="form-control"
                      id="personal_summary"
                      value={
                        selectedUser &&
                        selectedUser.work &&
                        selectedUser.work.personal_summary
                      }
                      disabled
                    ></textarea>
                  </div>
                </div>
                <div className="col-12 user_personal_information">
                  <div className="user_information_hor mb-2"></div>

                  <div className="row user_information_personal_form mt-4 pb-4">
                    <div className="form-group mb-5 col-md-6">
                      <label htmlFor="twitter">تويتر</label>
                      <div className="input-group">
                        <input
                          disabled
                          type="url" // Change the input type to 'url'
                          className="form-control"
                          id="twitter"
                          placeholder="لا يوجد" // Provide a placeholder with an example link
                          value={
                            selectedUser &&
                            selectedUser.social_personal &&
                            selectedUser.social_personal.twitter
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group mb-5 col-md-6">
                      <label htmlFor="snapchat"> سناب شات </label>
                      <div className="input-group">
                        <input
                          disabled
                          type="url" // Change the input type to 'url'
                          className="form-control"
                          id="snapchat"
                          placeholder="لا يوجد"
                          value={
                            selectedUser &&
                            selectedUser.social_personal &&
                            selectedUser.social_personal.snapchat
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group mb-5 col-md-6">
                      <label htmlFor="instagram">انستغرام </label>
                      <div className="input-group">
                        <input
                          disabled
                          type="url" // Change the input type to 'url'
                          className="form-control"
                          id="instagram"
                          placeholder="لا يوجد"
                          value={
                            selectedUser &&
                            selectedUser.social_personal &&
                            selectedUser.social_personal.instagram
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group mb-5 col-md-6">
                      <label htmlFor="tiktok">تيك توك </label>
                      <div className="input-group">
                        <input
                          disabled
                          type="url" // Change the input type to 'url'
                          className="form-control"
                          id="tiktok"
                          placeholder="لا يوجد"
                          value={
                            selectedUser &&
                            selectedUser.social_personal &&
                            selectedUser.social_personal.tiktok
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group mb-5 col-md-6">
                      <label htmlFor="youtube"> يوتيوب </label>
                      <div className="input-group">
                        <input
                          disabled
                          type="url" // Change the input type to 'url'
                          className="form-control"
                          id="youtube"
                          placeholder="لا يوجد"
                          value={
                            selectedUser &&
                            selectedUser.social_personal &&
                            selectedUser.social_personal.youtube
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group mb-5 col-md-6">
                      <label htmlFor="whatsapp"> واتس اب </label>
                      <div className="input-group">
                        <input
                          disabled
                          type="tel" // Change the input type to 'url'
                          className="form-control"
                          id="whatsapp"
                          placeholder="لا يوجد"
                          value={
                            selectedUser &&
                            selectedUser.social_personal &&
                            selectedUser.social_personal.whatsapp
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group mb-2 col-md-6">
                      <label htmlFor="telegram"> تليجرام </label>
                      <div className="input-group">
                        <input
                          disabled
                          type="tel" // Change the input type to 'url'
                          className="form-control"
                          id="telegram"
                          placeholder="لا يوجد"
                          value={
                            selectedUser &&
                            selectedUser.social_personal &&
                            selectedUser.social_personal.telegram
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group mb-2 col-md-6">
                      <label htmlFor="others"> اخرى </label>
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        id="others"
                        placeholder="لا يوجد"
                        value={
                          selectedUser &&
                          selectedUser.social_personal &&
                          selectedUser.social_personal.others
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {activeTab === "companyInfo" && selectedUser && (
            <div className="row">
              <div className="w-100 d-flex justify-content-center row">
                <Typography
                  id="modal-modal-title"
                  className="admin_model_title text-center"
                  component="h2"
                >
                  عرض بيانات المرشح
                </Typography>
                <Typography
                  id="modal-modal-description"
                  className="admin_model_exit text-center"
                >
                  اضغط خارج الموديل للغلق
                </Typography>
              </div>
              <div className="form-group mb-2 col-md-6">
                <label htmlFor="Field">المجال</label>
                <select className="form-control" id="field" disabled>
                  <option value="" disabled selected>
                    {selectedUser.field_name}
                  </option>
                </select>
              </div>
              <div className="form-group mb-5 col-md-6">
                <label htmlFor="nationality">الفرع</label>
                <select className="form-control" id="nationality" disabled>
                  <option value="" disabled selected>
                    {selectedUser.branch_name}
                  </option>
                </select>
              </div>
              <div className="form-group mb-5 col-md-6 col-xl-4">
                <label htmlFor="type_work_documnet">نوع وثيقة العمل</label>
                <input
                  disabled
                  className="form-control"
                  id="type_work_documnet"
                  value={selectedUser.commerce.type_work_documnet}
                />
              </div>
              {selectedUser.commerce.type_work_documnet !== "not_found" && (
                <>
                  <div className="form-group mb-5 col-md-6 col-xl-4">
                    <label htmlFor="number_documnet"> رقم الوثيقة </label>
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      id="number_documnet"
                      placeholder=""
                      value={selectedUser.commerce.number_documnet}
                    />
                  </div>

                  <div className="form-group mb-5 col-md-6 col-xl-4">
                    <label htmlFor="end_documnet"> تاريخ انتهاء الوثيقة </label>
                    <input
                      disabled
                      type="date"
                      className="form-control"
                      id="end_documnet"
                      placeholder=""
                      value={selectedUser.commerce.end_documnet}
                    />
                  </div>
                  <div className="form-group mb-5 col-md-6 col-xl-4">
                    <label htmlFor="name_activity"> اسم النشاط </label>
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      id="name_activity"
                      placeholder=""
                      value={selectedUser.commerce.name_activity}
                    />
                  </div>

                  <div className="form-group mb-5 col-md-6 col-xl-4">
                    <label htmlFor="start_documnet"> تاريخ بدءالنشاط </label>
                    <input
                      disabled
                      type="date"
                      className="form-control"
                      id="start_documnet"
                      placeholder=""
                      value={selectedUser.commerce.start_documnet}
                    />
                  </div>

                  <div className="form-group mb-5 col-md-6 col-xl-4">
                    <label htmlFor="duration_activity">
                      {" "}
                      المدة منذ بدء النشاط{" "}
                    </label>
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      id="duration_activity"
                      placeholder=""
                      value={age !== "" ? `${age} سنة` : ""}
                    />
                  </div>
                </>
              )}
              <div className="form-group mb-5 col-md-6 col-xl-4">
                <label htmlFor="number_documntation">رقم التوثيق</label>
                <input
                  disabled
                  type="number"
                  className="form-control"
                  id="number_documntation"
                  placeholder="لا يوجد"
                  value={selectedUser.commerce.number_documntation}
                />
              </div>

              <div className="form-group mb-2">
                <label htmlFor="website_sale">المنصة المستخدمة للبيع</label>
                <div className="col-12 user_personal_information">
                  <div className="user_information_hor mb-2"></div>
                  <div className="row user_information_personal_form mt-4 pb-4">
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>اسم المنصة</th>
                            <th>رابط المنصة</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Array.isArray(wetsite) &&
                            wetsite.map((webSite) => (
                              <tr key={webSite.id}>
                                <td>
                                  <input
                                    disabled
                                    className="form-control"
                                    id="website_sale"
                                    value={webSite.key}
                                  />
                                </td>
                                <td>
                                  <div className="form-group">
                                    <input
                                      disabled
                                      type="text"
                                      className="form-control"
                                      value={webSite.description}
                                      placeholder="ادخل وصف"
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group mb-2">
                <label htmlFor="charity">
                  {" "}
                  منتسب لجمعية خيرية او مؤسسة خيرية
                </label>
                <input
                  disabled
                  type="text"
                  className="form-control"
                  id="charity"
                  placeholder="لا يوجد"
                  value={selectedUser.commerce.charity}
                />
              </div>

              <div className="form-group mb-2">
                <label htmlFor="charity">
                  مؤهلات علمية او دورات تدريبية مرتبطة بالنشاط
                </label>
                <CreatableSelect
                  disabled
                  isMulti
                  value={
                    selectedUser.commerce.courses_qualification
                      ? (() => {
                          try {
                            return JSON.parse(
                              selectedUser.commerce.courses_qualification
                            );
                          } catch (error) {
                            console.error("Error parsing JSON:", error);
                            return [{ value: "", label: "" }];
                          }
                        })()
                      : [{ value: "", label: "" }]
                  }
                  placeholder="لا يوجد"
                  isClearable={true}
                />
              </div>

              <div className="form-group mb-5">
                <label htmlFor="notes">
                  نبذة تفصيلية عن الفكرة او النشاط الخاص بك
                </label>
                <textarea
                  disabled
                  className="form-control"
                  id="summary"
                  placeholder="لا يوجد"
                  rows={10}
                  value={selectedUser.commerce.summary}
                ></textarea>
              </div>
              <div className="row user_information_personal_form mt-4 pb-4">
                <div className="form-group mb-3 col-md-6 col-xl-4">
                  <label htmlFor="twitter"> تويتر </label>
                  <div className="input-group">
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      id="twitter"
                      placeholder="@اسم المستخدم"
                      value={selectedUser.social_commerce.twitter}
                      pattern="https?://.*"
                      title="يرجى إدخال رابط صحيح مبتدئًا بـ http أو https."
                    />
                  </div>
                </div>
                {/* Instagram Input */}
                <div className="form-group mb-3 col-md-6 col-xl-4">
                  <label htmlFor="instagram">انستغرام</label>
                  <div className="input-group">
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      id="instagram"
                      placeholder="@اسم المستخدم"
                      value={selectedUser?.social_commerce?.instagram || ""}
                    />
                  </div>
                </div>
                <div className="form-group mb-3 col-md-6 col-xl-4">
                  <label htmlFor="youtube"> يوتيوب </label>
                  <div className="input-group">
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      id="youtube"
                      placeholder="الرابط "
                      value={selectedUser.social_commerce.youtube}
                    />
                  </div>
                </div>
                <div className="form-group mb-3 col-md-6 col-xl-4">
                  <label htmlFor="tiktok"> تيك توك </label>
                  <div className="input-group">
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      id="tiktok"
                      placeholder="@اسم المستخدم"
                      value={selectedUser.social_commerce.tiktok}
                    />
                  </div>
                </div>

                <div className="form-group mb-3 col-md-6 col-xl-4">
                  <label htmlFor="whatsapp"> واتس اب </label>
                  <div className="input-group">
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      id="whatsapp"
                      placeholder="+9660000000"
                      value={selectedUser.social_commerce.whatsapp}
                    />
                  </div>
                </div>
                <div className="form-group mb-3 col-md-6 col-xl-4">
                  <label htmlFor="linkendin"> لينكد ان </label>
                  <div className="input-group">
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      id="linkendin"
                      placeholder="@اسم المستخدم"
                      value={selectedUser.social_commerce.linkendin}
                    />
                  </div>
                </div>
                <div className="form-group mb-3 col-md-6 col-xl-4">
                  <label htmlFor="snapchat"> سناب شات </label>
                  <div className="input-group">
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      id="snapchat"
                      placeholder="@اسم المستخدم"
                      value={selectedUser.social_commerce.snapchat}
                    />
                  </div>
                </div>

                <div className="form-group mb-3 col-md-6 col-xl-4">
                  <label htmlFor="telegram"> تليجرام </label>
                  <div className="input-group">
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      id="telegram"
                      placeholder="@اسم المستخدم"
                      value={selectedUser.social_commerce.telegram}
                    />
                  </div>
                </div>
                <div className="form-group mb-3 col-md-6 col-xl-4">
                  <label htmlFor="website"> الموقع الالكتروني </label>
                  <div className="input-group">
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      id="website"
                      placeholder="الرابط"
                      value={selectedUser.social_commerce.website}
                    />
                  </div>
                </div>
                <div className="form-group mb-3 col-md-6 col-xl-4">
                  <label htmlFor="others"> اخرى </label>
                  <div className="input-group">
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      id="others"
                      placeholder="@اسم المستخدم"
                      value={selectedUser.social_commerce.others}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 user_personal_information">
                <div className="w-100 d-flex align-items-center mt-3">
                  <div className="user_step_main_info">
                    <div className="user_info_meaning"> مستندات و وثائق </div>
                  </div>
                </div>
                <div className="user_information_hor mb-2"></div>

                <div className="row user_information_personal_form mt-4 pb-4">
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>الرقم </th>
                          <th>اسم الملف</th>
                          <th>وصف الملف</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(files) &&
                          files.map((file) => (
                            <tr key={file.id}>
                              <td>{file.id}</td>
                              <td>
                                <a
                                  className="user_work_file"
                                  href={
                                    "https://pub-834d7d165e3e40ecb0b474482259582d.r2.dev/" +
                                    file.path
                                  }
                                  target="_blank"
                                >
                                  {file.name}
                                </a>
                              </td>
                              <td>
                                <div className="form-group mt-2">
                                  <input
                                    disabled
                                    type="text"
                                    className="form-control"
                                    value={file.description}
                                    placeholder="ادخل وصف"
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="col-12 user_personal_information">
                <div className="w-100 d-flex align-items-center mt-3">
                  <div className="user_step_main_info">
                    <div className="user_info_meaning"> روابط الفيديو</div>
                  </div>
                </div>
                <div className="user_information_hor mb-2"></div>
                <div className="row user_information_personal_form mt-4 pb-4">
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>الرقم</th>
                          <th>اسم الفيديو</th>
                          <th>وصف الفيديو</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(videos) &&
                          videos.map((video) => (
                            <tr key={video.id}>
                              <td>{video.id}</td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={video.name}
                                  placeholder="ادخل رابط الفيديو"
                                />
                              </td>
                              <td>
                                <div className="form-group ">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={video.description}
                                    placeholder="ادخل وصف"
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default Candidates;
