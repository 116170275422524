import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; 
import { logout } from '../../auth/Login';

const NewField = () => {
  
    const navigate = useNavigate(); 
    const [title, setTitle] = useState('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');

    
    useEffect(() => {
        const token = localStorage.getItem('token');
        const user_type = localStorage.getItem('user_type');
        if (!token ) {

            logout(navigate);
        }
        
        if ( user_type === "referee" || user_type === "client" ) {
            navigate(-1);
        }
    }, [navigate]);


    const handleSubmit = async (event) => {
        
        event.preventDefault();
        setError('');
        setMessage('');
    
        try {
            const response = await axios.post('https://te.prize.pap.sa/api/field', { title }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
    
            setMessage(response.data.message);
            navigate('/admin/dashboard/Fields');
        } catch (err) {
            if (err.response && err.response.data.errors) {
                const errorMessages = Object.values(err.response.data.errors).join(' ');
                setError(errorMessages);
            } else {

                if (err.response && err.response.data && err.response.data.error) {
                    // Use the error message from the response, if available
                    setError(`خطاء: ${err.response.data.error}`);
                } else {
                    // Fallback error message if no response or specific error message is available
                    setError('حدث خطأ أثناء جلب الحقول.');
                }
            }
        }
    };
    
    const containerStyle = {
        maxWidth: '400px',
        margin: '0 auto',
        padding: '20px',
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        textAlign: 'center',
    };

    const formStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '10px',
    };

    const inputStyle = {
        width: '100%',
        padding: '10px',
        margin: '6px 0',
        display: 'inline-block',
        border: '1px solid #ccc',
        borderRadius: '4px',
        boxSizing: 'border-box',
    };

    const buttonStyle = {
        width: '100%',
        backgroundColor: '#4CAF50',
        color: 'white',
        padding: '14px 20px',
        margin: '8px 0',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    };

    const buttonHoverStyle = {
        backgroundColor: '#45a049',
    };

    const errorStyle = {
        color: 'red',
        margin: '10px 0',
    };

    const messageStyle = {
        color: 'green',
        margin: '10px 0',
    };

    return (
      <div className="container-fluid mt-2">
        <div className="row admin_page_header">
          <div className="admin_page_title">اضافة المجالات</div>
          <div className="admin_page_subtitle mt-2">
            المجالات / اضافة المجالات
          </div>
        </div>
        <div className="admin_page_content  p-5 col-12 row mb-4">
          <form onSubmit={handleSubmit} style={formStyle}>
            <div>
              <label>اسم المجال:</label>
              <input
                className="form-field form-control"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                style={inputStyle}
              />
            </div>
            <div className="d-flex justify-content-center mb-5">
              <button
                className="ms-2 user_info_btn_produce px-5 py-2"
                type="submit"
                style={buttonStyle}
                onMouseOver={(e) =>
                  (e.target.style.backgroundColor =
                    buttonHoverStyle.backgroundColor)
                }
                onMouseOut={(e) => (e.target.style.backgroundColor = "#4CAF50")}
              >
                حفظ
              </button>
            </div>
          </form>
          {error && <div style={errorStyle}>{error}</div>}
          {message && <div style={messageStyle}>{message}</div>}
        </div>
      </div>
    );
};

export default NewField;