import React, { Suspense, useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { CircleSpinner } from "react-spinners-kit";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import TopBar from "./components/Dashboard/TopBar";
import SideBar from "./components/Dashboard/SideBar";
import MyRoutes from "./routes/MyRoutes";
import MyFooter from "./components/Dashboard/MyFooter";

import "./App.css";
import { Login } from "./pages/auth/Login";
import { UserProvider } from "./context/UserContext";
import Verification from "./pages/veryfication/Verification";
import ResetPassword from "./pages/restPassword/ResetPassword";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

export default function App() {
  const theme = useTheme();
  const [open, setOpen] = useState(window.innerWidth >= 1000); // Change condition here
  const [token, setToken] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setOpen(window.innerWidth >= 1000); // Change condition here
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
  
    if (!storedToken ) {
      // navigate("/");
    } else {
      setToken(storedToken);
    }
  }, [navigate]);

  

  return (
    <UserProvider>
      {token ? (
        <Box sx={{ backgroundColor: "#f8f8fb", minHeight: "100vh" }}>
          <CssBaseline />
          <TopBar open={open} handleDrawerOpen={() => setOpen(true)} />
          <SideBar open={open} handleDrawerClose={() => setOpen(false)} />{" "}
          {/* Modify this line */}
          <Box
            component="main"
            className={open ? "isopen" : "notopen"}
            sx={{ p: 3 }}
          >
            <DrawerHeader />
            <Typography paragraph sx={{ minHeight: "70vh" }}>
              <MyRoutes />
            </Typography>
            <Typography paragraph>
              <MyFooter />
            </Typography>
          </Box>
        </Box>
      ) : (
        <Routes>
          <Route
            path="/"
            element={
              <Suspense fallback={<CircleSpinner size={60} />}>
                <Login />
              </Suspense>
            }
          />
          <Route
            path="/verification"
            element={
              <Suspense fallback={<CircleSpinner size={60} />}>
                <Verification />
              </Suspense>
            }
          />
          <Route
            path="/reset_password"
            element={
              <Suspense fallback={<CircleSpinner size={60} />}>
                <ResetPassword />
              </Suspense>
            }
          />
        </Routes>
      )}
    </UserProvider>
  );
}
