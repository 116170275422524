import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './arbitiators.css';
import { useNavigate, } from 'react-router-dom';
import { logout } from '../../auth/Login';
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import ModeEditRoundedIcon from "@mui/icons-material/ModeEditRounded";


const Arbitrators = () => {
  const [referees, setReferees] = useState([]);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchQuery);
  const [refereeType, setRefereeType] = useState('');

  const [employee_premission, setEmployeePremission] = useState({});
  const [userType, setUserType] = useState("");


  const fetchDataEmployee = async () => {
    try {

      const user_id = localStorage.getItem('user_id');
      const response = await axios.get(`https://te.prize.pap.sa/api/employees/${user_id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });

      setEmployeePremission(response.data.employee);

    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        console.log(`خطاء: ${err.response.data.error}`);
      } else {
        console.log('حدث خطأ أثناء جلب الحقول.');
      }
    }
  };
  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchQuery);
    }, 500);

    return () => {
      clearTimeout(timerId);
    };
  }, [searchQuery]);


  useEffect(() => {
    fetchReferees(1, debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    fetchReferees(1, '', refereeType);
  }, [refereeType]);
  useEffect(() => {
    const token = localStorage.getItem('token');
    const user_type = localStorage.getItem('user_type');
    if (!token) {
      logout(navigate);
    }
    if (user_type === "referee" || user_type === "client") {
      navigate(-1);
    }
    setUserType(localStorage.getItem('user_type'));
    fetchDataEmployee();
    fetchReferees();
  }, [currentPage, navigate]);

  const fetchReferees = async (page = 1) => {
    try {
      const response = await axios.get(`https://te.prize.pap.sa/api/referees?page=${currentPage}&search=${searchQuery}&refereeType=${refereeType}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setReferees(response.data.referees);
      setTotalPages(response.data.last_page);

    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        setError(`خطاء: ${err.response.data.error}`);
      } else {
        setError('حدث خطأ أثناء جلب الحقول.');
      }
    }
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("انتبه انت علي وشك حذف  المحكم  ");
    if (!confirmDelete) return;

    try {
      await axios.post(`https://te.prize.pap.sa/api/delete/referee/${id}`, {}, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      fetchReferees();
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        setError(`خطاء: ${err.response.data.error}`);
      } else {
        setError('حدث خطأ أثناء جلب الحقول.');
      }
    }
  };

  const handleEdit = (refereeId) => {
    navigate('/admin/dashboard/arbitrator/edit', { state: { refereeId } });
  };



  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const NewReferee = () => {
    navigate('/admin/dashboard/arbitrator/new');
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setRefereeType(value);
  };



  const buttonStyle = {
    padding: '10px 20px',
    margin: '5px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  };
  const refereeTypesArabic = {
    'sort_references': 'فرز ',
    'grade_references': 'تقييم درجات',
    'social_media_references': 'تقييم وسائل التواصل الاجتماعي',
    'final_grade_referee':  'محكم درجات نهائي',
    'final_social_media_referee':  'محكم سوشيال ميديا نهائي'
  };
  
  return (
    <div className="container-fluid mt-2">
      <div className="row admin_page_header">
        <div className="admin_page_title">استعراض المحكمين</div>
        <div className="admin_page_subtitle mt-2">
          المحكمين / استعراض المحكمين
        </div>
      </div>

      <div className="row">
        <div className="admin_page_content col-12 row">
          <div className="col-12 row d-flex justify-content-between">
            <div className="col-md-8 row">
              <div className="col-md-6">
              <div className="search-container">
                <input
                  type="text"
                  placeholder="ابحث عن المحكمين...."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="search-input form-control"
                />
                {/* <button
                onClick={() => fetchReferees(1)}
                className="search-button"
              >
                <i className="fa fa-search"></i>
              </button> */}
              </div>
              </div>
              <div className="col-md-6">
                
              <div className="form-group">
                <select
                  className="form-field form-control"
                  name="referee_type"
                  value={refereeType}
                  onChange={handleChange}
                  required
                >
                  <option value="">الكل</option>
                  <option value="sort_references">محكمين فرز</option>
                  <option value="grade_references">محكمين درجات</option>
                  <option value="social_media_references">محكمين سوشيال ميديا</option>
                  <option value=" final_grade_referee">
                    محكم درجات نهائي 
                  </option>
                  <option value=" final_social_media_referee">
                  محكم سوشيال ميديا نهائي 
                  </option>
                </select>
              </div>
                </div>


            </div>
            <div className="col-md-4 d-flex justify-content-end">
              {employee_premission.referees?.store == true ? (
                <button
                  onClick={NewReferee}
                  style={buttonStyle}
                  className="admin_page_btn_new"
                >
                  + اضافة جديد
                </button>
              ) : (
                userType !== 'employee' && (
                  <button
                    onClick={NewReferee}
                    style={buttonStyle}
                    className="admin_page_btn_new"
                  >
                    + اضافة جديد
                  </button>
                )
              )}

            </div>
          </div>

          {error && <p style={{ color: "red" }}>{error}</p>}

          <>
            <div className="row mt-3">
              <div className="table-responsive col-12">
                <table className="table admin_table">
                  <thead className="admin_table_thead">
                    <tr>
                      <th>#</th>
                      <th>الاسم الاول</th>
                      <th>اسم العائلة</th>
                      <th>رقم الجوال</th>
                      <th>البريد الالكتروني</th>
                      <th>نوع المحكم</th>
                      <th>الإجراء الأساسي</th>
                    </tr>
                  </thead>
                  {referees.length > 0 ? (
                    <tbody>
                      {referees.map((referee) => (
                        <tr key={referee.id}>
                          <td>{referee.id}</td>
                          <td>{referee.user.first_name}</td>
                          <td>{referee.user.last_name}</td>
                          <td>{referee.user.phone}</td>
                          <td>{referee.user.email}</td>
                          <td>{refereeTypesArabic[referee.referee_type]}</td>
                          <td>
                            {employee_premission.referees?.destroy == true ? (
                              <button
                                className="admin_table_delete_btn"
                                onClick={() => handleDelete(referee.id)}
                              >
                                <DeleteForeverRoundedIcon />
                              </button>
                            ) : (
                              userType !== 'employee' && (
                                <button
                                  className="admin_table_delete_btn"
                                  onClick={() => handleDelete(referee.id)}
                                >
                                  <DeleteForeverRoundedIcon />
                                </button>
                              )
                            )}
                            {employee_premission.referees?.update == true ? (
                              <button
                                className="admin_table_edit_btn"
                                onClick={() => handleEdit(referee.id)}
                              >
                                <ModeEditRoundedIcon />
                              </button>
                            ) : (
                              userType !== 'employee' && (
                                <button
                                  className="admin_table_edit_btn"
                                  onClick={() => handleEdit(referee.id)}
                                >
                                  <ModeEditRoundedIcon />
                                </button>
                              )
                            )}


                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <p>لا يوجد بيانات</p>
                  )}
                </table>
              </div>
            </div>
            <div className="pagination">
              {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                <button
                  key={page}
                  className="button"
                  onClick={() => handlePageChange(page)}
                  disabled={page === currentPage}
                >
                  {page}
                </button>
              ))}
            </div>
          </>
        </div>
      </div>
    </div>
  );


};

export default Arbitrators;
