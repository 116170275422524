import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import REGIONS from "../../../common/REGIONS";
import CITIES from "../../../common/CITIES";
import image1 from "../../../how_enter_work/Enter.png";
import image2 from "../../../how_enter_work/Enter2.png";
import image3 from "../../../how_enter_work/step1.png";
import image4 from "../../../how_enter_work/step2.png";
import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Tabs,
} from "react-bootstrap";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import CircularProgress from "@mui/material/CircularProgress";
import "./Candidates.css";
const EditCandidatePage = () => {
  const { clientId } = useParams();
  const [userData, setUserData] = useState(null);
  const user_id = localStorage.getItem("user_id");
  const [termsChecked, setTermsChecked] = useState(false);
  const [age, setAge] = useState(0);
  const navigate = useNavigate();
  const [howEnterWork, sethowEnterWork] = useState(false);

  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [filteredCities, setFilteredCities] = useState([]);
  const [occupation, setOccupation] = useState("");

  const [fieldErrors, setFieldErrors] = useState({});
  //use state commerce
  const [isCertified, setIsCertified] = useState(false);
  const [ishasorgnization, setishasorgnization] = useState(false);
  const [hasQualifications, sethasQualifications] = useState(false);
  const [userDataCommerce, setUserDataCommerce] = useState({}); //
  const [termsCheckedCommerce, setTermsCheckedCommerce] = useState(false); //
  const [files, setFiles] = useState([]);
  const [videos, setVideos] = useState([]);
  const [videoName, setVideoName] = useState("");
  const [videoDescription, setVideoDescription] = useState("");
  const [wetsite, setWrbsite] = useState([]);
  const [website_sale, setWrbsite_sale] = useState("");
  const [url_website_sale, setUrl_websit_sale] = useState("");
  const [countWevsite, setCountWebsite] = useState(1);
  const [keyWebsite, setKeyWebsite] = useState("");
  const [updatedFiles, setUpdatedFiles] = useState([]);
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [countfiles, setCountFiles] = useState(1);
  const [countVideos, setCountVideos] = useState(1);
  const [selectedFile, setSelectedFile] = useState(null);
  const [ageActivity, setAgeActivity] = useState(0); //
  const [errorsback, setErrorsback] = useState({});
  const [loading, setLoading] = useState(false);
  const [openedTap, setopenedTap] = useState(false);
  const [client_id, setClient_id] = useState("0");
  const [field_id, setField_id] = useState("0");
  const [branch_id, setBranch_id] = useState("0");
  const [value, setValue] = React.useState("1");

  //end use state commerce

  const calculateAge = (birthDate) => {
    const birthday = new Date(birthDate);
    const today = new Date();

    if (birthday > today) {
      // If the birth date is in the future, set age to an invalid state or zero
      return "";
    }

    let age = today.getFullYear() - birthday.getFullYear();
    const m = today.getMonth() - birthday.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
      age--;
    }
    return age;
  };

  const handleInputChange = (e, section, field) => {
    if (field === "birth_date") {
      const newAge = calculateAge(e.target.value);
      setAge(newAge);
    }
    if (field === "occupation") {
      setOccupation(e.target.value);
    }

    setUserData((prevData) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [field]: e.target.value,
      },
    }));
  };

  useEffect(() => {
    if (clientId) {
      fetch(`https://te.prize.pap.sa/api/client/${clientId}`)
        .then((response) => response.json())
        .then((data) => {
          const currentday = new Date();
          const birthday2 = new Date(data.user_data.birth_date);
          if (data.user_data.birth_date != null) {
            const neAge = currentday.getFullYear() - birthday2.getFullYear();
            setAge(neAge);
          }

          setUserData(data);
          if (data.user_data.region) {
            const cities = CITIES.filter(
              (city) => city.region_id === parseInt(data.user_data.region)
            );
            setFilteredCities(cities);
            setSelectedCity(data.user_data.city);
          }
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });
    }
  }, [clientId]);
  useEffect(() => {
    if (clientId) {
      fetch(`https://te.prize.pap.sa/api/client/${clientId}`)
        .then((response) => response.json())
        .then((data) => {
          const currentday = new Date();
          const duration = new Date(data.commerce.start_documnet);
          if (data.commerce.start_documnet != null) {
            const newAgeActivity =
              currentday.getFullYear() - duration.getFullYear();
            setAgeActivity(newAgeActivity);
          }
          setUserDataCommerce(data);

          if (data.commerce.charity) {
            setishasorgnization(true);
          }
          if (data.commerce.courses_qualification) {
            sethasQualifications(true);
          }
          if (data.commerce.number_documntation) {
            setIsCertified(true);
          }
          const filesArray = JSON.parse(data.commerce.files_document);
          setFiles(filesArray);
          if (filesArray.length > 0) {
            // Get the last element in the array
            const lastFile = filesArray[filesArray.length - 1];
            setCountFiles(lastFile.id + 1);
          }
          const videosArray = JSON.parse(data.commerce.videos);
          setVideos(videosArray);
          if (videosArray.length > 0) {
            // Get the last element in the array
            const lastVideo = videosArray[videosArray.length - 1];
            setCountVideos(lastVideo.id + 1);
          }

          const websiteArray = JSON.parse(data.commerce.website_sale);
          setWrbsite(websiteArray);
          if (websiteArray.length > 0) {
            // Get the last element in the array
            const lastWebsite = websiteArray[websiteArray.length - 1];
            setCountWebsite(lastWebsite.id + 1);
          }
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });
    }
  }, []);
  const [userUpdated, setUserUpdated] = useState({
    // ... other fields
  });
  const handleSaveDraft = () => {
    setFieldErrors({});
    let newErrors = {};
    let userUpdated = {
      first_name:
        (userData && userData.user_data && userData.user_data.first_name) || "",
      father_name:
        (userData && userData.user_data && userData.user_data.father_name) ||
        "",
      grandfather_name:
        (userData &&
          userData.client_data &&
          userData.client_data.grandfather_name) ||
        "",
      last_name:
        (userData && userData.user_data && userData.user_data.last_name) || "",
      // ssn:userData && userData.user_data && userData.user_data.ssn || '',
      gender:
        (userData && userData.user_data && userData.user_data.gender) || "",
      nationality:
        (userData && userData.user_data && userData.user_data.nationality) ||
        "",
      birth_date:
        (userData && userData.user_data && userData.user_data.birth_date) || "",
      // phone:userData && userData.user_data && userData.user_data.phone || '',
      phone2:
        (userData && userData.user_data && userData.user_data.phone2) || "",
      // email:userData && userData.user_data && userData.user_data.email || '',
      qualification_id:
        (userData &&
          userData.client_data &&
          userData.client_data.qualification_id) ||
        "",
      region:
        (userData && userData.user_data && userData.user_data.region) || "",
      city: (userData && userData.user_data && userData.user_data.city) || "",
      occupation: (userData && userData.work && userData.work.occupation) || "",
      current_workplace:
        (userData && userData.work && userData.work.current_workplace) || "",
      academic: (userData && userData.work && userData.work.academic) || "",
      academic_level:
        (userData && userData.work && userData.work.academic_level) || "",
      position: (userData && userData.work && userData.work.position) || "",
      previous_workplaces:
        (userData && userData.work && userData.work.previous_workplaces) || "",
      years_of_experience:
        (userData && userData.work && userData.work.years_of_experience) || "",
      skills: (userData && userData.work && userData.work.skills) || "",
      personal_summary:
        (userData && userData.work && userData.work.personal_summary) || "",
      father_name:
        (userData &&
          userData.client_data &&
          userData.client_data.father_name) ||
        "",
      twitter:
        (userData &&
          userData.social_personal &&
          userData.social_personal.twitter) ||
        "",
      snapchat:
        (userData &&
          userData.social_personal &&
          userData.social_personal.snapchat) ||
        "",
      instagram:
        (userData &&
          userData.social_personal &&
          userData.social_personal.instagram) ||
        "",
      tiktok:
        (userData &&
          userData.social_personal &&
          userData.social_personal.tiktok) ||
        "",
      youtube:
        (userData &&
          userData.social_personal &&
          userData.social_personal.youtube) ||
        "",
      whatsapp:
        (userData &&
          userData.social_personal &&
          userData.social_personal.whatsapp) ||
        "",
      telegram:
        (userData &&
          userData.social_personal &&
          userData.social_personal.telegram) ||
        "",
      others:
        (userData &&
          userData.social_personal &&
          userData.social_personal.others) ||
        "",
    };

    if (!userData.user_data.first_name) {
      newErrors.first_name = "هذا الحقل الزامي";
    }
    if (!userData.user_data.last_name) {
      newErrors.last_name = "هذا الحقل الزامي";
    }
    if (!userData.user_data.gender) {
      newErrors.gender = "هذا الحقل الزامي";
    }
    if (!userData.user_data.nationality) {
      newErrors.nationality = "هذا الحقل الزامي";
    }
    if (!userData.user_data.birth_date) {
      newErrors.birth_date = "هذا الحقل الزامي";
    }
    if (!userData.user_data.region) {
      newErrors.region = "هذا الحقل الزامي";
    }
    if (!userData.client_data.qualification_id) {
      newErrors.qualification_id = "هذا الحقل الزامي";
    }
    if (!userData.user_data.city) {
      newErrors.city = "هذا الحقل الزامي";
    }

    if (!userData.work.occupation) {
      newErrors.occupation = "هذا الحقل الزامي";
    }
    if (!userData.work.personal_summary) {
      newErrors.personal_summary = "هذا الحقل الزامي";
    }

    let filteredUserUpdated = Object.keys(userUpdated).reduce((acc, key) => {
      if (userUpdated[key] !== null && userUpdated[key] !== undefined) {
        acc[key] = userUpdated[key];
      }
      return acc;
    }, {});

    if (Object.keys(newErrors).length > 0) {
      // Set the errors to state
      setFieldErrors(newErrors);

      // Show Swal error message
      Swal.fire({
        text: "يوجد خطأ ! تأكد أن كافة البيانات التى تحتوى علي نجمة إلزامية",
        icon: "error",
        confirmButtonText: "حسنا",
      });
    } else {
      axios
        .patch(
          `https://te.prize.pap.sa/api/client/${user_id}`,
          filteredUserUpdated
        )
        .then((response) => {
          Swal.fire({
            text: "تم الحفظ كمسودة بنجاح",
            icon: "success",
            confirmButtonText: "حسنا",
          });
        })
        .catch((error) => {
          console.error("Error saving draft:", error);
        });
    }
  };

  const handleApplyApplication = () => {
    let userUpdated = {
      first_name:
        (userData && userData.user_data && userData.user_data.first_name) || "",
      grandfather_name:
        (userData &&
          userData.client_data &&
          userData.client_data.grandfather_name) ||
        "",
      last_name:
        (userData && userData.user_data && userData.user_data.last_name) || "",
      ssn: (userData && userData.user_data && userData.user_data.ssn) || "",
      gender:
        (userData && userData.user_data && userData.user_data.gender) || "",
      nationality:
        (userData && userData.user_data && userData.user_data.nationality) ||
        "",
      birth_date:
        (userData && userData.user_data && userData.user_data.birth_date) || "",
      phone: (userData && userData.user_data && userData.user_data.phone) || "",
      phone2:
        (userData && userData.user_data && userData.user_data.phone2) || "",
      email: (userData && userData.user_data && userData.user_data.email) || "",
      qualification_id:
        (userData &&
          userData.client_data &&
          userData.client_data.qualification_id) ||
        "",
      region:
        (userData && userData.user_data && userData.user_data.region) || "",
      city: (userData && userData.user_data && userData.user_data.city) || "",
      occupation: (userData && userData.work && userData.work.occupation) || "",
      current_workplace:
        (userData && userData.work && userData.work.current_workplace) || "",
      academic: (userData && userData.work && userData.work.academic) || "",
      academic_level:
        (userData && userData.work && userData.work.academic_level) || "",
      position: (userData && userData.work && userData.work.position) || "",
      previous_workplaces:
        (userData && userData.work && userData.work.previous_workplaces) || "",
      years_of_experience:
        (userData && userData.work && userData.work.years_of_experience) || "",
      skills: (userData && userData.work && userData.work.skills) || "",
      personal_summary:
        (userData && userData.work && userData.work.personal_summary) || "",
      father_name:
        (userData &&
          userData.client_data &&
          userData.client_data.father_name) ||
        "",
    };

    Swal.fire({
      title: "تأكيد",
      text: "سيتم تقديم طلبك بشكل نهائي هل تحققت من كامل المعلومات والبيانات؟",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "تأكيد",
      cancelButtonText: "إلغاء",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .put(
            `https://te.prize.pap.sa/api/client/updateClientByAdmin/${clientId}`,
            userUpdated
          )
          .then((response) => {
            // Navigate and reload after successful POST request
            navigate("/admin/dashboard/candidates", { replace: true });
            window.location.reload();
          })
          .catch((error) => {
            console.error("Error processing your request:", error);
            setFieldErrors(error.response.data.errors);
            Swal.fire({
              text: "يوجد خطأ ! تأكد أن كافة البيانات التى تحتوى علي نجمة إلزامية",
              icon: "error",
              confirmButtonText: "حسنا",
            });
          });
      }
    });
  };

  const handleSuccess = () => {
    toast.success("تم تقديم الطلب بنجاح", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const handleCheckboxChange = (e) => {
    setTermsChecked(e.target.checked);
  };

  const handleRegionChange = (e) => {
    setSelectedRegion(e.target.value);
    setUserData((prevData) => ({
      ...prevData,
      user_data: {
        ...prevData.user_data,
        region: e.target.value,
      },
    }));

    const cities = CITIES.filter(
      (city) => city.region_id === parseInt(e.target.value)
    );
    setFilteredCities(cities);
    setSelectedCity(""); 
  };

  const handleCityChange = (e) => {
    setSelectedCity(e.target.value);
    setUserData((prevData) => ({
      ...prevData,
      user_data: {
        ...prevData.user_data,
        city: e.target.value,
      },
    }));
  };
  
  //function commerce
  //function for comemrce
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const calculateDuration = (duration) => {
    const oldduration = new Date(duration);
    const today = new Date();

    if (oldduration > today) {
      // If the birth date is in the future, set age to an invalid state or zero
      return "";
    }

    let age = today.getFullYear() - oldduration.getFullYear();
    const m = today.getMonth() - oldduration.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < oldduration.getDate())) {
      age--;
    }
    return age;
  };
  const handleFileSelection = (e) => {
    setSelectedFile(e.target.files[0]);
    setCountFiles(countfiles + 1);
    const newFiles = {
      id: countfiles,
      name: e.target.files[0].name,
      description: "",
      path: "",
      file: e.target.files[0],
    };
    setFiles([...files, newFiles]);
  };
  //
  const handleCheckboxCommerceChange = (e) => {
    setTermsCheckedCommerce(e.target.checked);
  };

  const handleAddFile = async (e) => {
    e.preventDefault();
    if (selectedFile) {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("files[]", selectedFile);

        const response = await axios.post(
          "https://te.prize.pap.sa/api/commerce/changeImage",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // Get the path from the response
        const newPath = response.data.file_paths[0];

        // Update the path property of the last file in the files array
        setFiles((prevFiles) => {
          const lastFile = prevFiles[prevFiles.length - 1];
          if (lastFile) {
            return [
              ...prevFiles.slice(0, prevFiles.length - 1),
              {
                ...lastFile,
                path: newPath,
              },
            ];
          }
          return prevFiles;
        });
        Swal.fire({
          title: "Success!",
          text: "تم تحميل الملف بنجاح",
          icon: "success",
          confirmButtonText: "حسنا",
        });
        // Clear the selected file
        setSelectedFile(null);
      } catch (error) {
        console.error("Error adding file:", error);
        Swal.fire({
          text: "لم يتم تحميل الملف",
          icon: "error",
          confirmButtonText: "حسنا",
        });
      } finally {
        setLoading(false);
      }
    }
  };

  //
  const handleInputCommerceChange = (e, section, field) => {
    if (field === "start_documnet") {
      const newAge = calculateDuration(e.target.value);
      setAgeActivity(newAge);
    }
    setUserDataCommerce((prevData) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [field]: e.target.value,
      },
    }));
  };
  //
  const handleApplyApplicationCommerce = () => {
    let userUpdated = {
      user_id: userData.user_data.id,
      field_id: userData.client_data.field_id || "",
      branch_id: userData.client_data.branch_id || "",
      type: "commerce",
      commerce_status: true,
      type_work_documnet:
        (userDataCommerce &&
          userDataCommerce.commerce &&
          userDataCommerce.commerce.type_work_documnet) ||
        "",
      number_documnet:
        (userDataCommerce &&
          userDataCommerce.commerce &&
          userDataCommerce.commerce.number_documnet) ||
        "",
      number_documntation:
        (userDataCommerce &&
          userDataCommerce.commerce &&
          userDataCommerce.commerce.number_documntation) ||
        "",
      start_documnet:
        (userDataCommerce &&
          userDataCommerce.commerce &&
          userDataCommerce.commerce.start_documnet) ||
        "",
      duration_activity: ageActivity || "",
      end_documnet:
        (userDataCommerce &&
          userDataCommerce.commerce &&
          userDataCommerce.commerce.end_documnet) ||
        "",
      name_activity:
        (userDataCommerce &&
          userDataCommerce.commerce &&
          userDataCommerce.commerce.name_activity) ||
        "",
      website_sale: wetsite,
      charity:
        (userDataCommerce &&
          userDataCommerce.commerce &&
          userDataCommerce.commerce.charity) ||
        "",

      courses_qualification:
        (userDataCommerce &&
          userDataCommerce.commerce &&
          userDataCommerce.commerce.courses_qualification) ||
        "",
      summary:
        (userDataCommerce &&
          userDataCommerce.commerce &&
          userDataCommerce.commerce.summary) ||
        "",
      twitter:
        (userDataCommerce &&
          userDataCommerce.social_commerce &&
          userDataCommerce.social_commerce.twitter) ||
        "",
      snapchat:
        (userDataCommerce &&
          userDataCommerce.social_commerce &&
          userDataCommerce.social_commerce.snapchat) ||
        "",
      instagram:
        (userDataCommerce &&
          userDataCommerce.social_commerce &&
          userDataCommerce.social_commerce.instagram) ||
        "",
      tiktok:
        (userDataCommerce &&
          userDataCommerce.social_commerce &&
          userDataCommerce.social_commerce.tiktok) ||
        "",
      youtube:
        (userDataCommerce &&
          userDataCommerce.social_commerce &&
          userDataCommerce.social_commerce.youtube) ||
        "",
      whatsapp:
        (userDataCommerce &&
          userDataCommerce.social_commerce &&
          userDataCommerce.social_commerce.whatsapp) ||
        "",
      telegram:
        (userDataCommerce &&
          userDataCommerce.social_commerce &&
          userDataCommerce.social_commerce.telegram) ||
        "",
      website:
        (userDataCommerce &&
          userDataCommerce.social_commerce &&
          userDataCommerce.social_commerce.website) ||
        "",
      others:
        (userDataCommerce &&
          userDataCommerce.social_commerce &&
          userDataCommerce.social_commerce.others) ||
        "",
      files: files.map((file) => ({
        id: file.id,
        name: file.name,
        description: file.description,
        path: file.path,
        file: file.file,
        // Include other properties if needed
      })),
      videos: videos,
    };

    if (!termsCheckedCommerce) {
      Swal.fire({
        text: "يجب الموافقة على الشروط والاحكام",
        icon: "warning",
        confirmButtonText: "حسنا",
      });
    } else {
      Swal.fire({
        title: "تأكيد",
        text: "سيتم تقديم طلبك بشكل نهائي هل تحققت من كامل المعلومات والبيانات؟",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "تأكيد",
        cancelButtonText: "إلغاء",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .put(
              `https://te.prize.pap.sa/api/commerce/updateWhenApplyCommerce/${userData.user_data.id}`,
              userUpdated
            )
            .then((response) => {
              return Swal.fire({
                text: "تم تثديم الطلب بنجاح",
                icon: "success",
                confirmButtonText: "حسنا",
              });
            })
            .then((response) => {
              // Handle the response of the POST request
              handleSuccess();
              navigate("/admin/dashboard/candidates", { replace: true });
              window.location.reload();
            })
            .catch((error) => {
              console.error(
                "Error processing your request:",
                error.response.data
              );

              if (error.response && error.response.data.errors) {
                setErrorsback(error.response.data.errors);
                Swal.fire({
                  text: "يوجد خطأ :)  تأكد أن كافة البيانات التى تحتوى علي نجمة إلزامية",
                  icon: "error",
                  confirmButtonText: "حسنا",
                });
              } else {
                setErrorsback(error.response.data.errors);
                Swal.fire({
                  text: "يوجد خطأ :) !!!!!!!!",
                  icon: "error",
                  confirmButtonText: "حسنا",
                });
              }
            });
        }
      });
    }
  };

  const handleFileChange = (event) => {
    setCountFiles(countfiles + 1);
    const filesArray = event.target.files;
    const newFiles = Array.from(filesArray).map((file, index) => ({
      id: countfiles,
      name: file.name,
      description: "",
      path: "",
      file: file,
    }));
    setFiles([...files, ...newFiles]);
    setUpdatedFiles([...updatedFiles, ...newFiles]);
  };
  const handleVideoChange = () => {
    setCountVideos(countVideos + 1);
    const newVideo = {
      id: countVideos,
      name: videoName || "",
      description: videoDescription || "",
    };

    setVideos([...videos, newVideo]);
    setVideoName("");
    setVideoDescription("");
  };
  const handleWebsiteSalesChange = (e) => {
    e.preventDefault();
    setCountWebsite(countWevsite + 1);
    const newWensite = {
      id: countWevsite,
      key: keyWebsite || "",
      description: url_website_sale || "",
    };
    setWrbsite([...wetsite, newWensite]);
    setKeyWebsite("");
    setUrl_websit_sale("");
  };
  const handleDescriptionChange = (id, description) => {
    const updatedFiles = files.map((file) => {
      if (file.id === id) {
        return { ...file, description };
      }
      return file;
    });
    setFiles(updatedFiles);
  };
  const handleNameChange = (id, name) => {
    const updatedVideo = videos.map((video) => {
      if (video.id === id) {
        return { ...video, name };
      }
      return video;
    });
    setVideos(updatedVideo);
  };
  const handleWebsiteNameChange = (e, id) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const key = selectedOption.value;
    const updatedWebsite = wetsite.map((webSite) => {
      if (webSite.id === id) {
        return { ...webSite, key };
      }
      return webSite;
    });
    setWrbsite(updatedWebsite);
  };
  const handleDescriptionVideoChange = (id, description) => {
    const updatedVideo = videos.map((video) => {
      if (video.id === id) {
        return { ...video, description };
      }
      return video;
    });
    setVideos(updatedVideo);
  };
  const handleDescriptionWebsiteChange = (id, description) => {
    const updatedWebsite = wetsite.map((website) => {
      if (website.id === id) {
        return { ...website, description };
      }
      return website;
    });

    setWrbsite(updatedWebsite);
  };
  const handleDelete = async (id, path) => {
    setLoading(true);
    const updatedFiles = files.filter((file) => file.id !== id);
    setFiles(updatedFiles);
    setDeletedFiles([...deletedFiles, path]);
    const response = await axios.post(
      "https://te.prize.pap.sa/api/commerce/deleteImage",
      path
    );
    Swal.fire({
      title: "Success!",
      text: "تم حذف الملف بنجاح",
      icon: "success",
      confirmButtonText: "حسنا",
    });
    setLoading(false);
  };
  const handleDeleteVideo = (id, path) => {
    const updatedVideos = videos.filter((video) => video.id !== id);
    setVideos(updatedVideos);
  };
  const handleDeleteWebsite = (id) => {
    const updatedWebsite = wetsite.filter((website) => website.id !== id);
    setWrbsite(updatedWebsite);
  };
  const postimages = async () => {
    let formData = new FormData();
    files.forEach((file) => {
      formData.append("files[]", file.file);
      formData.append("ids[]", file.id); // Include the id in the form data
    });
    formData.append("deletedFiles[]", deletedFiles);

    try {
      const response = await axios.post(
        "https://te.prize.pap.sa/api/commerce/changeImage",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const updatedFiles = files.map((file) => {
        const matchingFile = response.data.file_paths.find(
          (path) => path.id == file.id
        );
        if (matchingFile) {
          return {
            ...file,
            path: matchingFile.path,
          };
        }

        return file;
      });

      await setFiles(updatedFiles);
    } catch (error) {
      console.error("Error uploading files:", error);
      // Handle the error as needed
    }
  };

  return (
    <div className="container-fluid mt-2">
      <div className="row admin_page_header">
        <div className="admin_page_title">تعديل المرشح</div>
        <div className="admin_page_subtitle mt-2">المرشحين / تعديل المرشح</div>
      </div>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab
                className="admin_tab"
                label="المعلومات الشخصيه"
                value="1"
                sx={{ fontSize: "1.2rem" }}
              />
              <Tab
                className="admin_tab"
                label="المعلومات التجاريه"
                value="2"
                sx={{ fontSize: "1.2rem" }}
              />
            </TabList>
          </Box>
          <TabPanel value="1">
            <div className="row">
              {/* user personal information */}
              <div className="col-12 user_personal_information">
                <div className="w-100 d-flex align-items-center mt-3">
                  <div className="mx-2"></div>
                  <div className="user_step_main_info">
                    <div className="user_info_meaning">معلومات شخصية</div>
                    <div className="user_information_confirm">
                      جميع الحقول التي تحتوي على (*) تعتبر الزامية
                    </div>
                  </div>
                </div>
                <div className="user_information_hor mb-2"></div>
                <form action="">
                  {userData && (
                    <>
                      <div className="row user_information_personal_form mt-4 pb-4">
                        <div className="form-group mb-5 col-md-6 col-xl-3">
                          <label htmlFor="first_name">الاسم الأول</label>
                          <input
                            type="text"
                            className="form-control"
                            id="first_name"
                            placeholder=""
                            value={userData.user_data.first_name}
                            onChange={(e) =>
                              handleInputChange(e, "user_data", "first_name")
                            }
                          />
                          {fieldErrors?.first_name && (
                            <div className="text-danger">
                              {fieldErrors?.first_name}
                            </div>
                          )}
                        </div>
                        <div className="form-group mb-5 col-md-6 col-xl-3">
                          <label htmlFor="father_name">اسم الاب </label>
                          <input
                            type="text"
                            className="form-control"
                            id="father_name"
                            placeholder="اسم الاب"
                            value={userData.client_data.father_name}
                            onChange={(e) =>
                              handleInputChange(e, "client_data", "father_name")
                            }
                          />
                        </div>
                        <div className="form-group mb-5 col-md-6 col-xl-3">
                          <label htmlFor="grandfather_name">اسم الجد</label>
                          <input
                            type="text"
                            className="form-control"
                            id="grandfather_name"
                            placeholder="اسم الجد"
                            value={userData.client_data.grandfather_name}
                            onChange={(e) =>
                              handleInputChange(
                                e,
                                "client_data",
                                "grandfather_name"
                              )
                            }
                          />
                        </div>
                        <div className="form-group mb-5 col-md-6 col-xl-3">
                          <label htmlFor="last_name">اسم العائة</label>
                          <input
                            type="text"
                            className="form-control"
                            id="last_name"
                            placeholder=""
                            value={userData.user_data.last_name}
                            onChange={(e) =>
                              handleInputChange(e, "user_data", "last_name")
                            }
                          />
                          {fieldErrors?.last_name && (
                            <div className="text-danger">
                              {fieldErrors?.last_name}
                            </div>
                          )}
                        </div>
                        <div className="form-group mb-5 col-md-6 col-xl-3">
                          <label htmlFor="ssn">رقم الهوية</label>
                          <input
                            type="text"
                            className="form-control"
                            id="ssn"
                            placeholder=""
                            value={userData.user_data.ssn}
                            onChange={(e) =>
                              handleInputChange(e, "user_data", "ssn")
                            }
                          />
                          {fieldErrors?.ssn && (
                            <div className="text-danger">
                              {fieldErrors?.ssn}
                            </div>
                          )}
                        </div>
                        <div className="form-group mb-5 col-md-6 col-xl-3">
                          <label htmlFor="gender">الجنس</label>
                          <select
                            className="form-control"
                            id="gender"
                            value={userData.user_data.gender}
                            onChange={(e) =>
                              handleInputChange(e, "user_data", "gender")
                            }
                          >
                            <option value="" disabled selected>
                              حدد
                            </option>
                            <option value="male">ذكر</option>
                            <option value="female">أنثى</option>
                          </select>
                          {fieldErrors?.gender && (
                            <div className="text-danger">
                              {fieldErrors?.gender}
                            </div>
                          )}
                        </div>
                        <div className="form-group mb-5 col-md-6 col-xl-3">
                          <label htmlFor="nationality">الجنسية</label>
                          <select
                            className="form-control"
                            id="nationality"
                            value={userData.user_data.nationality}
                            onChange={(e) =>
                              handleInputChange(e, "user_data", "nationality")
                            }
                          >
                            <option value="" disabled selected>
                              حدد الجنسية
                            </option>
                            <option value="sa">السعودية</option>
                            <option value="son_or_dagauter_home">
                              ابن / ابنة مواطنة
                            </option>
                          </select>
                          {fieldErrors?.nationality && (
                            <div className="text-danger">
                              {fieldErrors?.nationality}
                            </div>
                          )}
                        </div>
                        <div className="form-group mb-5 col-md-6 col-xl-3">
                          <label htmlFor="birth_date">
                            العمر(اختر تاريخ الميلاد)
                          </label>
                          <div className="input-group">
                            <input
                              type="date"
                              className="form-control user_phone_input"
                              id="birth_date"
                              aria-describedby="emailHelp"
                              value={userData.user_data.birth_date}
                              onChange={(e) =>
                                handleInputChange(e, "user_data", "birth_date")
                              }
                            />
                            <div className="input-group-append">
                              <button className="btn user_btn" type="button">
                                {age !== "" ? `${age} سنة` : ""}
                              </button>
                            </div>
                          </div>
                          {fieldErrors?.birth_date && (
                            <div className="text-danger">
                              {fieldErrors?.birth_date}
                            </div>
                          )}
                        </div>
                        <div className="form-group mb-5 col-md-6 col-xl-3">
                          <label htmlFor="phone">رقم الجوال</label>
                          <div className="input-group">
                            <input
                              type="tel"
                              className={`form-control user_phone_input`}
                              id="phone"
                              aria-describedby="emailHelp"
                              placeholder="5xxxxxxxx"
                              value={
                                userData.user_data.phone
                                  ? userData.user_data.phone.slice(4)
                                  : ""
                              }
                              onChange={(e) => {
                                const updatedPhone = `+966${e.target.value}`;
                                handleInputChange(
                                  {
                                    target: {
                                      id: "phone",
                                      value: updatedPhone,
                                    },
                                  },
                                  "user_data",
                                  "phone"
                                );
                              }}
                            />
                            <div className="input-group-append">
                              <button className="btn user_btn" type="button">
                                966+
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="form-group mb-5 col-md-6 col-xl-3">
                          <label htmlFor="phone2">رقم هاتف بديل</label>
                          <div className="input-group">
                            <input
                              type="tel"
                              className="form-control user_phone_input"
                              id="phone2"
                              aria-describedby="emailHelp"
                              value={
                                userData.user_data.phone2
                                  ? userData.user_data.phone2.slice(4)
                                  : ""
                              }
                              placeholder="5xxxxxxxx"
                              maxLength={9}
                              minLength={9}
                              onChange={(e) => {
                                const updatedPhone = `+966${e.target.value}`;
                                handleInputChange(
                                  {
                                    target: {
                                      id: "phone2",
                                      value: updatedPhone,
                                    },
                                  },
                                  "user_data",
                                  "phone2"
                                );
                              }}
                            />
                            <div className="input-group-append">
                              <button className="btn user_btn" type="button">
                                966+
                              </button>
                            </div>
                          </div>
                          {fieldErrors?.phone && (
                            <div className="text-danger">
                              {fieldErrors?.phone}
                            </div>
                          )}
                        </div>

                        <div className="form-group mb-5 col-md-6 col-xl-3">
                          <label htmlFor="grandfather_name">
                            {" "}
                            البريد الالكتروني{" "}
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder=""
                            value={userData.user_data.email}
                            onChange={(e) =>
                              handleInputChange(e, "user_data", "email")
                            }
                          />
                          {fieldErrors?.email && (
                            <div className="text-danger">
                              {fieldErrors?.email}
                            </div>
                          )}
                        </div>
                        <div className="form-group mb-5 col-md-6 col-xl-3">
                          <label htmlFor="qualification">المؤهل العلمي</label>
                          <select
                            className="form-control"
                            id="qualification"
                            value={userData.client_data.qualification_id}
                            onChange={(e) =>
                              handleInputChange(
                                e,
                                "client_data",
                                "qualification_id"
                              )
                            }
                          >
                            <option value="" disabled selected>
                              حدد
                            </option>
                            <option value="1">امي</option>
                            <option value="2">ابتدائي</option>
                            <option value="3">متوسط</option>
                            <option value="4">ثانوي</option>
                            <option value="5">دبلوم</option>
                            <option value="6">بكالوريوس</option>
                            <option value="7">ماجستير</option>
                            <option value="8">دكتوراه</option>
                          </select>
                          {fieldErrors?.qualification_id && (
                            <div className="text-danger">
                              {fieldErrors?.qualification_id}
                            </div>
                          )}
                        </div>
                        <div className="form-group mb-5 col-md-6 col-xl-3">
                          <label htmlFor="region">المنطقة</label>
                          <select
                            className="form-control"
                            id="region"
                            value={selectedRegion || userData.user_data.region}
                            onChange={handleRegionChange}
                          >
                            <option value="">اختر المنطقة</option>
                            {REGIONS.map((region) => (
                              <option
                                key={region.region_id}
                                value={region.region_id}
                              >
                                {region.name_ar}
                              </option>
                            ))}
                          </select>
                          {fieldErrors?.region && (
                            <div className="text-danger">
                              {fieldErrors?.region}
                            </div>
                          )}
                        </div>
                        <div className="form-group mb-5 col-md-6 col-xl-3">
                          <label htmlFor="city">المدينة </label>
                          <select
  className="form-control"
  id="city"
  value={selectedCity || userData.user_data.city}
  onChange={handleCityChange}
>
  <option value="">اختر المدينة</option>
  {filteredCities.map((city) => (
    <option key={city.city_id} value={city.city_id}>
      {city.name_ar}
    </option>
  ))}
</select>


                          {fieldErrors?.city && (
                            <div className="text-danger">
                              {fieldErrors?.city}
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </form>
              </div>
              {/* end user personal enformation */}

              {/* user work information */}
              <div className="col-12 user_personal_information">
                <div className="w-100 d-flex align-items-center mt-3">
                  <div className="mx-2"></div>
                  <div className="user_step_main_info">
                    <div className="user_info_meaning">معلومات العمل</div>
                    <div className="user_information_confirm">
                      جميع الحقول التي تحتوي على (*) تعتبر الزامية
                    </div>
                  </div>
                </div>
                <div className="user_information_hor mb-2"></div>
                <form action="">
                  <div className="row user_information_personal_form mt-4 pb-4">
                    <div className="form-group mb-5 col-md-6 col-xl-3">
                      <label htmlFor="occupation">المهنة</label>
                      <select
                        className="form-control"
                        id="occupation"
                        value={
                          userData && userData.work && userData.work.occupation
                        }
                        onChange={(e) =>
                          handleInputChange(e, "work", "occupation")
                        }
                      >
                        <option value="" disabled selected>
                          حدد
                        </option>
                        <option value="student">طالب</option>
                        <option value="government">موظف حكومي</option>
                        <option value="private">موظف قطاع خاص</option>
                        <option value="trader">تاجر</option>
                        <option value="freelancer">عمل حر</option>
                        <option value="retired">متقاعد</option>
                        <option value="unemployed">بدون عمل</option>
                      </select>
                      {fieldErrors?.occupation && (
                        <div className="text-danger">
                          {fieldErrors?.occupation}
                        </div>
                      )}
                    </div>
                    {occupation !== "student" &&
                      occupation !== "retired" &&
                      occupation !== "unemployed" && (
                        <div className="form-group mb-5 col-md-6 col-xl-3">
                          <label htmlFor="current_workplace">
                            {" "}
                            جهة العمل الحالية{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="current_workplace"
                            placeholder=""
                            value={
                              userData &&
                              userData.work &&
                              userData.work.current_workplace
                            }
                            onChange={(e) =>
                              handleInputChange(e, "work", "current_workplace")
                            }
                          />
                        </div>
                      )}
                    {occupation !== "student" && (
                      <div className="form-group mb-5 col-md-6 col-xl-6">
                        <label htmlFor="previous_workplaces">
                          {" "}
                          جهة العمل السابقة{" "}
                        </label>
                        <ButtonGroup dir="ltr" className="item">
                          <small
                            onClick={() => {
                              sethowEnterWork(true);
                            }}
                            className="p-0 m-0 ps-3 pe-3 pt-1 pb-1 bg-primary text-white btn-custom"
                          >
                            <small>كيف ؟</small>
                          </small>
                          <small
                            className="badge bg-danger fs-sm rounded pt-1 pb-1 d-flex align-items-center"
                            dir="rtl"
                          >
                            انقر على 'Enter' او 'ادخال' بعد كتابة جهة العمل
                          </small>
                        </ButtonGroup>
                        <Modal
                          show={howEnterWork}
                          size="lg"
                          aria-labelledby="contained-modal-title-vcenter"
                          centered
                          scrollable={false}
                        >
                          <ModalHeader className="bg-light d-flex justify-content-between">
                            <ModalTitle>
                              <h5 className="m-0 p-0">كيف يعمل ؟</h5>
                            </ModalTitle>
                            <Button
                              variant="light"
                              className="text-primary border"
                              size={"sm"}
                              onClick={() => {
                                sethowEnterWork(false);
                              }}
                            >
                              فهمت
                            </Button>
                          </ModalHeader>
                          <ModalBody className="p-0">
                            <p className="fw-bold p-4">
                              في هذه الخطوات سنشرح كيفية ادخال المعلومات متعددة
                              الخيارات مثل "جهات العمل السابقة" و "المهارات"
                            </p>
                            <ol className="p-0">
                              <li className="p-0">
                                <div className="p-4">
                                  <b>
                                    الخطوة الاولى : نقوم بكتابة المدخلة مثال:
                                  </b>
                                  <img
                                    src={image3}
                                    alt="img1"
                                    className="img-fluid border mt-3"
                                  />
                                </div>
                              </li>

                              <li className="mt-4 p-0">
                                <hr />
                                <div className="p-4">
                                  <b>
                                    الخطوة الثانية : نقوم بالنقر على{" "}
                                    <small className="badge bg-primary fs-sm">
                                      Enter
                                    </small>{" "}
                                    او{" "}
                                    <small className="badge bg-primary fs-sm">
                                      ادخال
                                    </small>{" "}
                                    او{" "}
                                    <small className="badge bg-primary fs-sm">
                                      Return
                                    </small>{" "}
                                    في لوحة المفاتيح الخاصه بك
                                  </b>

                                  <Tabs
                                    defaultActiveKey="home"
                                    id="uncontrolled-tab-example"
                                    className="mb-3 mt-3"
                                  >
                                    <Tab
                                      eventKey="home"
                                      title={<b>كيبورد windows</b>}
                                    >
                                      <img
                                        src={image2}
                                        alt="img1"
                                        className="img-fluid border mt-3 p-1"
                                      />
                                    </Tab>
                                    <Tab
                                      eventKey="profile"
                                      title={<b>كيبورد Mac</b>}
                                    >
                                      <img
                                        src={image1}
                                        alt="img1"
                                        className="img-fluid border mt-3 p-1"
                                      />
                                    </Tab>
                                  </Tabs>
                                </div>
                              </li>

                              <li className="mt-4 p-0">
                                <hr />
                                <div className="p-4">
                                  <b>
                                    الخطوة الثالثة : ظهور واعتماد القيمة بعد
                                    اتمام الخطوات السابقة
                                  </b>
                                  <img
                                    src={image4}
                                    alt="img1"
                                    className="img-fluid border mt-3"
                                  />
                                </div>
                              </li>
                            </ol>
                          </ModalBody>
                          <ModalFooter className="bg-light"></ModalFooter>
                        </Modal>
                        <CreatableSelect
                          isMulti
                          value={
                            userData &&
                            userData.work &&
                            userData.work.previous_workplaces
                              ? (() => {
                                  try {
                                    return JSON.parse(
                                      userData.work.previous_workplaces
                                    );
                                  } catch (error) {
                                    console.error("Error parsing JSON:", error);
                                    return [{ value: "", label: "" }];
                                  }
                                })()
                              : [{ value: "", label: "" }]
                          }
                          placeholder="اكتب اسم جهات عمل سابقه"
                          isClearable={true}
                          onChange={(e) => {
                            setUserData((prevData) => ({
                              ...prevData,
                              work: {
                                ...prevData.work,
                                previous_workplaces: JSON.stringify(e),
                              },
                            }));

                            // Handle other state updates or actions as needed
                          }}
                          onCreateOption={(inputValue) => {
                            // Handle the creation of a new skill option
                            const newOption = {
                              value: inputValue,
                              label: inputValue,
                            };

                            setUserData((prevData) => ({
                              ...prevData,
                              work: {
                                ...prevData.work,
                                previous_workplaces: JSON.stringify([
                                  ...JSON.parse(
                                    prevData.work.previous_workplaces || "[]"
                                  ),
                                  newOption,
                                ]),
                              },
                            }));

                            // Handle other state updates or actions as needed
                          }}
                        />
                      </div>
                    )}

                    {occupation !== "student" &&
                      occupation !== "retired" &&
                      occupation !== "unemployed" && (
                        <div className="form-group mb-5 col-md-6 col-xl-3">
                          <label htmlFor="position"> المنصب </label>
                          <input
                            type="text"
                            className="form-control"
                            id="position"
                            placeholder=""
                            value={
                              userData &&
                              userData.work &&
                              userData.work.position
                            }
                            onChange={(e) =>
                              handleInputChange(e, "work", "position")
                            }
                          />
                        </div>
                      )}

                    {occupation == "student" && (
                      <div className="form-group mb-5 col-md-6 col-xl-3">
                        <label htmlFor="academic ">الجهة الدراسية </label>
                        <input
                          type="text"
                          className="form-control"
                          id="current_workplace"
                          placeholder=""
                          value={
                            userData && userData.work && userData.work.academic
                          }
                          onChange={(e) =>
                            handleInputChange(e, "work", "academic")
                          }
                        />
                      </div>
                    )}
                    {occupation == "student" && (
                      <div className="form-group mb-5 col-md-6 col-xl-3">
                        <label htmlFor="academic_level">
                          {" "}
                          المستوى الدراسي{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="academic_level"
                          placeholder=""
                          value={
                            userData &&
                            userData.work &&
                            userData.work.academic_level
                          }
                          onChange={(e) =>
                            handleInputChange(e, "work", "academic_level")
                          }
                        />
                      </div>
                    )}

                    <div className="form-group mb-5 col-md-6 col-xl-3">
                      <label htmlFor="years_of_experience">سنوات الخبرة </label>
                      <input
                        type="number"
                        className="form-control"
                        id="years_of_experience"
                        placeholder=""
                        value={
                          userData &&
                          userData.work &&
                          userData.work.years_of_experience
                        }
                        min={0}
                        onChange={(e) =>
                          handleInputChange(e, "work", "years_of_experience")
                        }
                      />
                    </div>
                    <div className="form-group mb-5 col-md-6">
                      <label htmlFor="years_of_experience"> المهارات </label>
                      <ButtonGroup dir="ltr" className="item">
                        <small
                          onClick={() => {
                            sethowEnterWork(true);
                          }}
                          className="p-0 m-0 ps-3 pe-3 pt-1 pb-1 bg-primary text-white btn-custom"
                        >
                          <small>كيف ؟</small>
                        </small>
                        <small
                          className="badge bg-danger fs-sm rounded pt-1 pb-1 d-flex align-items-center"
                          dir="rtl"
                        >
                          انقر على 'Enter' او 'ادخال' بعد كتابة جهة العمل
                        </small>
                      </ButtonGroup>
                      <Modal
                        show={howEnterWork}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        scrollable={false}
                      >
                        <ModalHeader className="bg-light d-flex justify-content-between">
                          <ModalTitle>
                            <h5 className="m-0 p-0">كيف يعمل ؟</h5>
                          </ModalTitle>
                          <Button
                            variant="light"
                            className="text-primary border"
                            size={"sm"}
                            onClick={() => {
                              sethowEnterWork(false);
                            }}
                          >
                            فهمت
                          </Button>
                        </ModalHeader>
                        <ModalBody className="p-0">
                          <p className="fw-bold p-4">
                            في هذه الخطوات سنشرح كيفية ادخال المعلومات متعددة
                            الخيارات مثل "جهات العمل السابقة" و "المهارات"
                          </p>
                          <ol className="p-0">
                            <li className="p-0">
                              <div className="p-4">
                                <b>الخطوة الاولى : نقوم بكتابة المدخلة مثال:</b>
                                <img
                                  src={image3}
                                  alt="img1"
                                  className="img-fluid border mt-3"
                                />
                              </div>
                            </li>

                            <li className="mt-4 p-0">
                              <hr />
                              <div className="p-4">
                                <b>
                                  الخطوة الثانية : نقوم بالنقر على{" "}
                                  <small className="badge bg-primary fs-sm">
                                    Enter
                                  </small>{" "}
                                  او{" "}
                                  <small className="badge bg-primary fs-sm">
                                    ادخال
                                  </small>{" "}
                                  او{" "}
                                  <small className="badge bg-primary fs-sm">
                                    Return
                                  </small>{" "}
                                  في لوحة المفاتيح الخاصه بك
                                </b>

                                <Tabs
                                  defaultActiveKey="home"
                                  id="uncontrolled-tab-example"
                                  className="mb-3 mt-3"
                                >
                                  <Tab
                                    eventKey="home"
                                    title={<b>كيبورد windows</b>}
                                  >
                                    <img
                                      src={image2}
                                      alt="img1"
                                      className="img-fluid border mt-3 p-1"
                                    />
                                  </Tab>
                                  <Tab
                                    eventKey="profile"
                                    title={<b>كيبورد Mac</b>}
                                  >
                                    <img
                                      src={image1}
                                      alt="img1"
                                      className="img-fluid border mt-3 p-1"
                                    />
                                  </Tab>
                                </Tabs>
                              </div>
                            </li>

                            <li className="mt-4 p-0">
                              <hr />
                              <div className="p-4">
                                <b>
                                  الخطوة الثالثة : ظهور واعتماد القيمة بعد اتمام
                                  الخطوات السابقة
                                </b>
                                <img
                                  src={image4}
                                  alt="img1"
                                  className="img-fluid border mt-3"
                                />
                              </div>
                            </li>
                          </ol>
                        </ModalBody>
                        <ModalFooter className="bg-light"></ModalFooter>
                      </Modal>
                      <CreatableSelect
                        isMulti
                        value={
                          userData && userData.work && userData.work.skills
                            ? (() => {
                                try {
                                  return JSON.parse(userData.work.skills);
                                } catch (error) {
                                  console.error("Error parsing JSON:", error);
                                  return [{ value: "", label: "" }];
                                }
                              })()
                            : [{ value: "", label: "" }]
                        }
                        placeholder="اكتب اسم مهارة"
                        isClearable={true}
                        onChange={(e) => {
                          setUserData((prevData) => ({
                            ...prevData,
                            work: {
                              ...prevData.work,
                              skills: JSON.stringify(e),
                            },
                          }));

                          // Handle other state updates or actions as needed
                        }}
                        onCreateOption={(inputValue) => {
                          // Handle the creation of a new skill option
                          const newOption = {
                            value: inputValue,
                            label: inputValue,
                          };

                          setUserData((prevData) => ({
                            ...prevData,
                            work: {
                              ...prevData.work,
                              skills: JSON.stringify([
                                ...JSON.parse(prevData.work.skills || "[]"),
                                newOption,
                              ]),
                            },
                          }));

                          // Handle other state updates or actions as needed
                        }}
                      />
                    </div>
                    <div className="form-group mb-5">
                      <label htmlFor="personal_summary">
                        {" "}
                        نبذة شخصية تقدم بها نفسك{" "}
                      </label>
                      <textarea
                        className="form-control"
                        id="personal_summary"
                        value={
                          userData &&
                          userData.work &&
                          userData.work.personal_summary
                        }
                        placeholder=""
                        onChange={(e) =>
                          handleInputChange(e, "work", "personal_summary")
                        }
                      ></textarea>
                      {fieldErrors?.personal_summary && (
                        <div className="text-danger">
                          {fieldErrors?.personal_summary}
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>

              {/* end user work information */}

              {/* user social information */}
              <div className="col-12 user_personal_information">
                <div className="user_information_hor mb-2"></div>

                {
                  <>
                    <div className=""></div>
                    <div className="d-flex justify-content-center mb-5">
                      <button
                        className="ms-2 user_info_btn_produce"
                        onClick={handleApplyApplication}
                      >
                        حفظ
                      </button>
                      <div className="ms">
                        <Link to="/admin/dashboard/candidates">
                          <button className="ms-2 btn btn-danger">
                            الغاء الطلب
                          </button>
                        </Link>
                      </div>
                    </div>
                  </>
                }
              </div>
              {/* end user social information */}
            </div>
          </TabPanel>
          <TabPanel value="2">
            <div className="container pt-4">
              <div className="row">
                <div className="col-12 user_personal_information">
                  <div className="w-100 d-flex align-items-center mt-3">
                    <div className="mx-2">
                      <div className="user_info_column">1</div>
                    </div>
                    <div className="user_step_main_info">
                      <div className="user_info_meaning">معلوماتي التجارية</div>
                      <div className="user_information_confirm">
                        جميع الحقول التي تحتوي على (*) تعتبر الزامية
                      </div>
                    </div>
                  </div>
                  <div className="user_information_hor mb-2"></div>
                  <form>
                    <div className="row user_information_personal_form mt-4 pb-4">
                      <div className="form-group mb-5 col-md-6 col-xl-4">
                        <label htmlFor="type_work_documnet">
                          نوع وثيقة العمل
                        </label>
                        <select
                          className="form-control"
                          id="type_work_documnet"
                          value={
                            userDataCommerce &&
                            userDataCommerce.commerce &&
                            userDataCommerce.commerce.type_work_documnet
                          }
                          onChange={(e) =>
                            handleInputCommerceChange(
                              e,
                              "commerce",
                              "type_work_documnet"
                            )
                          }
                        >
                          <option value="">حدد</option>
                          <option value="commerce">سجل تجاري</option>
                          <option value="freelancer">وثيقة عمل حر</option>
                          <option value="production_family">
                            رخصة الاسر المنتجة
                          </option>
                          <option value="information">معروف</option>
                          <option value="not_found">لا يوجد</option>
                        </select>
                        {errorsback?.type_work_documnet && (
                          <div className="text-danger">
                            {errorsback?.type_work_documnet[0]}
                          </div>
                        )}
                      </div>
                      {userDataCommerce &&
                        userDataCommerce.commerce &&
                        userDataCommerce.commerce.type_work_documnet !==
                          "not_found" && (
                          <>
                            <div className="form-group mb-5 col-md-6 col-xl-4">
                              <label htmlFor="number_documnet">
                                {" "}
                                رقم الوثيقة{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="number_documnet"
                                placeholder=""
                                value={
                                  userDataCommerce &&
                                  userDataCommerce.commerce &&
                                  userDataCommerce.commerce.number_documnet
                                }
                                onChange={(e) =>
                                  handleInputCommerceChange(
                                    e,
                                    "commerce",
                                    "number_documnet"
                                  )
                                }
                              />
                              {errorsback?.number_documnet && (
                                <div className="text-danger">
                                  {errorsback?.number_documnet[0]}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-5 col-md-6 col-xl-4">
                              <label htmlFor="end_documnet">
                                {" "}
                                تايخ انتهاء الوثيقة{" "}
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                id="end_documnet"
                                placeholder=""
                                value={
                                  userDataCommerce &&
                                  userDataCommerce.commerce &&
                                  userDataCommerce.commerce.end_documnet
                                }
                                onChange={(e) =>
                                  handleInputCommerceChange(
                                    e,
                                    "commerce",
                                    "end_documnet"
                                  )
                                }
                              />
                              {errorsback?.end_documnet && (
                                <div className="text-danger">
                                  {errorsback?.end_documnet[0]}
                                </div>
                              )}
                            </div>
                            <div className="form-group mb-5 col-md-6 col-xl-4">
                              <label htmlFor="name_activity">
                                {" "}
                                اسم النشاط{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="name_activity"
                                placeholder=""
                                value={
                                  userDataCommerce &&
                                  userDataCommerce.commerce &&
                                  userDataCommerce.commerce.name_activity
                                }
                                onChange={(e) =>
                                  handleInputCommerceChange(
                                    e,
                                    "commerce",
                                    "name_activity"
                                  )
                                }
                              />
                              {errorsback?.name_activity && (
                                <div className="text-danger">
                                  {errorsback?.name_activity[0]}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-5 col-md-6 col-xl-4">
                              <label htmlFor="start_documnet">
                                {" "}
                                تايخ بدءالنشاط{" "}
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                id="start_documnet"
                                placeholder=""
                                value={
                                  userDataCommerce &&
                                  userDataCommerce.commerce &&
                                  userDataCommerce.commerce.start_documnet
                                }
                                onChange={(e) =>
                                  handleInputCommerceChange(
                                    e,
                                    "commerce",
                                    "start_documnet"
                                  )
                                }
                              />
                              {errorsback?.start_documnet && (
                                <div className="text-danger">
                                  {errorsback?.start_documnet[0]}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-5 col-md-6 col-xl-4">
                              <label htmlFor="duration_activity">
                                {" "}
                                المدة منذ بدء النشاط{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="duration_activity"
                                placeholder=""
                                value={
                                  ageActivity !== "" ? `${ageActivity} سنة` : ""
                                }
                                disabled
                              />
                            </div>
                          </>
                        )}
                      <div className="form-group mb-5 col-md-6 col-xl-4">
                        <label htmlFor=""></label>
                        <div className="user_checkbox">
                          <input
                            className="form-check-input mx-1"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                            checked={isCertified}
                            onChange={() => setIsCertified(!isCertified)}
                          />
                          <label htmlFor="flexCheckDefault">
                            هل تملك رقم توثيق من المركز السعودي للأعمال
                          </label>
                        </div>
                      </div>

                      {isCertified && (
                        <div className="form-group mb-5 col-md-6 col-xl-4">
                          <label htmlFor="number_documntation">
                            رقم التوثيق
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="number_documntation"
                            placeholder="رقم التوثيق"
                            value={
                              userDataCommerce.commerce.number_documntation
                            }
                            onChange={(e) =>
                              handleInputCommerceChange(
                                e,
                                "commerce",
                                "number_documntation"
                              )
                            }
                          />
                          {errorsback?.number_documntation && (
                            <div className="text-danger">
                              {errorsback?.number_documntation[0]}
                            </div>
                          )}
                        </div>
                      )}

                      <div className="form-group mb-2">
                        <label htmlFor="website_sale">
                          ما هي المنصة المستخدمة للبيع
                        </label>
                        <div className="col-12 user_personal_information">
                          <div className="user_information_hor mb-2"></div>
                          <div className="row user_information_personal_form mt-4 pb-4">
                            <div className="table-responsive">
                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th>الرقم</th>
                                    <th>اسم المنصه</th>
                                    <th>رابط المنصه</th>
                                    <th>اضافة / حذف</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td></td>
                                    <td>
                                      <select
                                        className="form-control"
                                        id="website_sale"
                                        value={keyWebsite}
                                        onChange={(e) =>
                                          setKeyWebsite(
                                            e.target.options[
                                              e.target.selectedIndex
                                            ].value
                                          )
                                        }
                                      >
                                        <option value="" disabled>
                                          حدد منصة
                                        </option>
                                        <option value="special_commerce">
                                          متجر الكتروني خاص
                                        </option>
                                        <option value="instegram">
                                          حساب انستغرام
                                        </option>
                                        <option value="twitter">
                                          حساب تويتر
                                        </option>
                                        <option value="other">اخرى</option>
                                        <option value="not_found">
                                          لا يوجد
                                        </option>
                                      </select>
                                    </td>
                                    <td>
                                      <div className="form-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="ادخل الرابط"
                                          value={url_website_sale}
                                          onChange={(e) =>
                                            setUrl_websit_sale(e.target.value)
                                          }
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <button
                                        className="btn btn-success"
                                        onClick={(e) =>
                                          handleWebsiteSalesChange(e)
                                        }
                                        disabled={!keyWebsite.trim()}
                                      >
                                        <i class="fa-solid fa-plus"></i>
                                      </button>
                                    </td>
                                  </tr>
                                  {Array.isArray(wetsite) &&
                                    wetsite.map((webSite) => (
                                      <tr key={webSite.id}>
                                        <td>{webSite.id}</td>
                                        <td>
                                          <select
                                            className="form-control"
                                            id="website_sale"
                                            value={webSite.key}
                                            onChange={(e) =>
                                              handleWebsiteNameChange(
                                                e,
                                                webSite.id
                                              )
                                            }
                                          >
                                            <option value="" disabled>
                                              حدد منصة
                                            </option>
                                            <option value="special_commerce">
                                              متجر الكتروني خاص
                                            </option>
                                            <option value="instegram">
                                              حساب انستغرام
                                            </option>
                                            <option value="twitter">
                                              حساب تويتر
                                            </option>
                                            <option value="other">اخرى</option>
                                            <option value="not_found">
                                              لا يوجد
                                            </option>
                                          </select>
                                        </td>
                                        <td>
                                          <div className="form-group">
                                            <input
                                              type="text"
                                              className="form-control"
                                              value={webSite.description}
                                              onChange={(e) =>
                                                handleDescriptionWebsiteChange(
                                                  webSite.id,
                                                  e.target.value
                                                )
                                              }
                                              placeholder="ادخل وصف"
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <button
                                            className="btn btn-danger"
                                            onClick={() =>
                                              handleDeleteWebsite(
                                                webSite.id,
                                                webSite.path
                                              )
                                            }
                                          >
                                            <i class="fa-solid fa-trash-can"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                              {errorsback?.website_sale && (
                                <div className="text-danger">
                                  {errorsback?.website_sale[0]}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="mb-5">
                            {/* Existing buttons and checkboxes */}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 mb-5">
                        <div className="form-group mb-2">
                          <label htmlFor=""></label>
                          <div className="user_checkbox">
                            <input
                              className="form-check-input mx-1"
                              type="checkbox"
                              value=""
                              id="ishasorgnization_id"
                              checked={ishasorgnization}
                              onChange={() =>
                                setishasorgnization(!ishasorgnization)
                              }
                            />
                            <label htmlFor="ishasorgnization_id">
                              {" "}
                              هل انت منتسب لجمعية خيرية او مؤسسة خيرية ؟
                            </label>
                          </div>
                        </div>

                        {ishasorgnization && (
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="charity"
                              placeholder="اذكرها "
                              value={
                                userDataCommerce &&
                                userDataCommerce.commerce &&
                                userDataCommerce.commerce.charity
                              }
                              onChange={(e) =>
                                handleInputCommerceChange(
                                  e,
                                  "commerce",
                                  "charity"
                                )
                              }
                            />
                            {errorsback?.charity && (
                              <div className="text-danger">
                                {errorsback?.charity[0]}
                              </div>
                            )}
                          </div>
                        )}
                      </div>

                      <div className="col-md-6 mb-5">
                        <div className="form-group mb-2">
                          <label htmlFor=""></label>
                          <div className="user_checkbox">
                            <input
                              className="form-check-input mx-1"
                              type="checkbox"
                              value=""
                              id="hasQualifications_id"
                              checked={hasQualifications}
                              onChange={() =>
                                sethasQualifications(!hasQualifications)
                              }
                            />
                            <label htmlFor="hasQualifications_id">
                              {" "}
                              هل تملك مؤهلات علمية او دورات تدريبية مرتبطة
                              بالنشاط؟
                            </label>
                          </div>
                        </div>

                        {hasQualifications && (
                          <div className="form-group">
                            <ButtonGroup dir="ltr" className="item">
                              <small
                                className="badge bg-danger fs-sm rounded pt-1 pb-1 d-flex align-items-center"
                                dir="rtl"
                              >
                                انقر على 'Enter' او 'ادخال' بعد كتابة جهة العمل
                              </small>
                            </ButtonGroup>

                            <CreatableSelect
                              isMulti
                              value={
                                userDataCommerce &&
                                userDataCommerce.commerce &&
                                userDataCommerce.commerce.courses_qualification
                                  ? (() => {
                                      try {
                                        return JSON.parse(
                                          userDataCommerce.commerce
                                            .courses_qualification
                                        );
                                      } catch (error) {
                                        console.error(
                                          "Error parsing JSON:",
                                          error
                                        );
                                        return [{ value: "", label: "" }];
                                      }
                                    })()
                                  : [{ value: "", label: "" }]
                              }
                              placeholder="اكتب اسم جهات عمل سابقه"
                              isClearable={true}
                              onChange={(e) => {
                                setUserDataCommerce((prevData) => ({
                                  ...prevData,
                                  commerce: {
                                    ...prevData.commerce,
                                    courses_qualification: JSON.stringify(e),
                                  },
                                }));

                                // Handle other state updates or actions as needed
                              }}
                              onCreateOption={(inputValue) => {
                                // Handle the creation of a new skill option
                                const newOption = {
                                  value: inputValue,
                                  label: inputValue,
                                };

                                setUserDataCommerce((prevData) => ({
                                  ...prevData,
                                  commerce: {
                                    ...prevData.commerce,
                                    courses_qualification: JSON.stringify([
                                      ...JSON.parse(
                                        prevData.commerce
                                          .courses_qualification || "[]"
                                      ),
                                      newOption,
                                    ]),
                                  },
                                }));

                                // Handle other state updates or actions as needed
                              }}
                            />
                          </div>
                        )}
                      </div>

                      <div className="form-group mb-5">
                        <label htmlFor="notes">
                          {" "}
                          نبذة تفصيلية عن الفكرة او النشاط الخاص بك{" "}
                        </label>
                        <div className="user_textarea_content">
                          (إن التعريف بمشروعك او نشاطك حسب ماتكتب يعتبر أهم
                          مرحلة في إستكمال البيانات والتي من خلالها سيتم تقييم
                          نشاطك، لذا نرجو الكتابة بعناية وتفصيل ودقة، على ان
                          يشتمل التعريف على التالي: فكرة المشروع - الفئة
                          المستهدفة - الصعوبات ان وجدت - الهدف المستقبلي - اي
                          معلومات او تفاصيل تجد أنها قد تفيد في فهم نشاطك او
                          فكرتك){" "}
                        </div>
                        <textarea
                          className="form-control"
                          id="summary"
                          placeholder=""
                          rows={10}
                          value={
                            userDataCommerce &&
                            userDataCommerce.commerce &&
                            userDataCommerce.commerce.summary
                          }
                          onChange={(e) =>
                            handleInputCommerceChange(e, "commerce", "summary")
                          }
                        ></textarea>
                        {errorsback?.summary && (
                          <div className="text-danger">
                            {errorsback?.summary[0]}
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
                {/* end user personal enformation */}

                {/* user social information */}

                <div className="col-12 user_personal_information">
                  <div className="w-100 d-flex align-items-center mt-3">
                    <div className="mx-2">
                      <div className="user_info_column">2</div>
                    </div>
                    <div className="user_step_main_info">
                      <div className="user_info_meaning">
                        {" "}
                        حسابات التواصل الاجتماعي الخاصة بالنشاط التجاري
                      </div>
                    </div>
                  </div>
                  <div className="user_information_hor mb-2"></div>
                  <form>
                    <div className="row user_information_personal_form mt-4 pb-4">
                      <div className="form-group mb-3 col-md-6 col-xl-4">
                        <label htmlFor="twitter"> تويتر </label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <button
                              className="btn user_btn_right"
                              type="button"
                            >
                              <i className="fab fa-twitter"></i>
                            </button>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            id="twitter"
                            placeholder="@اسم المستخدم"
                            value={
                              userDataCommerce &&
                              userDataCommerce.social_commerce &&
                              userDataCommerce.social_commerce.twitter
                            }
                            onChange={(e) =>
                              handleInputCommerceChange(
                                e,
                                "social_commerce",
                                "twitter"
                              )
                            }
                            pattern="https?://.*"
                            title="يرجى إدخال رابط صحيح مبتدئًا بـ http أو https."
                          />
                        </div>
                      </div>
                      {/* Instagram Input */}
                      <div className="form-group mb-3 col-md-6 col-xl-4">
                        <label htmlFor="instagram">انستغرام</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <button
                              className="btn user_btn_right"
                              type="button"
                            >
                              <i class="fa-brands fa-instagram"></i>
                            </button>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            id="instagram"
                            placeholder="@اسم المستخدم"
                            value={
                              userDataCommerce?.social_commerce?.instagram || ""
                            }
                            onChange={(e) =>
                              handleInputCommerceChange(
                                e,
                                "social_commerce",
                                "instagram"
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3 col-md-6 col-xl-4">
                        <label htmlFor="youtube"> يوتيوب </label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <button
                              className="btn user_btn_right"
                              type="button"
                            >
                              <i class="fa-brands fa-youtube"></i>
                            </button>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            id="youtube"
                            placeholder="الرابط "
                            value={
                              userDataCommerce &&
                              userDataCommerce.social_commerce &&
                              userDataCommerce.social_commerce.youtube
                            }
                            onChange={(e) =>
                              handleInputCommerceChange(
                                e,
                                "social_commerce",
                                "youtube"
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3 col-md-6 col-xl-4">
                        <label htmlFor="tiktok"> تيك توك </label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <button
                              className="btn user_btn_right"
                              type="button"
                            >
                              <i class="fa-brands fa-tiktok"></i>
                            </button>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            id="tiktok"
                            placeholder="@اسم المستخدم"
                            value={
                              userDataCommerce &&
                              userDataCommerce.social_commerce &&
                              userDataCommerce.social_commerce.tiktok
                            }
                            onChange={(e) =>
                              handleInputCommerceChange(
                                e,
                                "social_commerce",
                                "tiktok"
                              )
                            }
                          />
                        </div>
                      </div>

                      <div className="form-group mb-3 col-md-6 col-xl-4">
                        <label htmlFor="whatsapp"> واتس اب </label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <button
                              className="btn user_btn_right"
                              type="button"
                            >
                              <i class="fa-brands fa-whatsapp"></i>
                            </button>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            id="whatsapp"
                            placeholder="+9660000000"
                            value={
                              userDataCommerce &&
                              userDataCommerce.social_commerce &&
                              userDataCommerce.social_commerce.whatsapp
                            }
                            onChange={(e) =>
                              handleInputCommerceChange(
                                e,
                                "social_commerce",
                                "whatsapp"
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3 col-md-6 col-xl-4">
                        <label htmlFor="linkendin"> لينكد ان </label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <button
                              className="btn user_btn_right"
                              type="button"
                            >
                              <i class="fa-brands fa-facebook-f"></i>
                            </button>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            id="linkendin"
                            placeholder="@اسم المستخدم"
                            value={
                              userDataCommerce &&
                              userDataCommerce.social_commerce &&
                              userDataCommerce.social_commerce.linkendin
                            }
                            onChange={(e) =>
                              handleInputCommerceChange(
                                e,
                                "social_commerce",
                                "linkendin"
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3 col-md-6 col-xl-4">
                        <label htmlFor="snapchat"> سناب شات </label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <button
                              className="btn user_btn_right"
                              type="button"
                            >
                              <i class="fa-brands fa-snapchat"></i>
                            </button>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            id="snapchat"
                            placeholder="@اسم المستخدم"
                            value={
                              userDataCommerce &&
                              userDataCommerce.social_commerce &&
                              userDataCommerce.social_commerce.snapchat
                            }
                            onChange={(e) =>
                              handleInputCommerceChange(
                                e,
                                "social_commerce",
                                "snapchat"
                              )
                            }
                          />
                        </div>
                      </div>

                      <div className="form-group mb-3 col-md-6 col-xl-4">
                        <label htmlFor="telegram"> تليجرام </label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <button
                              className="btn user_btn_right"
                              type="button"
                            >
                              <i class="fa-brands fa-telegram"></i>
                            </button>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            id="telegram"
                            placeholder="@اسم المستخدم"
                            value={
                              userDataCommerce &&
                              userDataCommerce.social_commerce &&
                              userDataCommerce.social_commerce.telegram
                            }
                            onChange={(e) =>
                              handleInputCommerceChange(
                                e,
                                "social_commerce",
                                "telegram"
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3 col-md-6 col-xl-4">
                        <label htmlFor="website"> الموقع الالكتروني </label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <button
                              className="btn user_btn_right"
                              type="button"
                            >
                              <i class="fa-solid fa-globe"></i>
                            </button>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            id="website"
                            placeholder="الرابط"
                            value={
                              userDataCommerce &&
                              userDataCommerce.social_commerce &&
                              userDataCommerce.social_commerce.website
                            }
                            onChange={(e) =>
                              handleInputCommerceChange(
                                e,
                                "social_commerce",
                                "website"
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3 col-md-6 col-xl-4">
                        <label htmlFor="others"> اخرى </label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <button
                              className="btn user_btn_right"
                              type="button"
                            >
                              <i class="fa-solid fa-person-breastfeeding"></i>
                            </button>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            id="others"
                            placeholder="@اسم المستخدم"
                            value={
                              userData &&
                              userData.social_commerce &&
                              userData.social_commerce.others
                            }
                            onChange={(e) =>
                              handleInputCommerceChange(
                                e,
                                "social_commerce",
                                "others"
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

                {/* end user social information */}

                {/* user Files information */}
                <div className="col-12 user_personal_information">
                  <div className="w-100 d-flex align-items-center mt-3">
                    <div className="mx-2">
                      <div className="user_info_column">3</div>
                    </div>
                    <div className="user_step_main_info">
                      <div className="user_info_meaning"> مستندات ووثائق </div>
                    </div>
                  </div>
                  <div className="user_information_hor mb-2"></div>
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="row user_information_personal_form mt-4 pb-4">
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>الرقم </th>
                              <th>اسم الملف</th>
                              <th>وصف الملف</th>
                              <th>اضافة / حذف </th>
                            </tr>
                          </thead>
                          <tbody>
                            {Array.isArray(files) &&
                              files.map((file) => (
                                <tr key={file.id}>
                                  <td>{file.id}</td>
                                  <td>
                                    <a
                                      className="user_work_file"
                                      href={
                                        "https://pub-834d7d165e3e40ecb0b474482259582d.r2.dev/" +
                                        file.path
                                      }
                                      target="_blank"
                                    >
                                      {file.name}
                                    </a>
                                  </td>
                                  <td>
                                    <div className="form-group mt-2">
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={file.description}
                                        onChange={(e) =>
                                          handleDescriptionChange(
                                            file.id,
                                            e.target.value
                                          )
                                        }
                                        placeholder="ادخل وصف"
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    {loading ? (
                                      <Box sx={{ display: "flex" }}>
                                        <CircularProgress />
                                      </Box>
                                    ) : (
                                      <>
                                        <button
                                          className="btn btn-danger mx-2"
                                          onClick={() =>
                                            handleDelete(file.id, file.path)
                                          }
                                        >
                                          <i class="fa-solid fa-trash-can"></i>
                                        </button>

                                        <button
                                          className="btn btn-success mx-2"
                                          onClick={handleAddFile}
                                        >
                                          {" "}
                                          <i class="fa-solid fa-plus"></i>
                                        </button>
                                      </>
                                    )}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="form-group mt-2">
                        <input
                          type="file"
                          name="file"
                          className="form-control"
                          id="files"
                          placeholder="@اسم المستخدم"
                          onChange={handleFileSelection}
                        />
                        <label htmlFor="files">
                          ملفات مسموح بها : .jpg,.png,.pdf,.ppt,.docx,.docs
                        </label>
                        <div className="user_add_file_alert">
                          بعد أن تختار الملف اضغط علي + كي تقوم بحفظ الملفات ومن
                          ثم الحفظ كمسودة
                        </div>
                      </div>
                    </div>
                  </form>

                  {errorsback?.files && (
                    <div className="text-danger">{errorsback?.files[0]}</div>
                  )}
                </div>
                {/* end user Files information */}
                {/* user videos information */}
                <div className="col-12 user_personal_information">
                  <div className="w-100 d-flex align-items-center mt-3">
                    <div className="mx-2">
                      <div className="user_info_column">4</div>
                    </div>
                    <div className="user_step_main_info">
                      <div className="user_info_meaning"> روابط الفيديو</div>
                    </div>
                  </div>
                  <div className="user_information_hor mb-2"></div>
                  <div className="row user_information_personal_form mt-4 pb-4">
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>الرقم </th>
                            <th>اسم الفيديو</th>
                            <th>وصف الفيديو</th>
                            <th>اضافة / حذف</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td></td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="ادخل رابط الفيديو"
                                value={videoName}
                                onChange={(e) => setVideoName(e.target.value)}
                              />
                            </td>
                            <td>
                              <div className="form-group ">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="ادخل وصف"
                                  value={videoDescription}
                                  onChange={(e) =>
                                    setVideoDescription(e.target.value)
                                  }
                                />
                              </div>
                            </td>
                            <td>
                              <button
                                className="btn btn-success"
                                onClick={() => handleVideoChange()}
                                disabled={!videoName.trim()}
                              >
                                <i class="fa-solid fa-plus"></i>
                              </button>
                            </td>
                          </tr>
                          {Array.isArray(videos) &&
                            videos.map((video) => (
                              <tr key={video.id}>
                                <td>{video.id}</td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={video.name}
                                    onChange={(e) =>
                                      handleNameChange(video.id, e.target.value)
                                    }
                                    placeholder="ادخل رابط الفيديو"
                                  />
                                </td>
                                <td>
                                  <div className="form-group ">
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={video.description}
                                      onChange={(e) =>
                                        handleDescriptionVideoChange(
                                          video.id,
                                          e.target.value
                                        )
                                      }
                                      placeholder="ادخل وصف"
                                    />
                                  </div>
                                </td>
                                <td>
                                  <button
                                    className="btn btn-danger"
                                    onClick={() =>
                                      handleDeleteVideo(video.id, video.path)
                                    }
                                  >
                                    <i class="fa-solid fa-trash-can"></i>
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="mb-5">
                    {
                      <>
                        <div className="mt-3 mb-3 d-flex justify-content-center align-items-center p-2 user_alert_save">
                          إذا كنت ترغب في إجراء أي إضافات أو تعديلات، أو إذا
                          كانت معلوماتك غير مكتملة، يُرجى النقر على "حفظ
                          كمسودة". يتعين عليك فعل ذلك لأنك لن تكون قادرًا على
                          التعديل أو الإضافة بعد الضغط على أيقونة تقديم الطلب.
                        </div>
                        <div className="">
                          <div className="user_checkbox user_check_confirm user_check_update">
                            <input
                              className="form-check-input mx-1"
                              type="checkbox"
                              id="checkConfirm"
                              checked={termsCheckedCommerce}
                              onChange={handleCheckboxCommerceChange}
                            />
                            <label htmlFor="checkConfirm">
                              أقر بأنني قرأت وفهمت ووافقت على شروط الترشح، و
                              <a
                                href="https://pap.sa/rules"
                                target="_blank"
                                className="user_confidintional_link"
                              >
                                الشروط و الأحكام
                              </a>
                              <span className="mx-1">و</span>
                              <a
                                href="https://pap.sa/privacy"
                                target="_blank"
                                className="user_confidintional_link"
                              >
                                اتفاقية الخصوصية
                              </a>
                            </label>
                          </div>
                        </div>
                        <div className="d-flex justify-content-center mt-3">
                          <div className="ms-2">
                            <Link to="/admin/dashboard/candidates">
                              <button className="ms-2 btn btn-danger">
                                الغاء الطلب{" "}
                              </button>
                            </Link>
                          </div>
                          <button
                            className="ms-2 user_info_btn_produce"
                            onClick={handleApplyApplicationCommerce}
                          >
                            تقديم الطلب
                          </button>
                        </div>
                      </>
                    }
                  </div>
                  {errorsback?.videos && (
                    <div className="text-danger">{errorsback?.videos[0]}</div>
                  )}
                </div>
                {/* end user videos information */}
              </div>
            </div>
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
};

export default EditCandidatePage;
