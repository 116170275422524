import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { logout } from "../../auth/Login";

const NewStandards = () => {
  const navigate = useNavigate();
  const [Branches, setBranches] = useState([]);
  const [name, setName] = useState("");
  const [branchId, setBranchId] = useState("");
  const [general, setGeneral] = useState(false);
  const [socialMedia, setSocialMedia] = useState(false);
  const [score, setScore] = useState(0);
  const [type, setType] = useState("initial_assessment");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const token = localStorage.getItem("token");
    const user_type = localStorage.getItem("user_type");
    if (!token) {
      logout(navigate);
    }

    if (user_type === "referee" || user_type === "client") {
      navigate(-1);
    }
    fetchBranches();
  }, [navigate]);

  const fetchBranches = async () => {
    try {
      const response = await axios.get("https://te.prize.pap.sa/api/branches", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      setBranches(response.data.branches);
    } catch (err) {
      console.error(err);
      setErrors((prev) => ({
        ...prev,
        fetchBranches: "Failed to load branches",
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors({});

    try {
      await axios.post(
        "https://te.prize.pap.sa/api/criteria",
        {
          name,
          branch_id: general || socialMedia ? null : branchId,
          general,
          social_media: socialMedia,
          score: parseInt(score, 10),
          type,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      navigate("/admin/dashboard/standards");
    } catch (err) {
      if (err.response && err.response.data.errors) {
        setErrors(err.response.data.errors);
      }
    }
  };

  const handleBranchChange = (e) => {
    const selectedBranchId = e.target.value;
    setBranchId(selectedBranchId);
    setGeneral(selectedBranchId === "general");
    setSocialMedia(selectedBranchId === "social_media");
  };

  const inputStyle = {
    padding: "10px",
    marginBottom: "10px",
    width: "100%",
  };

  const labelStyle = {
    fontWeight: "bold",
    padding: "10px 20px",
  };

  const buttonStyle = {
    padding: "10px 20px",
    backgroundColor: "#4CAF50",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  };
  const radioGroupStyle = {
    display: "flex",
    flexDirection: "column",
    margin: "10px 0",
  };

  const radioStyle = {
    margin: "5px 10px",
    cursor: "pointer",
  };

  const radioLabelStyle = {
    fontWeight: "normal",
    marginLeft: "5px",
  };

  const customRadioStyle = `
        .custom-radio {
            opacity: 0;
            position: fixed;
            width: 0;
        }
    
        .custom-radio + label {
            position: relative;
            padding-left: 25px;
            cursor: pointer;
        }
    
        .custom-radio + label:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 18px;
            height: 18px;
            border: 1px solid #444;
            border-radius: 50%;
            background-color: white;
        }
    
        .custom-radio:checked + label:after {
            content: '';
            position: absolute;
            left: 5px;
            top: 5px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #4CAF50;
        }
    
        .custom-radio:focus + label:before {
            box-shadow: 0 0 3px 2px rgba(0, 100, 0, 0.3);
        }
    
        .custom-radio:hover + label:before {
            border-color: #4CAF50;
        }
    `;
  return (
    <div className="container-fluid mt-2">
      <div className="row admin_page_header">
        <div className="admin_page_title">اضافة معاير جديد</div>
        <div className="admin_page_subtitle mt-2">
          المعايير / اضافة معيار جديد
        </div>
      </div>
      <div className="admin_page_content col-12 row mb-4">
        <form onSubmit={handleSubmit}>
          <div>
            <label style={labelStyle}>الاسم:</label>
            <i
              className="fa-solid fa-star user_star"
              style={{ color: "#fd1212" }}
            ></i>
            <input
              className="form-control"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              style={inputStyle}
            />
            {errors.name && <div style={{ color: "red" }}>{errors.name}</div>}
          </div>
          <div>
            <label style={labelStyle}>رقم الفرع:</label>
            <i
              className="fa-solid fa-star user_star"
              style={{ color: "#fd1212" }}
            ></i>
            <select
              className="form-control"
              value={branchId}
              onChange={handleBranchChange}
              style={inputStyle}
            >
              <option value="">اختيار الفرع</option>
              <option value="general">الكل</option>
              <option value="social_media">سوشيال ميديا</option>
              {Branches.map((branch) => (
                <option key={branch.id} value={branch.id}>
                  {branch.name}
                </option>
              ))}
            </select>
            {errors.branch_id && (
              <div style={{ color: "red" }}>{errors.branch_id}</div>
            )}
          </div>
          <div>
            <label style={labelStyle}>الدرجة:</label>
            <i
              className="fa-solid fa-star user_star"
              style={{ color: "#fd1212" }}
            ></i>
            <input
              className="form-control"
              type="number"
              value={score}
              onChange={(e) => setScore(e.target.value)}
              style={inputStyle}
            />
            {errors.score && <div style={{ color: "red" }}>{errors.score}</div>}
          </div>
          <style>{customRadioStyle}</style>
          <div style={radioGroupStyle}>
            <label style={labelStyle}>نوع:</label>

            <div style={{ marginBottom: "20px" }}>
              <input
                className="form-control custom-radio"
                type="radio"
                id="final_assessment"
                value="final_assessment"
                checked={type === "final_assessment"}
                onChange={() => setType("final_assessment")}
                style={radioStyle}
              />
              <label htmlFor="final_assessment" style={radioLabelStyle}>
                المرحلة النهائية
              </label>
            </div>
            <div style={{ marginTop: "20px" }}>
              <input
                className="custom-radio form-control"
                type="radio"
                id="initial_assessment"
                value="initial_assessment"
                checked={type === "initial_assessment"}
                onChange={() => setType("initial_assessment")}
                style={radioStyle}
              />
              <label htmlFor="initial_assessment">المرحلة الاولية</label>
            </div>
          </div>

          <button type="submit" className="user_info_btn_produce mt-3 p-2">
            حفظ
          </button>
        </form>
        {errors.general && <div style={{ color: "red" }}>{errors.general}</div>}
        {errors.fetchBranches && (
          <div style={{ color: "red" }}>{errors.fetchBranches}</div>
        )}
      </div>
    </div>
  );
};

export default NewStandards;
