import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { logout } from "../../auth/Login";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
import "./report.css";

const Reports = () => {
  const navigate = useNavigate();
  const [Branches, setBranches] = useState([]);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [statistics, setStatistics] = useState({ data: [] });

  const [referee_count, setRefereeCount] = useState(0);
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [userStep, setUserStep] = useState("");

  useEffect(() => {
    checkAuth();
    fetchStatistics();
  }, [navigate, currentPage, perPage, searchQuery, userStep]);

  const checkAuth = () => {
    const token = localStorage.getItem("token");
    const user_type = localStorage.getItem("user_type");
    if (!token) {
      logout(navigate);
    } else if (["referee", "client"].includes(user_type)) {
      navigate(-1);
    }
  };

  const fetchStatistics = async () => {
    const params = {
      search: searchQuery,
      userStep: userStep,
      page: currentPage, 
      perPage: perPage, 
    };
  
    try {
      const response = await axios.get(
        `https://te.prize.pap.sa/api/clients-statistics`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          params,
        }
      );
      setStatistics(response.data.final_clients);
    } catch (err) {
      console.error("Error fetching statistics: ", err);
    }
  };
  
  const handleSearch = (e) => {
    e.preventDefault();
    fetchStatistics();
  };

  const handleChange = (e) => {
    setUserStep(e.target.value); // Update userStep state based on selection
  };

  const handleShowDetails = (client) => {
    setSelectedClient(client);
    setShowModal(true);
  };

  const modalHeaderStyle = {
    padding: "1rem",
    backgroundColor: "#007bff",
    color: "#fff",
    fontSize: "1.25rem",
  };

  const modalBodyStyle = {
    padding: "1rem",
    fontSize: "1rem",
  };

  const modalFooterStyle = {
    padding: "1rem",
    backgroundColor: "#f8f9fa",
  };

  const refereeTypesArabic = {
    sort_references: "فرز ",
    grade_references: "تقييم درجات مرحلة اولي",
    social_media_references: "تقييم سوشيال ميديا مرحله اولي",
    final_grade_referee: "محكم درجات نهائي",
    final_social_media_referee: "محكم سوشيال ميديا نهائي",
  };

  return (
    <div className="container-fluid mt-2">
      <div className="row admin_page_header">
        <div className="admin_page_title">التقارير </div>
        <div className="admin_page_subtitle mt-2">
          التقارير / استعراض التقارير
        </div>
      </div>

      <div className="row">
        <div className="admin_page_content col-12 row">
          <div className="col-12 row d-flex justify-content-between">
            <div className="col-md-4">
              <form onSubmit={handleSearch} className="mb-4">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control admin_candi_search_input"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="ابحث عن المرشحيين...."
                  />
                </div>
              </form>
            </div>

            <div className="col-md-4">
              <select
                className="form-field form-control"
                name="referee_type"
                value={userStep}
                onChange={handleChange}
                required
              >
                <option value="">اختر المرحلة </option>
                <option value="1">حفظ كمسوده</option>
                <option value="2"> تقديم الطلب</option>
                <option value="3"> مرحله التقيم</option>
                <option value="4">مرحله الترحيل </option>
                <option value="5"> مرحلة تقييم النهائية </option>
              </select>
            </div>
          </div>

          <table className="table admin_table">
            <thead className="admin_table_thead">
              <tr>
                <th>#</th>
                <th>اسم العميل</th>
                <th>الهاتف</th>
                <th>الايميل</th>
                <th>المجال</th>
                <th>الفرع</th>
                <th>المرحلة</th>
                <th>النتيجة الكليه</th>
                <th>الإجراء الأساسي</th>
              </tr>
            </thead>
            <tbody>
              {statistics.data && statistics.data.length > 0 ? (
                statistics.data.map((client, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      {client.user.first_name} {client.user.last_name}
                    </td>
                    <td>{client.user.phone} </td>
                    <td>{client.user.email} </td>
                    <td>{client.field.title}</td>
                    <td>{client.branch.name}</td>
                    <td>
                      {(() => {
                        const stepId = client.step?.id;
                        let stepLabel = "";
                        let buttonClass = "btn ";

                        switch (stepId) {
                          case 2:
                            stepLabel = "تم تقديم الطلب";
                            buttonClass += "btn-primary";
                            break;
                          case 3:
                            stepLabel = "في مرحلة التقييم الأولي";
                            buttonClass += "btn-secondary";
                            break;
                          case 4:
                            stepLabel = "في مرحلة الترحيل";
                            buttonClass += "btn-success";
                            break;
                          case 5:
                            stepLabel = "في مرحلة التقييم النهائي";
                            buttonClass += "btn-warning";
                            break;
                          default:
                            stepLabel =
                              stepId < 3 ? "حفظ كمسودة" : "Unknown Step";
                            buttonClass += "btn-dark";
                            break;
                        }

                        return (
                          <span
                            className={`${buttonClass} btn-sm`}
                            style={{ pointerEvents: "none", opacity: 0.65 }}
                          >
                            {stepLabel}
                          </span>
                        );
                      })()}
                    </td>
                    <td>{client.final_score}</td>
                    <td>
                      {" "}
                      <button
                        className="admin_table_show_btn"
                        onClick={() => handleShowDetails(client)}
                      >
                        التفاصيل
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7">
                    <div className="mb-3">لا توجد نتائج</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            style={{
              marginTop: "100px",
              marginBottom: "100px",
            }}
          >
            <Modal.Header
              closeButton
              style={{ backgroundColor: "rgb(52, 195, 143)" }}
            >
              <Modal.Title className="w-100 text-center">
                معلومات العميل
              </Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                backgroundColor: "rgb(52, 195, 143)",
              }}
            >
              {selectedClient && (
                <div>
                  <h5>معلومات العميل</h5>
                  <p>
                    <strong>الاسم:</strong> {selectedClient.user.first_name}{" "}
                    {selectedClient.user.last_name}
                  </p>
                  <p>
                    <strong>الهاتف:</strong> {selectedClient.user.phone}
                  </p>
                  <p>
                    <strong>البريد الإلكتروني:</strong>{" "}
                    {selectedClient.user.email}
                  </p>
                  <p>
                    <strong>المجال:</strong> {selectedClient.field.title}
                  </p>
                  <p>
                    <strong>الفرع:</strong> {selectedClient.branch.name}
                  </p>
                  <p>
                    <strong>النتيجة النهائية:</strong>{" "}
                    {selectedClient.final_score}
                  </p>
                  <hr />
                  <h5>تقييمات المحكمين</h5>
                  {selectedClient.referees_evaluations_sum &&
                  selectedClient.referees_evaluations_sum.length > 0 ? (
                    selectedClient.referees_evaluations_sum.map(
                      (referee, index) => (
                        <div key={index}>
                          <p>
                            <strong>اسم المحكم:</strong>{" "}
                            {referee.user.first_name} {referee.user.last_name}
                          </p>
                          <p>
                            <strong>الجوال:</strong> {referee.user.phone}
                          </p>
                          <p>
                            <strong>البريد الإلكتروني للمحكم:</strong>{" "}
                            {referee.user.email}
                          </p>
                          <p>
                            <strong>نوع المحكم:</strong>{" "}
                            {refereeTypesArabic[referee.referee_type]}
                          </p>
                          <p>
                            <strong>المجموع الكلي للتقييمات:</strong>{" "}
                            {referee.evaluations_sum}
                          </p>
                          <hr />
                        </div>
                      )
                    )
                  ) : (
                    <p>لا توجد تقييمات للمحكمين متاحة.</p>
                  )}
                </div>
              )}
            </Modal.Body>
            <Modal.Footer style={{ backgroundColor: "rgb(52, 195, 143)" }}>
              <Button
                variant=""
                onClick={() => setShowModal(false)}
                style={{ backgroundColor: "white" }}
              >
                إغلاق
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
      <div className="pagination-controls">
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={!statistics.prev_page_url}
        >
          Previous
        </button>
        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={!statistics.next_page_url}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Reports;
