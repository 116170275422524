import React, { useState, useEffect } from "react";
import axios from 'axios';
import { toast } from 'react-toastify';
import { logout } from '../auth/Login';
import { useNavigate } from 'react-router-dom';
    import Swal from 'sweetalert2';

const NewPassword = () => {
    const [userData, setUserData] = useState(null);
    const [formData, setFormData] = useState({
        newPassword: "",
        confirmPassword: ""
    });
    const navigate = useNavigate();

    useEffect(() => {
        const user_id = localStorage.getItem("user_id");

        if (user_id) {
            axios.get(`https://te.prize.pap.sa/api/user/profile/${user_id}`)
                .then((response) => {
                    setUserData(response.data);
                })
                .catch((error) => {
                    console.error("Error fetching user data:", error);
                });
        }
    }, []);

    const handleInputChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

    const handleChangePassword = (event) => {
        event.preventDefault();
        const user_id = localStorage.getItem("user_id");
        const user_type = localStorage.getItem("user_type");

        axios.post('https://te.prize.pap.sa/api/auth/cheange/new/password', {
            user_id,
            user_type,
            password: formData.newPassword,
            confirmPassword: formData.confirmPassword
        })
            .then(response => {
                logout(navigate);
            })
            .catch(error => {
                if (error.response && error.response.status === 422) {
                    let errors = error.response.data.error;

                    if (Array.isArray(errors)) {
                        // Handle array of error messages
                        const errorMessages = errors.join(', ').replace(/,/g, ',_');
                        Swal.fire({
                            title: 'Error!',
                            html: errorMessages,
                            confirmButtonText: 'حسنا'
                        });
                    } else if (typeof errors === 'object') {
                        // Handle object of error messages
                        let errorText = '';
                        for (const key in errors) {
                            if (errors.hasOwnProperty(key)) {
                                const errorMessages = errors[key].join(', ');
                               
                                errorText += `${key}: ${errorMessages.replace(/,/g, ',_')}_`;
                            }
                        }
                        Swal.fire({
                            title: 'Error!',
                            html: errorText,
                            confirmButtonText: 'حسنا'
                        });
                    } else {
                        // Handle other types of errors
                        console.error('Unexpected error structure:', errors);
                    }
                } else if (error.response) {
                    let errorMessage = error.response.data.error;
                    Swal.fire({
                        title: 'Error!',
                        text: errorMessage,
                        confirmButtonText: 'حسنا'
                    });
                }
            });
    };

   
    

    return (
        <div className="container pt-5">
            <div className="row mt-5">
                <div className="col-md-6 offset-md-3 user-account-form">
                    {userData && (
                        <>
                <div className="user-account-title mb-4">
     تغيير كلمة المرور حتى لا تظهر معك هذه الصفحة مرة اخري  سيدي/سيدتي
    <span style={{ fontWeight: 'bold' }}> {userData.user_name} </span>

</div>

                        </>
                    )}

                    <form onSubmit={handleChangePassword}>
                        <div className="mb-3">
                            <label htmlFor="newPassword" className="form-label">كلمة المرور الجديدة</label>
                            <input
                                type="password"
                                className="form-control"
                                id="newPassword"
                                name="newPassword"
                                placeholder="كلمة المرور الجديدة"
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="confirmPassword" className="form-label">تأكيد كلمة المرور</label>
                            <input
                                type="password"
                                className="form-control"
                                id="confirmPassword"
                                name="confirmPassword"
                                placeholder="تأكيد كلمة المرور"
                                onChange={handleInputChange}
                            />
                        </div>
                        <button className="btn btn-primary" type="submit">تحديث كلمة المرور</button>
                        <div className="mt-2 text-muted">بعد تحديث كلمة المرور ستحتاج إلى تسجيل الدخول مرة اخري</div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default NewPassword;
