import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { logout } from "../../auth/Login";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import ModeEditRoundedIcon from "@mui/icons-material/ModeEditRounded";

const Branches = () => {
  const [branches, setBranches] = useState([]);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [fields, setFields] = useState([]);
  const [currentBranch, setCurrentBranch] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [employee_premission, setEmployeePremission] = useState({});
  const [userType, setUserType] = useState("");

  const fetchDataEmployee = async () => {
    try {
      const user_id = localStorage.getItem("user_id");
      const response = await axios.get(
        `https://te.prize.pap.sa/api/employees/${user_id}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      setEmployeePremission(response.data.employee);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        console.log(`خطاء: ${err.response.data.error}`);
      } else {
        console.log("حدث خطأ أثناء جلب الحقول.");
      }
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    const user_type = localStorage.getItem("user_type");

    if (!token) {
      logout(navigate);
    }

    if (user_type === "referee" || user_type === "client") {
      navigate(-1);
    }
    setUserType(localStorage.getItem("user_type"));

    fetchDataEmployee();

    fetchBranches();
    fetchFields();
  }, [navigate]);

  useEffect(() => {
    fetchFields();
  }, []);

  const fetchFields = async () => {
    try {
      const response = await axios.get("https://te.prize.pap.sa/api/fields", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      setFields(response.data.fields);
      console.log(response.data.fields);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        setError(`خطاء: ${err.response.data.error}`);
      } else {
        setError("حدث خطأ أثناء جلب الحقول.");
      }
    }
  };

  // Fetch branches from API
  const fetchBranches = async () => {
    try {
      const response = await axios.get("https://te.prize.pap.sa/api/branches", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      setBranches(response.data.branches);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        setError(`خطاء: ${err.response.data.error.name}`);
      } else {
        setError("حدث خطأ أثناء جلب الحقول.");
      }
    }
  };

  // Handle delete branch
  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "انتبه انت علي وشك حذف جميع التقييمات التي تخص هذا الفرع"
    );
    if (!confirmDelete) return;

    try {
      await axios.post(
        `https://te.prize.pap.sa/api/delete/branch/${id}`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      fetchBranches();
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        setError(`خطاء: ${err.response.data.error}`);
      } else {
        setError("حدث خطأ أثناء جلب الحقول.");
      }
    }
  };

  // Handle new branch creation
  const NewBranche = () => {
    navigate("/admin/dashboard/branches/new");
  };

  // Handle edit branch
  const handleEdit = (branch) => {
    setCurrentBranch(branch);
    setIsEditModalOpen(true);
  };

  // Handle save branch
  const handleSave = async (id, name, fieldId, mandatory) => {
    try {
      await axios.put(
        `https://te.prize.pap.sa/api/branch/${id}`,
        {
          name,
          field_id: fieldId,
          mandatory,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      fetchBranches();
      setIsEditModalOpen(false);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        setError(`خطاء: ${err.response.data.error.name}`);
      } else {
        setError("حدث خطأ أثناء جلب الحقول.");
      }
    }
  };

  const EditBranchModal = ({
    isOpen,
    onClose,
    branch,
    onSave,
    fields = [],
  }) => {
    const [name, setName] = useState(branch ? branch.name : "");
    const [fieldId, setFieldId] = useState(branch ? branch.field_id : "");
    const [mandatory, setMandatory] = useState(
      branch ? branch.mandatory : false
    );

    const handleSubmit = (event) => {
      event.preventDefault();
      onSave(branch.id, name, fieldId, mandatory);
    };

    if (!isOpen) return null;

    return (
      <div style={modalStyle}>
        <div style={modalContentStyle}>
          <span onClick={onClose} style={closeModalStyle}>
            &times;
          </span>
          <form onSubmit={handleSubmit} style={formStyle}>
            <label style={labelStyle}>اسم الفرع:</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              style={inputStyle}
            />
            {error && <p style={{ color: "red" }}>{error}</p>}
            <label style={labelStyle}>المجال:</label>
            <select
              value={fieldId}
              onChange={(e) => setFieldId(e.target.value)}
              style={inputStyle}
              required
            >
              <option value="">اختار المجال</option>
              {fields.map((field) => (
                <option key={field.id} value={field.id}>
                  {field.title}
                </option>
              ))}
            </select>
            <div className="form-group">
              <label style={labelStyle}>إلزامي:</label>
              <input
                type="checkbox"
                checked={mandatory == 1}
                onChange={(e) => setMandatory(e.target.checked ? 1 : 0)}
                style={inputStyle}
              />

            </div>

            <button type="submit" className="admin_branch_edit">
              تعديل
            </button>
          </form>
        </div>
      </div>
    );
  };

  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "20px",
  };

  const thStyle = {
    padding: "15px",
    textAlign: "center",
    borderBottom: "1px solid #ddd",
    backgroundColor: "#f2f2f2",
    color: "black",
  };

  const tdStyle = {
    textAlign: "center",
    borderBottom: "1px solid #ddd",
  };

  const buttonStyle = {
    padding: "10px 20px",
    margin: "5px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  };
  const modalStyle = {
    display: "block",
    position: "fixed",
    zIndex: 1,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    overflow: "auto",
    backgroundColor: "rgba(0,0,0,0.4)",
  };

  const modalContentStyle = {
    backgroundColor: "rgb(52, 195, 143)",
    margin: "15% auto",
    padding: "40px",
    border: "1px solid #888",
    width: "40%",
    borderRadius: "10px",
  };

  const closeModalStyle = {
    color: "red",
    float: "right",
    fontSize: "28px",
    fontWeight: "bold",
    cursor: "pointer",
  };

  const formStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: "50px",
  };

  const labelStyle = {
    marginBottom: "10px",
    fontWeight: "bold",
  };

  const inputStyle = {
    marginBottom: "20px",
    width: "100%",
    padding: "10px",
    border: "1px solid #ddd",
    borderRadius: "5px",
  };

  return (
    <div className="container-fluid mt-2">
      <div className="row admin_page_header">
        <div className="admin_page_title">استعراض الافرع</div>
        <div className="admin_page_subtitle mt-2">الافرع / استعراض الافرع</div>
      </div>
      <div className="row">
        <div className="admin_page_content col-12 row">
          <div className="col-12 row d-flex justify-content-between">
            <div className="w-100">
              {employee_premission.branches?.store == true ? (
                <button
                  onClick={NewBranche}
                  className="admin_page_btn_new mb-3"
                >
                  اضافه فرع
                </button>
              ) : (
                userType !== "employee" && (
                  <button
                    onClick={NewBranche}
                    className="admin_page_btn_new mb-3"
                  >
                    اضافه فرع
                  </button>
                )
              )}
            </div>
          </div>

          <div className="table-responsive col-12">
            <table className="table admin_table">
              <thead className="admin_table_thead">
                <tr>
                  <th>اسم الفرع</th>
                  <th>اسم المجال </th>
                  <th>إلزامي </th>
                  <th>الاجراءات</th>
                </tr>
              </thead>
              {branches.length > 0 ? (
                <tbody>
                  {branches.map((branch) => (
                    <tr key={branch.id}>
                      <td style={tdStyle}>{branch.name}</td>
                      <td style={tdStyle}>{branch.field_title}</td>
                      <td style={tdStyle}>
                        {branch.mandatory == 1 ? (
                          <span style={{ color: "green" }}>✓</span>
                        ) : (
                          <span style={{ color: "red" }}>✕</span>
                        )}
                      </td>

                      <td style={tdStyle}>
                        {employee_premission.branches?.destroy == true ? (
                          <button
                            onClick={() => handleDelete(branch.id)}
                            className="admin_table_delete_btn"
                          >
                            <DeleteForeverRoundedIcon />
                          </button>
                        ) : (
                          userType != "employee" && (
                            <button
                              onClick={() => handleDelete(branch.id)}
                              className="admin_table_delete_btn"
                            >
                              <DeleteForeverRoundedIcon />
                            </button>
                          )
                        )}
                        {employee_premission.branches?.update == true ? (
                          <button
                            onClick={() => handleEdit(branch)}
                            className="admin_table_edit_btn "
                          >
                            <ModeEditRoundedIcon />
                          </button>
                        ) : (
                          userType != "employee" && (
                            <button
                              onClick={() => handleEdit(branch)}
                              className="admin_table_edit_btn "
                            >
                              <ModeEditRoundedIcon />
                            </button>
                          )
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <p>لا يوجد افرع</p> // Message displayed when there are no branches
              )}
            </table>
          </div>
          {currentBranch && (
            <EditBranchModal
              isOpen={isEditModalOpen}
              onClose={() => setIsEditModalOpen(false)}
              branch={currentBranch}
              onSave={handleSave}
              fields={fields}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Branches;
