import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { logout } from "../../auth/Login";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import ModeEditRoundedIcon from "@mui/icons-material/ModeEditRounded";

import "./field.css";

const Fields = () => {
  const navigate = useNavigate();
  const [fields, setFields] = useState([]);
  const [error, setError] = useState("");

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentField, setCurrentField] = useState(null);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const [employee_premission, setEmployeePremission] = useState({});
  const [userType, setUserType] = useState("");

  const fetchDataEmployee = async () => {
    try {
      const user_id = localStorage.getItem("user_id");
      const response = await axios.get(
        `https://te.prize.pap.sa/api/employees/${user_id}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      setEmployeePremission(response.data.employee);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        console.log(`خطاء: ${err.response.data.error}`);
      } else {
        console.log("حدث خطأ أثناء جلب الحقول.");
      }
    }
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    const user_type = localStorage.getItem("user_type");

    if (!token) {
      logout(navigate);
      navigate(-1);
    }

    if (user_type === "referee" || user_type === "client") {
      navigate(-1);
    }
    setUserType(localStorage.getItem("user_type"));

    fetchDataEmployee();

    fetchFields();
  }, [navigate]);

  const NewField = () => {
    navigate("/admin/dashboard/Fields/new");
  };

  const FieldModal = ({ isOpen, onClose, field, onSave, isEditMode }) => {
    const [title, setTitle] = useState(field ? field.title : "");

    const handleSubmit = (event) => {
      event.preventDefault();
      onSave(field ? field.id : null, title);
    };

    if (!isOpen) return null;

    return (
      <div className={`modal ${isOpen ? "d-block" : "d-none"}`} tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div
            className="modal-content"
            style={{
              backgroundColor: "#3ab09e",
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
            }}
          >
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                onClick={onClose}
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label className="form-label">اسم المجال:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  {error && <p className="text-danger">{error}</p>}
                </div>
                <div className="modal-footer">
                  <button type="submit" className="admin-field-edit">
                    {isEditMode ? "تعديل" : "اضافة"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const fetchFields = async () => {
    try {
      const response = await axios.get("https://te.prize.pap.sa/api/fields", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      setFields(response.data.fields);
    } catch (err) {
      // Check if the error response is available and set a more specific error message
      if (err.response && err.response.data && err.response.data.error) {
        // Use the error message from the response, if available
        setError(`خطاء: ${err.response.data.error}`);
      } else {
        // Fallback error message if no response or specific error message is available
        setError("حدث خطأ أثناء جلب الحقول.");
      }
    }
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "انتبه في حاله حذف هذا المجال سوف تقوم بحذف حميع الافرع المرتبطه به"
    );
    if (!confirmDelete) {
      return;
    }

    try {
      await axios.post(
        `https://te.prize.pap.sa/api/delete/fields/${id}`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      fetchFields();
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        // Use the error message from the response, if available
        setError(`خطاء: ${err.response.data.error}`);
      } else {
        // Fallback error message if no response or specific error message is available
        setError("حدث خطأ أثناء جلب الحقول.");
      }
    }
  };

  const handleEdit = (field) => {
    setCurrentField(field);
    setIsEditModalOpen(true);
  };

  const handleSave = async (id, title) => {
    try {
      await axios.put(
        `https://te.prize.pap.sa/api/fields/${id}`,
        { title },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      fetchFields();
      setIsEditModalOpen(false);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        // Use the error message from the response, if available
        setError(`خطاء: ${err.response.data.error.title}`);
      } else {
        // Fallback error message if no response or specific error message is available
        setError("حدث خطأ أثناء جلب الحقول.");
      }
    }
  };

  const EditFieldModal = ({ isOpen, onClose, field, onSave }) => {
    const [title, setTitle] = useState(field.title);

    const handleSubmit = (event) => {
      event.preventDefault();
      onSave(field.id, title);
    };

    return (
      <div
        style={{
          display: isOpen ? "block" : "none",
          position: "fixed",
          zIndex: 1,
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          overflow: "auto",
          backgroundColor: "rgba(0,0,0,0.4)",
        }}
      >
        <div
          style={{
            backgroundColor: "#fefefe",
            margin: "15% auto",
            padding: "20px",
            border: "1px solid #888",
            width: "80%",
          }}
        >
          <span
            onClick={onClose}
            style={{
              color: "#aaa",
              float: "right",
              fontSize: "28px",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            &times;
          </span>
          <form onSubmit={handleSubmit}>
            <label>اسم المجال:</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <button type="submit">Save</button>
          </form>
        </div>
      </div>
    );
  };

  return (
    <div className="container-fluid mt-2">
      <div className="row admin_page_header">
        <div className="admin_page_title">استعراض المجالات</div>
        <div className="admin_page_subtitle mt-2">
          المجالات / استعراض المجالات
        </div>
      </div>
      <div className="row">
        <div className="admin_page_content col-12 row">
          <div className="col-12 row d-flex justify-content-between">
            <div className="w-100">
              {employee_premission.fields?.store == true ? (
                <button onClick={NewField} className="admin_page_btn_new mb-3">
                  إضافة مجال
                </button>
              ) : (
                userType !== "employee" && (
                  <button
                    onClick={NewField}
                    className="admin_page_btn_new mb-3"
                  >
                    إضافة مجال
                  </button>
                )
              )}
            </div>
          </div>

          <div className="row mt-3">
            <div className="table-responsive col-12">
              <table className="table admin_table">
                <thead className="admin_table_thead">
                  <tr>
                    <th>اسم المجال</th>
                    <th>الاجراءات</th>
                  </tr>
                </thead>
                {fields.length > 0 ? (
                  <tbody>
                    {fields.map((field) => (
                      <tr key={field.id}>
                        <td>{field.title}</td>
                        <td>
                          {employee_premission.fields?.destroy == true ? (
                            <button
                              onClick={() => handleDelete(field.id)}
                              className="admin_table_delete_btn"
                            >
                              <DeleteForeverRoundedIcon />
                            </button>
                          ) : (
                            userType !== "employee" && (
                              <button
                                onClick={() => handleDelete(field.id)}
                                className="admin_table_delete_btn"
                              >
                                <DeleteForeverRoundedIcon />
                              </button>
                            )
                          )}
                          {employee_premission.fields?.update == true ? (
                            <button
                              onClick={() => handleEdit(field)}
                              className="admin_table_edit_btn "
                            >
                              <ModeEditRoundedIcon />
                            </button>
                          ) : (
                            userType !== "employee" && (
                              <button
                                onClick={() => handleEdit(field)}
                                className="admin_table_edit_btn "
                              >
                                <ModeEditRoundedIcon />
                              </button>
                            )
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="2" className="text-center">
                        لا توجد بيانات
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
              {currentField && (
                <FieldModal
                  isOpen={isEditModalOpen}
                  onClose={() => setIsEditModalOpen(false)}
                  field={currentField}
                  onSave={handleSave}
                  isEditMode={true}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fields;
