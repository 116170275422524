// EditImageModal.js
import React, { useState, useEffect, useRef } from 'react';

const EditImageModal = ({ isOpen, onClose, ad, onUpdate }) => {
  const [editTitle, setEditTitle] = useState('');
  const [editImage, setEditImage] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (ad) {
      setEditTitle(ad.title);
    }
  }, [ad]);

  const handleUpdate = () => {
    // Implement the logic to update the image details
    // Use the onUpdate function passed as a prop
    // to handle the update API call
    const updatedData = {
      title: editTitle,
      img: editImage,
    };
    onUpdate(updatedData);

    // Close the modal after updating
    onClose();
  };

  const handleImageChange = (e) => {
    // Handle changes in the file input
    setEditImage(e.target.files[0]);
  };

  return (
    <div className={`modal ${isOpen ? 'show' : ''}`} tabIndex="-1" role="dialog">
      {/* ... (rest of the modal code) */}
      <div className="modal-body">
        {/* Form for editing image details */}
        <form>
          <div className="mb-3">
            <label htmlFor="editTitle" className="form-label">
              Title
            </label>
            <input
              type="text"
              className="form-control"
              id="editTitle"
              value={editTitle}
              onChange={(e) => setEditTitle(e.target.value)}
            />
          </div>
          {/* Add input for editing image file */}
          <div className="mb-3">
            <label htmlFor="editImage" className="form-label">
              Image
            </label>
            <input
              type="file"
              className="form-control"
              id="editImage"
              ref={fileInputRef}
              onChange={handleImageChange}
            />
          </div>
        </form>
      </div>
      {/* ... (rest of the modal code) */}
    </div>
  );
};

export default EditImageModal;
