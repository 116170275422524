import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { logout } from '../auth/Login';
import ImageUploadModal from './ImageUploadModal';
import EditImageModal from './EditImageModal';
import REGIONS from "../../common/REGIONS";
import CITIES from "../../common/CITIES";

const Dashboard = () => {
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);
  const [ads, setAds] = useState([]);
  const [editAd, setEditAd] = useState(null);
  const [editTitle, setEditTitle] = useState(''); // Define setEditTitle's initial state
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [userType, setUserType] = useState("");
  const [employeePermission, setEmployeePermission] = useState({});
  const token = localStorage.getItem('token');

  const [statistics, setStatistics] = useState({
    total_users: 0,
    clients: { count: 0, percentage: 0 },
    referees: { count: 0, percentage: 0 },
    employees: { count: 0, percentage: 0 },
    branches: [],
    clients_by_region: [],
    clients_by_step: [],
  });

  useEffect(() => {
    const checkAuthorization = () => {
      if (!token) {
        logout(navigate);
        navigate('/login'); 
      }

      const user_type = localStorage.getItem('user_type');
      if (user_type === "referee" || user_type === "client") {
        navigate(-1);
      }
      setUserType(user_type);
    };

    checkAuthorization();
    fetchAds();
    fetchStatistics();
  }, [navigate, token]);

  const fetchAds = async () => {
    try {
      const response = await axios.get('https://te.prize.pap.sa/api/admin/ads', {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      setAds(response.data);
    } catch (error) {
      console.error('Error fetching ads:', error.message || 'Unknown error');
    }
  };

  const fetchStatistics = async () => {
    try {
      const response = await axios.get('https://te.prize.pap.sa/api/statistics', {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      setStatistics(response.data);
    } catch (error) {
      console.error('Error fetching statistics:', error.message || 'Unknown error');
    }
  };

  const openModal = () => setModalOpen(true);
  const closeModal = () => {
    setModalOpen(false);
    fetchAds(); // Refresh ads after closing the modal
  };

  const handleEdit = ad => {
    setEditAd(ad);
    setEditTitle(ad.title);
    setEditModalOpen(true);
  };

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: 'هل أنت متأكد؟',
      text: 'لن تكون قادرًا على التراجع عن هذا!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'نعم، احذفها!'
    });

    if (result.isConfirmed) {
      try {
        const response = await axios.delete(`https://te.prize.pap.sa/api/admin/ads/${id}`, {
          headers: { 'Authorization': `Bearer ${token}` },
        });

        if (response.status === 204) {
          Swal.fire('نجاح', 'تم حذف الإعلان بنجاح', 'success');
          fetchAds();
        } else {
          Swal.fire('خطأ', response.data.error || 'حدث خطأ أثناء حذف الإعلان', 'error');
        }
      } catch (error) {
        Swal.fire('خطأ', error.message || 'حدث خطأ أثناء الاتصال بالخادم', 'error');
      }
    }
  };

  const customStyles = {
    adImage: {
      width: "100px",
      height: "100px",
      objectFit: "cover",
      borderRadius: "10px", // Rounded corners for images
    },
    editButton: {
      marginRight: "8px",
    },
    cardHeader: {
      backgroundColor: "#f0f0f0",
      color: "#333",
      padding: "10px",
      borderRadius: "5px 5px 0 0",
    },
    cardBody: {
      padding: "20px",
    },
    table: {
      marginTop: "20px",
    },
    // Additional custom styles can be added here
  };
  const styles = {
    sectionHeader: {
      marginBottom: "2rem",
      color: "#007bff", // Primary color for headers
    },
    card: {
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
      transition: "transform 0.2s", // Smooth transition for hover effect
      cursor: "pointer", // Cursor change to indicate interactivity
    },
    cardHover: {
      transform: "scale(1.05)", // Slightly increase size on hover
    },
    cardBody: {
      padding: "1rem",
    },
    cardTitle: {
      fontSize: "1.25rem", // Larger text for titles
      color: "#343a40", // Dark color for contrast
    },
    cardText: {
      fontSize: "1rem",
      color: "#6c757d", // Muted color for regular text
    },
  };

  // Handlers for mouse enter and leave to apply hover effect
  const handleMouseEnter = (e) => {
    e.target.style.transform = styles.cardHover.transform;
  };

  const handleMouseLeave = (e) => {
    e.target.style.transform = "none";
  };
  
  return (
    <div className="container mt-4" dir="rtl">
      {/* Dashboard header */}
      <div className="mb-4">
        <h1 className="mb-3">لوحة التحكم</h1>
        <div className="d-flex justify-content-end">
          <button className="btn btn-primary mb-3" onClick={openModal}>إضافة إعلان جديد</button>
        </div>
      </div>
  
      <div className="row g-3">
        {/* Statistics Cards */}
        <div className="col-md-6 col-lg-3">
          <div className="card text-center bg-primary text-white">
            <div className="card-body">
              <h5 className="card-title">عدد المستخدمين الإجمالي</h5>
              <p className="card-text fs-4">{statistics.total_users}</p>
            </div>
          </div>
        </div>
  
        <div className="col-md-6 col-lg-3">
          <div className="card text-center bg-success text-white">
            <div className="card-body">
              <h5 className="card-title">العملاء</h5>
              <p className="card-text">{statistics.clients.count} ({statistics.clients.percentage.toFixed(2)}%)</p>
            </div>
          </div>
        </div>
  
        <div className="col-md-6 col-lg-3">
          <div className="card text-center bg-info text-white">
            <div className="card-body">
              <h5 className="card-title">الحكام</h5>
              <p className="card-text">{statistics.referees.count} ({statistics.referees.percentage.toFixed(2)}%)</p>
            </div>
          </div>
        </div>
  
        <div className="col-md-6 col-lg-3">
          <div className="card text-center bg-warning text-white">
            <div className="card-body">
              <h5 className="card-title">الموظفين</h5>
              <p className="card-text">{statistics.employees.count} ({statistics.employees.percentage.toFixed(2)}%)</p>
            </div>
          </div>
        </div>
        
      </div>

      <div class="container mt-5">
  <h2 class="mb-4 fw-bold text-dark">العملاء حسب الفروع</h2>
  <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
    {statistics.branches.map((branch) => (
      <div class="col" key={branch.branch_id}>
        <div class="card border-0 shadow h-100">
          <div class="card-body">
            <h5 class="card-title text-primary">{branch.branch_name}</h5>
            <p class="card-text">عدد العملاء: {branch.clients_count}</p>
          </div>
        </div>
      </div>
    ))}
  </div>
</div>

<div class="container mt-5">
  <h2 class="mb-4 fw-bold text-success">العملاء حسب المنطقة</h2>
  <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
    {statistics.clients_by_region.map((regionStat) => {
      // Find the region name from the REGIONS array
      const regionName = REGIONS.find(region => region.region_id == regionStat.region)?.name_ar || 'منطقة غير محددة';
      
      return (
        <div class="col" key={regionStat.region}>
          <div class="card border-0 shadow h-100">
            <div class="card-body">
              <h5 class="card-title text-info">{regionName}</h5>
              <p class="card-text">العدد: {regionStat.count}</p>
              <p class="card-text">النسبة المئوية: {regionStat.percentage.toFixed(2)}%</p>
            </div>
          </div>
        </div>
      );
    })}
  </div>
</div>




<div class="container mt-5">
  <h2 class="mb-4 fw-bold text-warning">العملاء حسب المرحلة</h2>
  <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
    {statistics.clients_by_step.map((step) => (
      <div class="col" key={step.step_id}>
        <div class="card border-0 shadow h-100">
          <div class="card-body">
            <h5 class="card-title text-danger">{step.step_name}</h5>
            <p class="card-text">عدد العملاء: {step.clients_count}</p>
            <p class="card-text">النسبة المئوية: {step.percentage.toFixed(2)}%</p>
          </div>
        </div>
      </div>
    ))}
  </div>
</div>



  <div className="row mt-4">
        <div className="col-12">
          <h2>الإعلانات</h2>
          <div className="table-responsive" style={customStyles.table}>
            <table className="table table-striped">
              <thead className="thead-dark">
                <tr>
                  <th>اسم الإعلان</th>
                  <th>الصورة</th>
                  <th>الإجراءات</th>
                </tr>
              </thead>
              <tbody>
                {ads.map((ad) => (
                  <tr key={ad.id}>
                    <td>{ad.title}</td>
                    <td>
                      <img src={`https://te.prize.pap.sa/${ad.img}`} alt={ad.title} style={customStyles.adImage} />
                    </td>
                    <td>
                      <button className="btn btn-info" style={customStyles.editButton} onClick={() => handleEdit(ad)}>تعديل</button>
                      <button className="btn btn-danger" onClick={() => handleDelete(ad.id)}>حذف</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Modals for image upload and edit */}
      <ImageUploadModal isOpen={isModalOpen} onClose={closeModal} />
      <EditImageModal isOpen={isEditModalOpen} onClose={() => setEditModalOpen(false)} ad={editAd} onUpdate={fetchAds} />
    </div>
  );
};

export default Dashboard;