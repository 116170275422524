import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";


const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [phone, setPhone] = useState("");
  const [errors, setErrors] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  useEffect(() => {
    const phoneParams = location.state?.phone;
    if (phoneParams) {
      setPhone(phoneParams);
    }
  });
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangePassword = (event) => {
    event.preventDefault();

    // Clear previous errors
    setErrors({ newPassword: "", confirmPassword: "" });

    // Basic validation
    let hasError = false;
    if (!formData.newPassword) {
      setErrors((prev) => ({ ...prev, newPassword: "هذا الحقل الزامي" }));
      hasError = true;
    }
    if (!formData.confirmPassword) {
      setErrors((prev) => ({ ...prev, confirmPassword: "هذا الحقل الزامي" }));
      hasError = true;
    }
    if (formData.newPassword !== formData.confirmPassword) {
      setErrors((prev) => ({
        ...prev,
        confirmPassword: "كلمة المرور غير متطابقة",
      }));
      hasError = true;
    }

    if (hasError) return;

    // Proceed with the API request if no validation errors
    axios
      .post("https://te.prize.pap.sa/api/update/password", {
        phone,
        password: formData.newPassword,
        confirmPassword: formData.confirmPassword,
      })
      .then((response) => {
        localStorage.removeItem("user_login");
        navigate("/");
      })
      .catch((error) => {
        let errorMessage = "حدث خطأ أثناء تحديث كلمة المرور";
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          errorMessage = error.response.data.error;
        } else if (!error.response) {
          errorMessage = "فشل الاتصال بالخادم";
        }
        // Displaying the customized error message in SweetAlert
        Swal.fire({
          title: "Error!",
          text: errorMessage,
          icon: "error",
          confirmButtonText: "حسنا",
        });
      });
  };

  return (
    <div>
      <div className="container pt-5">
        <div className="row mt-5 d-flex justify-content-center align-items-center">
          <div className="col-md-8">
            <form onSubmit={handleChangePassword}>
              <div className="row user_account_form_policy">
                <div className="form-group mb-3">
                  <label htmlFor="newPassword">كلمة المرور الجديدة</label>
                  <input
                    type="password"
                    className={`form-control ${
                      errors.newPassword ? "is-invalid" : ""
                    }`}
                    id="newPassword"
                    name="newPassword"
                    placeholder="كلمة المرور الجديدة"
                    onChange={handleInputChange}
                  />
                  {errors.newPassword && (
                    <div className="text-danger">{errors.newPassword}</div>
                  )}
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="confirmPassword">تأكيد كلمة المرور</label>
                  <input
                    type="password"
                    className={`form-control ${
                      errors.confirmPassword ? "is-invalid" : ""
                    }`}
                    id="confirmPassword"
                    name="confirmPassword"
                    placeholder="تأكيد كلمة المرور"
                    onChange={handleInputChange}
                  />
                  {errors.confirmPassword && (
                    <div className="text-danger">{errors.confirmPassword}</div>
                  )}
                </div>

                <div className="w-100 d-flex align-items-center">
                  <button className="user_account_btn" type="submit">
                    تحديث كلمة المرور
                  </button>
                  <div className="mx-2 user_account_confirm_message">
                    بعد تحديث كلمة المرور ستحتاج إلى تسجيل الدخول مرة اخري
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
