import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import "./Archives.css";
import { useNavigate } from 'react-router-dom';
import { logout } from '../auth/Login';

const Archives = () => {
    const [archives, setArchives] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedArchive, setSelectedArchive] = useState({ id: '', action: '', created_at: '', client: {}, user: {} });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    const [userType, setUserType] = useState("");
    const [employee_premission, setEmployeePremission] = useState({});
    const fetchDataEmployee = async () => {
      try {
            const user_id = localStorage.getItem('user_id');
            const response = await axios.get(`https://te.prize.pap.sa/api/employees/${user_id}`, {
                  headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
              });
    
              setEmployeePremission(response.data.employee);
    
      } catch (err) {
          if (err.response && err.response.data && err.response.data.error) {
              console.log(`خطاء: ${err.response.data.error}`);
          } else {
              console.log('حدث خطأ أثناء جلب الحقول.');
          }
      }
    };
   
    useEffect(() => {
      
      setUserType(localStorage.getItem('user_type'));
      fetchDataEmployee();
        fetchData();
    }, [navigate]);


    const fetchData = async (page = 1) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`https://te.prize.pap.sa/api/archives?page=${page}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setArchives(Array.isArray(response.data.archive.data) ? response.data.archive.data : []);
            setTotalPages(response.data.archive.meta.last_page); // Adjusted to access nested 'last_page'
            setCurrentPage(response.data.archive.meta.current_page);
            setLoading(false);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                logout();
                navigate('/login');
            } else {
                setError('فشل في جلب الأرشيف.');
            }
            setLoading(false);
        }
    };
    
    const deleteArchive = async (id) => {
        try {
            const token = localStorage.getItem('token');
            await axios.delete(`https://te.prize.pap.sa/api/archives/${id}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setArchives(archives.filter(archive => archive.id !== id));
            Swal.fire('تم الحذف!', 'تم حذف الأرشيف بنجاح.', 'success');
        } catch (error) {
            Swal.fire('خطأ!', 'فشل في حذف الأرشيف.', 'error');
        }
    };

    const fetchArchiveDetails = async (id) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`https://te.prize.pap.sa/api/archives/${id}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setSelectedArchive(response.data.archive);
            setShowModal(true);
        } catch (error) {
            Swal.fire('خطأ!', 'فشل في جلب بيانات الأرشيف.', 'error');
        }
    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
        return new Date(dateString).toLocaleDateString('ar-EG', options);
    };

    const PaginationControls = () => {
        const pages = Array.from({ length: totalPages }, (_, i) => i + 1);
    
        return (
            <nav>
                <ul className="pagination text-black">
                    {pages.map(page => (
                        <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                            <button className="page-link" onClick={() =>   fetchData(page)}>
                                {page}
                            </button>
                        </li>
                    ))}
                </ul>
            </nav>
        );
    };
    

    return (
      <div className="container-fluid mt-2">
        <div className="row admin_page_header">
          <div className="admin_page_title">استعراض الأرشيف</div>
          <div className="admin_page_subtitle mt-2">
            الأرشيف / استعراض الأرشيف
          </div>
        </div>
        <div className="admin_page_content col-12 row">
          {loading && (
            <div className="text-center">
              <span
                className="spinner-border text-primary"
                role="status"
              ></span>
            </div>
          )}
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          <div className="col-12 row d-flex justify-content-between">
            <div className="col-md-4">
              <div className="search-container">
                <input
                  type="text"
                  placeholder=" ابحث عن الطلب...."
                  //   value={searchQuery}
                  //   onChange={(e) => setSearchQuery(e.target.value)}
                  className="search-input admin_candi_search_input form-control"
                />
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="table-responsive col-12">
              <table className="table admin_table">
                <thead className="admin_table_thead">
                  <tr>
                    <th>رقم الطلب</th>
                    <th>المستخدم</th>
                    <th>الإجراء</th>
                    <th>تاريخ الإنشاء</th>
                    <th>خيارات</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(archives) &&
                    archives.map((archive) => (
                      <tr key={archive.id}>
                        <td>{archive.id}</td>
                        <td>
                          {archive.user.first_name} {archive.user.last_name}
                        </td>
                        <td>{archive.action}</td>
                        <td>{formatDate(archive.created_at)}</td>
                        <td>
                          {employee_premission.archives?.destroy == true ||
                            (userType !== "employee" && (
                              <button
                                className="admin_table_delete_btn"
                                onClick={() => deleteArchive(archive.id)}
                              >
                                حذف
                              </button>
                            ))}
                          {employee_premission.archives?.show == true ||
                            (userType !== "employee" && (
                              <button
                                className="admin_table_show_btn"
                                onClick={() => fetchArchiveDetails(archive.id)}
                              >
                                عرض التفاصيل
                              </button>
                            ))}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>

          <PaginationControls />

          {archives.length === 0 && !loading && (
            <p className="text-muted text-center mt-4">لا يوجد أرشيف.</p>
          )}
        </div>

        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          centered
          className="mt-5"
        >
          <Modal.Header closeButton className="custom-modal-header">
            <Modal.Title className="text-center w-100">
              تفاصيل الأرشيف
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4 custom-modal-body">
            <div className="archive-info mb-4">
              <h5 className="fw-bold">رقم الأرشيف: {selectedArchive.id}</h5>
              <p>الفعل: {selectedArchive.action}</p>
              <p>الإجراء: {selectedArchive.reason}</p>
              <p>تاريخ الإنشاء: {formatDate(selectedArchive.created_at)}</p>
            </div>
            <div className="client-info mb-4">
              <h6 className="text-secondary">معلومات العميل:</h6>
              <p>
                الاسم: {selectedArchive.client.first_name}{" "}
                {selectedArchive.client.last_name}
              </p>
              <p>الهاتف: {selectedArchive.client.phone}</p>
              <p>البريد الإلكتروني: {selectedArchive.client.email}</p>
            </div>
            <div className="user-info mb-4">
              <h6 className="text-secondary">معلومات المستخدم:</h6>
              <p>
                الاسم: {selectedArchive.user.first_name}{" "}
                {selectedArchive.user.last_name}
              </p>
              <p>الهاتف: {selectedArchive.user.phone}</p>
              <p>البريد الإلكتروني: {selectedArchive.user.email}</p>
            </div>
          </Modal.Body>
          <Modal.Footer className="custom-modal-footer">
            <Button
              variant="secondary"
              className="custom-modal-close-button"
              onClick={() => setShowModal(false)}
            >
              إغلاق
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
}

export default Archives;