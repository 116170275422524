import React, { useState } from 'react';
import Swal from 'sweetalert2';

const ImageUploadModal = ({ isOpen, onClose }) => {
    const [image, setImage] = useState(null);
    const [title, setTitle] = useState('');

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
    };

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('title', title);
        formData.append('img', image);

        const token = localStorage.getItem('token');
        try {
            const response = await fetch('https://te.prize.pap.sa/api/admin/ads', {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            const data = await response.json();
            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: 'نجاح',
                    text: data.message || 'تم الحفظ بنجاح',
                });
                onClose();
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'خطأ',
                    text: data.error || 'حدث خطأ في رفع الصوره',
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'خطأ',
                text:'خطأ غير متوقع، ربما من الإنترنت'
            });
        }
    
    };

    if (!isOpen) return null;

    return (
        <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" aria-modal="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">اضافه اعلان</h5>
                        <button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="title" className="form-label">عنوان للصوره ان كنت تريد وضع عنوان للاعلان:</label>
                                <input type="text" className="form-control" id="title" value={title} onChange={handleTitleChange} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="img" className="form-label">صوره الاعلان:</label>
                                <input type="file" className="form-control" id="img" onChange={handleImageChange} />
                            </div>
                            <button type="submit" className="btn btn-primary">حفظ</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImageUploadModal;
