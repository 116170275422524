import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { logout } from "../../auth/Login";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";

const Deportation = () => {
  const navigate = useNavigate();
  const [Branches, setBranches] = useState([]);
  const [number_of_winners, setNumberofwinners] = useState("");
  const [branchId, setBranchId] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [statistics, setStatistics] = useState([]);
  const [referee_count, setRefereeCount] = useState(0);
  const [errors, setErrors] = useState({});
  const [showGradesPopup, setShowGradesPopup] = useState(false);
  const [showCountPopup, setShowCountPopup] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const user_type = localStorage.getItem("user_type");
    if (!token) {
      logout(navigate);
    }

    if (user_type === "referee" || user_type === "client") {
      navigate(-1);
    } else {
      fetchStatistics();
    }

    fetchBranches();
  }, [navigate]);

  const fetchBranches = async () => {
    try {
      const response = await axios.get(
        "https://te.prize.pap.sa/api/branches/deportation",
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setBranches(response.data.branches);
    } catch (err) {
      console.error(err);
      setErrors((prev) => ({
        ...prev,
        fetchBranches: "Failed to load branches",
      }));
    }
  };

  const fetchStatistics = async () => {
    try {
      const response = await axios.get(
        "https://te.prize.pap.sa/api/deportation/statistics/client",
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      setStatistics(response.data.final_clients);
      setRefereeCount(response.data.referee_count);
    } catch (err) {
      console.error("Error fetching statistics: ", err);
    }
  };

  const handleSubmitGrades = async (event) => {
    event.preventDefault();

    if (
      !number_of_winners ||
      isNaN(number_of_winners) ||
      number_of_winners <= 0
    ) {
      Swal.fire({
        title: "خطأ",
        text: "يرجى إدخال رقم صحيح أكبر من الصفر لعدد الفائزين.",
        icon: "error",
        confirmButtonText: "حسنًا",
      });
      return;
    }

    const isConfirmed = await Swal.fire({
      title: "هل انت متأكد ؟",
      text: `هل أنت متأكد أنك تريد ترحيل جميع المتسابقين الذين حصلوا على درجات أعلى من ${number_of_winners}؟`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "نعم بالتأكيد !",
    });

    if (!isConfirmed.value) {
      return;
    }

    try {
      const response = await axios.post(
        "https://te.prize.pap.sa/api/deportation/client",
        {
          number_of_winners: parseInt(number_of_winners, 10),
          branchId,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      if (response.data.success) {
        Swal.fire("نجاح!", response.data.message, "success");
        fetchStatistics();
        handleClosePopup();
    } else {
        Swal.fire({
            title: "خطأ!",
            text: response.data.message || "حدث خطأ أثناء العملية. يرجى المحاولة مرة اخري لاحقاً.",
            icon: "error",
            confirmButtonText: "حسنًا",
        });
        handleClosePopup();
    }
    } catch (err) {
      Swal.fire({
        title: "Error!",
        text: "حدث خطأ. الرجاء المحاولة مرة اخري لاحقًا.",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };

  const handleShowGradesPopup = () => {
    setShowGradesPopup(true);
    setShowCountPopup(false);
  };

  // Handler for showing "Deportation by Count" pop-up
  const handleShowCountPopup = () => {
    setShowCountPopup(true);
    setShowGradesPopup(false);
  };

  const handleCountSubmit = async (event) => {
    event.preventDefault();
    if (!number_of_winners || number_of_winners <= 0) {
      Swal.fire({
        title: "خطأ",
        text: "يرجى إدخال رقم صحيح أكبر من الصفر لعدد الفائزين.",
        icon: "error",
        confirmButtonText: "حسنًا",
      });
      return;
    }
    Swal.fire({
      title: "الرجاء التأكيد",
      text: "هل أنت متأكد من أنك تريد المتابعة في عملية الترحيل حسب العدد؟",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "نعم، تابع!",
      cancelButtonText: "إلغاء",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.post(
            "https://te.prize.pap.sa/api/deportation/count/client",
            {
              number_of_winners: parseInt(number_of_winners, 10),
              branchId,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );

          if (response.data.success) {
            Swal.fire("نجاح!", response.data.message, "success");
            fetchStatistics();
            handleClosePopup();
        } else {
            Swal.fire({
                title: "خطأ!",
                text: response.data.message || "حدث خطأ أثناء العملية. يرجى المحاولة مرة اخري لاحقاً.",
                icon: "error",
                confirmButtonText: "حسنًا",
            });
            handleClosePopup();
        }
        } catch (error) {
          Swal.fire({
            title: "خطأ!",
            text: "حدث خطأ أثناء العملية. يرجى المحاولة مرة اخري لاحقاً.",
            icon: "error",
            confirmButtonText: "حسنًا",
          });
        }
      }
    });
  };
  const handleNumberChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value > 0) {
      setNumberofwinners(value);
    } else {
      setNumberofwinners(""); // Reset the value if it's not valid
    }
  };

  // Function to close pop-ups
  const handleClosePopup = () => {
    setShowGradesPopup(false);
    setShowCountPopup(false);
  };

  return (
    <div className="container-fluid mt-2">
      <div className="row admin_page_header">
        <div className="admin_page_title">استعراض الترحيل</div>
        <div className="admin_page_subtitle mt-2">
          الترحيل / استعراض الترحيل
        </div>
      </div>
      <button
        variant="success"
        className="mb-3 admin_page_btn_new mx-2"
        onClick={handleShowGradesPopup}
      >
        الترحيل بالدرجات
      </button>
      <button
        variant="success"
        className="mb-3 admin_page_btn_new"
        onClick={handleShowCountPopup}
      >
        الترحيل بالعدد
      </button>

      <Modal show={showGradesPopup} onHide={handleClosePopup} centered>
        <Modal.Header closeButton>
          <Modal.Title>الترحيل بالدرجات</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmitGrades}>
            <div className="mb-3">
              <label htmlFor="branchSelect" className="form-label">
                الفرع
              </label>
              <select
                id="branchSelect"
                className="form-select"
                value={branchId}
                onChange={(e) => setBranchId(e.target.value)}
              >
                {Branches.map((branch, index) => (
                  <option key={index} value={branch.id}>
                    {branch.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="numberInput" className="form-label">
                عدد الفائزين
              </label>
              <input
                type="number"
                className="form-control"
                id="numberInput"
                value={number_of_winners}
                onChange={handleNumberChange}
              />
            </div>
            <button
              className="admin_page_btn_new"
              variant="primary"
              type="submit"
            >
              حفظ
            </button>
          </form>
        </Modal.Body>
      </Modal>

      <Modal show={showCountPopup} onHide={handleClosePopup} centered>
        <Modal.Header closeButton>
          <Modal.Title>الترحيل بالعدد</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleCountSubmit}>
            <div className="mb-3">
              <label htmlFor="branchSelectCount" className="form-label">
                الفرع
              </label>
              <select
                id="branchSelectCount"
                className="form-select"
                onChange={(e) => setBranchId(e.target.value)}
              >
                {Branches.map((branch, index) => (
                  <option key={index} value={branch.id}>
                    {branch.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="numberInput" className="form-label">
                عدد الفائزين
              </label>
              <input
                type="number"
                className="form-control"
                id="numberInput"
                value={number_of_winners}
                onChange={handleNumberChange}
              />
            </div>
            <button
              variant="primary"
              className="admin_page_btn_new"
              ctype="submit"
            >
              حفظ
            </button>
          </form>
        </Modal.Body>
      </Modal>

      {/* <div className="mb-3">
        عدد جميع المحكمين الموجودين في مرحله التقييم في المرحلة الثانيه هو:
        {referee_count}
      </div> */}

      <div className="row">
        <div className="admin_page_content col-12 row">
          <div className="mb-3"> احصائيات عن المتسابقين:</div>
          <div className="table-responsive col-12">
            <table className="table admin_table">
              <thead className="admin_table_thead">
                <tr>
                  <th>#</th>
                  <th>اسم العميل</th>
                  <th>الهاتف</th>
                  <th>الايميل</th>
                  <th>المجال</th>
                  <th>الفرع</th>
                  <th>النتيجة</th>
                </tr>
              </thead>
              <tbody>
                {statistics.length > 0 ? (
                  statistics.map((client, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        {client.user.first_name} {client.user.last_name}
                      </td>
                      <td>{client.user.phone} </td>
                      <td>{client.user.email} </td>
                      <td>{client.field.title}</td>
                      <td>{client.branch.name}</td>
                      <td>{client.final_score}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7">
                      <div className="mb-3">لا توجد نتائج</div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Deportation;
