import React, { useState, useEffect } from "react";
import axios from "axios";

import { useNavigate } from "react-router-dom";
import { logout } from "../../auth/Login";
import { Modal, Form, Button, Pagination, Table } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const Standards = () => {
  const [branches, setBranches] = useState([]);
  const [error, setError] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [standards, setStandards] = useState([]);
  const [currentBranch, setCurrentBranch] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [userType, setUserType] = useState("");
  const [employee_premission, setEmployeePremission] = useState({});
  const fetchDataEmployee = async () => {
    try {
      const user_id = localStorage.getItem("user_id");
      const response = await axios.get(
        `https://te.prize.pap.sa/api/employees/${user_id}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      setEmployeePremission(response.data.employee);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        console.log(`خطاء: ${err.response.data.error}`);
      } else {
        console.log("حدث خطأ أثناء جلب الحقول.");
      }
    }
  };

  useEffect(() => {
    const user_type = localStorage.getItem("user_type");
    const token = localStorage.getItem("token");
    if (!token) {
      logout(navigate);
    }

    if (user_type === "referee" || user_type === "client") {
      navigate(-1);
    }
    setUserType(localStorage.getItem("user_type"));
    fetchDataEmployee();

    fetchStandard();
    fetchBranches();
  }, [navigate]);

  const fetchBranches = async () => {
    try {
      const response = await axios.get("https://te.prize.pap.sa/api/branches", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      setBranches(response.data.branches);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        setError(`خطاء: ${err.response.data.error}`);
      } else {
        setError("حدث خطأ أثناء جلب الحقول.");
      }
    }
  };

  const fetchStandard = async () => {
    try {
      const response = await axios.get(
        `https://te.prize.pap.sa/api/criteria?page=${currentPage}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setStandards(response.data.standards);

      setTotalPages(response.data.last_page);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        setError(`خطاء: ${err.response.data.error}`);
      } else {
        setError("حدث خطأ أثناء جلب الحقول.");
      }
    }
  };

  useEffect(() => {
    fetchStandard();
  }, [currentPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("انتبه انت علي وشك حذف جميع التقييم ");
    if (!confirmDelete) return;

    try {
      await axios.post(
        `https://te.prize.pap.sa/api/delete/criteria/${id}`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      fetchStandard();
    } catch (err) {
      if (err.response && err.response.data.errors) {
        setError(`خطاء: ${err.response.data.errors.errors.name}`);
      } else {
        setError("حدث خطأ أثناء جلب الحقول.");
      }
    }
  };

  const NewStandards = () => {
    navigate("/admin/dashboard/standards/new");
  };

  // Handle edit branch
  const handleEdit = (branch) => {
    setCurrentBranch(branch);
    setIsEditModalOpen(true);
  };

  // Handle save branch
  const handleSave = async (id, updatedData) => {
    try {
      await axios.put(`https://te.prize.pap.sa/api/criteria/${id}`, updatedData, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      fetchStandard();
      setIsEditModalOpen(false);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.errors) {
        setErrors(err.response.data.errors);
      } else {
        setError("حدث خطأ أثناء جلب الحقول.");
      }
    }
  };

  const EditStandardModal = ({ isOpen, onClose, branch, onSave, branches }) => {
    const [name, setName] = useState("");
    const [branchId, setBranchId] = useState("");
    const [score, setScore] = useState(0);
    const [general, setGeneral] = useState(false);
    const [social_media, setSocialMedia] = useState(false);
    const [type, setType] = useState("initial_assessment");

    useEffect(() => {
      if (branch) {
        setName(branch.name);
        setBranchId(branch.branch_id);
        setScore(parseInt(branch.score, 10));
        setGeneral(branch.general === 1 || branch.general === true);
        setSocialMedia(branch.socialMedia === 1 || branch.socialMedia === true);

        setType(branch.type);
      }
    }, [branch]);

    const handleSubmit = (event) => {
      event.preventDefault();
      onSave(branch.id, {
        name,
        branch_id: general || social_media ? null : branchId,
        general,
        social_media,
        score: parseInt(score, 10),
        type,
      });
    };

    const handleBranchChange = (e) => {
      setBranchId(e.target.value);
      setGeneral(false);
      setSocialMedia(false);
    };

    const handleGeneralChange = () => {
      setGeneral(!general);
      setSocialMedia(false);
      setBranchId("");
    };

    const handleSocialMediaChange = () => {
      setSocialMedia(!social_media);
      setGeneral(false);
      setBranchId("");
    };

    if (!isOpen) return null;
    return (
      <Modal
        show={isOpen}
        onHide={onClose}
        className=""
        style={{ marginTop: "100px"}}
      >
        <Modal.Header
          closeButton
          style={{ backgroundColor: "rgb(52, 195, 143)" }}
        >
          <div className="d-flex justify-content-center">
            <Modal.Title className="w-100 text-center">
              تعديل التقييم
            </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body
          style={{
            backgroundColor: "rgb(52, 195, 143)",
            borderRadius: "0px 0px 10px 10px",
          }}
        >
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>الاسم او العنوان :</Form.Label>
              <Form.Control
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {errors.name && <div style={{ color: "red" }}>{errors.name}</div>}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>الفرع:</Form.Label>
              <Form.Select
                value={branchId}
                onChange={handleBranchChange}
                disabled={general || social_media}
                required={!general && !social_media}
              >
                <option value="">اختر الفرع</option>
                {branches.map((branch) => (
                  <option key={branch.id} value={branch.id}>
                    {branch.name}
                  </option>
                ))}
              </Form.Select>
              {errors.branch_id && (
                <div style={{ color: "red" }}>{errors.branch_id}</div>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Check
                type="checkbox"
                label="عام"
                checked={general}
                onChange={handleGeneralChange}
                disabled={social_media}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Check
                type="checkbox"
                label="سوشيال ميديا"
                checked={social_media}
                onChange={handleSocialMediaChange}
                disabled={general}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>الدرجة:</Form.Label>
              <Form.Control
                type="number"
                value={score}
                onChange={(e) => setScore(parseInt(e.target.value, 10))}
                required
              />
              {errors.score && (
                <div style={{ color: "red" }}>{errors.score}</div>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>نوع التقييم:</Form.Label>
              <div>
                <Form.Check
                  type="radio"
                  label="تقييم مبدئي"
                  name="type"
                  id="typeInitial"
                  value="initial_assessment"
                  checked={type === "initial_assessment"}
                  onChange={() => setType("initial_assessment")}
                />

                <Form.Check
                  type="radio"
                  label="تقييم نهائي"
                  name="type"
                  id="typeFinal"
                  value="final_assessment"
                  checked={type === "final_assessment"}
                  onChange={() => setType("final_assessment")}
                />
              </div>
            </Form.Group>
            <Button
              variant=""
              type="submit"
              style={{ backgroundColor: "white" }}
            >
              تعديل
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  };

  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "20px",
  };

  const thStyle = {
    padding: "15px",
    textAlign: "center",
    borderBottom: "1px solid #ddd",
    backgroundColor: "#f2f2f2",
    color: "black",
  };

  const tdStyle = {
    textAlign: "center",
    borderBottom: "1px solid #ddd",
  };

  const buttonStyle = {
    padding: "10px 20px",
    margin: "5px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  };
  const modalStyle = {
    display: "block",
    position: "fixed",
    zIndex: 1,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    overflow: "auto",
    backgroundColor: "rgba(0,0,0,0.4)",
  };

  const modalContentStyle = {
    backgroundColor: "#fefefe",
    margin: "15% auto",
    padding: "20px",
    border: "1px solid #888",
    width: "40%",
    borderRadius: "10px",
  };

  const closeModalStyle = {
    color: "#aaa",
    float: "right",
    fontSize: "28px",
    fontWeight: "bold",
    cursor: "pointer",
  };

  const formStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  };

  const labelStyle = {
    marginBottom: "10px",
    fontWeight: "bold",
  };

  const inputStyle = {
    marginBottom: "20px",
    width: "100%",
    padding: "10px",
    border: "1px solid #ddd",
    borderRadius: "5px",
  };

  return (
    <div className="container-fluid mt-2">
      <div className="row admin_page_header">
        <div className="admin_page_title">استعراض التقييمات</div>
        <div className="admin_page_subtitle mt-2">
          التقييمات / استعراض التقييمات
        </div>
      </div>
      <div className="row">
        <div className="admin_page_content col-12 row">
          <div className="col-12 row d-flex justify-content-between">
            <div className="w-100">
              {employee_premission.criteria?.store == true ? (
                <button
                  className="admin_page_btn_new"
                  variant="primary"
                  onClick={NewStandards}
                >
                  + اضافة جديد
                </button>
              ) : (
                userType !== "employee" && (
                  <button
                    className="admin_page_btn_new"
                    variant="primary"
                    onClick={NewStandards}
                  >
                    + اضافة جديد
                  </button>
                )
              )}
            </div>
            <div className="row mt-3">
              {error && <p className="text-danger">{error}</p>}
              <div className="table-responsive col-12">
                <table className="table admin_table">
                  <thead className="admin_table_thead">
                    <tr>
                      <th>#</th>
                      <th>عنوان التقييم</th>
                      <th>الفرع</th>
                      <th>الدرجة</th>
                      <th>المرحلة</th>

                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {standards.length > 0 ? (
                      standards.map((standard) => (
                        <tr key={standard.id}>
                          <td>{standard.id}</td>
                          <td>{standard.name}</td>
                          <td>
                            {standard.general == 1
                              ? "عام"
                              : standard.socialMedia == 1
                              ? "سوشيال ميديا"
                              : standard.branch_name}
                          </td>

                          <td>
                            {standard.type === "initial_assessment"
                              ? "مرحله اوليه"
                              : "نهائيه"}
                          </td>
                          <td>{standard.score}</td>
                          <td>
                            {employee_premission.criteria?.destroy == true ? (
                              <button
                                variant="danger"
                                className="admin_table_delete_btn"
                                onClick={() => handleDelete(standard.id)}
                              >
                                حذف
                              </button>
                            ) : (
                              userType !== "employee" && (
                                <button
                                  className="admin_table_delete_btn"
                                  variant="danger"
                                  onClick={() => handleDelete(standard.id)}
                                >
                                  حذف
                                </button>
                              )
                            )}
                            {employee_premission.criteria?.update == true ? (
                              <button
                                className="admin_table_edit_btn"
                                variant="secondary"
                                onClick={() => handleEdit(standard)}
                              >
                                تعديل
                              </button>
                            ) : (
                              userType !== "employee" && (
                                <button
                                  className="admin_table_edit_btn"
                                  variant="secondary"
                                  onClick={() => handleEdit(standard)}
                                >
                                  تعديل
                                </button>
                              )
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6">لا يوجد بيانات</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <Pagination>
              {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                (page) => (
                  <Pagination.Item
                    key={page}
                    active={page === currentPage}
                    onClick={() => handlePageChange(page)}
                  >
                    {page}
                  </Pagination.Item>
                )
              )}
            </Pagination>
          </div>
        </div>
      </div>
      {currentBranch && (
        <EditStandardModal
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          branch={currentBranch}
          onSave={handleSave}
          branches={branches}
        />
      )}
    </div>
  );
};
export default Standards;
