import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './styles.css';
import { logout } from '../../auth/Login';

const NewEmployeeRule = () => {
    const navigate = useNavigate();
    const [employees, setEmployees] = useState([]);
    const [selectedEmployeeId, setSelectedEmployeeId] = useState('');
    const [validationErrors, setValidationErrors] = useState({});
    const [error, setError] = useState('');
    const [formData, setFormData] = useState({
      fields: {
        index: false,
        store: false,
        update: false,
        destroy: false,
      },
      branches: {
        index: false,
        store: false,
        update: false,
        destroy: false,
      },
      criteria: {
        index: false,
        store: false,
        update: false,
        destroy: false,
      },
      clients: {
        index: false,
        store: false,
        update: false,
        destroy: false,
      },
      referees: {
        index: false,
        store: false,
        update: false,
        destroy: false,
      },
      employees: {
        index: false,
        permission: false,
        store: false,
        update: false,
        destroy: false,
      },
      support_times: {
        index: false,
        store: false,
        show: false,
        destroy: false,
      },
      works: {
        index: false,
        store: false,
        update: false,
        destroy: false,
      },
      commerces: {
        index: false,
        store: false,
        update: false,
        destroy: false,
      },
      social_accounts: {
        index: false,
        store: false,
        update: false,
        destroy: false,
      },
      archives: {
        index: false,
        show: false,
        store: false,
        update: false,
        destroy: false,
      },
      client_evaluations: {
        index: false,
        store: false,
        update: false,
        destroy: false,
      },
      ads: {
        index: false,
        store: false,
        update: false,
        destroy: false,
      },
      reports: {
        action: false,
      },
      deportations: {
        action: false,
      },

    });

    useEffect(() => {
        const token = localStorage.getItem('token');
        const user_type = localStorage.getItem('user_type');
        if (!token ) {

            logout(navigate);
        }
        
        if ( user_type === "referee" || user_type === "client" ) {
            navigate(-1);
        }

        fetchEmployees(); // Fetch all employees
    }, [navigate]);

    const fetchEmployees = async () => {
        try {
            const response = await axios.get('https://te.prize.pap.sa/api/employees', {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setEmployees(response.data.employees);
        } catch (err) {
            if (err.response && err.response.data && err.response.data.error) {
                setError(`خطاء: ${err.response.data.error}`);
            } else {
                setError('حدث خطأ أثناء جلب الحقول.');
            }
        }
    };

    
    const fetchData = async (employeeId) => {
        try {
           
                const response = await axios.get(`https://te.prize.pap.sa/api/employee/${employeeId}`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                });
    
                const employee = response.data.employee;
    
                const updateField = (fieldData) => {
                    if (typeof fieldData === 'string') {
                        const parsedData = JSON.parse(fieldData);
                        return Object.keys(parsedData).reduce((acc, key) => {
                            acc[key] = parsedData[key] === "true"; 
                            return acc;
                        }, {});
                    }
                    return fieldData; 
                };
    
                setFormData({
                    ...employee.user,
                    fields: updateField(employee.fields),
                    branches: updateField(employee.branches),
                    criteria: updateField(employee.criteria),
                    clients: updateField(employee.clients),
                    referees: updateField(employee.referees),
                    employees: updateField(employee.employees),
                    support_times: updateField(employee.support_times),
                    works: updateField(employee.works),
                    commerces: updateField(employee.commerces),
                    social_accounts: updateField(employee.social_accounts),
                    archives: updateField(employee.archives),
                    client_evaluations: updateField(employee.client_evaluations),
                    ads: updateField(employee.ads),
                    reports: updateField(employee.reports),
                    deportations: updateField(employee.deportations),
                });
        
        } catch (err) {
            if (err.response && err.response.data && err.response.data.error) {
                setError(`خطاء: ${err.response.data.error}`);
            } else {
                setError('حدث خطأ أثناء جلب الحقول.');
            }
        }
    };

    const handleEmployeeChange = (e) => {
        setSelectedEmployeeId(e.target.value);
        fetchData(e.target.value);
    };

   // ... (previous code)

   const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    if (type === 'checkbox') {
        const [parentKey, childKey] = name.split('.');
        setFormData({
            ...formData,
            [parentKey]: {
                ...formData[parentKey],
                [childKey]: checked
            }
        });
    } else {
        setFormData({ ...formData, [name]: value });
    }
};

const handleSubmit = async (event) => {
    event.preventDefault();
    try {
        const token = localStorage.getItem('token');
        await axios.put(`https://te.prize.pap.sa/api/employee/${selectedEmployeeId}`, formData, {
            headers: { Authorization: `Bearer ${token}` }
        });
        navigate('/admin/dashboard/employees'); // Redirect after successful update
    } catch (err) {
        if (err.response && err.response.data.errors) {
            setValidationErrors(err.response.data.errors);
        } else {
            setError('Failed to update employee. Please check the details.');
        }
    }
};



const renderToggleFields = (parentField, fields) => {
  return Object.keys(fields).map((field, index) => (
    <div className="mb-3 d-flex align-items-center justify-content-between" key={index}>
      <label className="form-label">{`${fieldMapping[field]}:`}</label>
      <div className="form-check form-switch">
        <input 
          className="form-check-input"
          type="checkbox"
          id={`flexSwitchCheck${parentField}${index}`}
          name={`${parentField}.${field}`}
          checked={formData[parentField][field]}
          onChange={handleChange}
        />
        <label className="form-check-label" htmlFor={`flexSwitchCheck${parentField}${index}`}></label>
      </div>
    </div>
  ));
};



const fieldMapping = {
  index: "عرض",
  show: "عرض تفاصيل",
  store: "إضافة",
  update: "تحديث",
  destroy: "حذف",
  permission: "الصلاحيات",
  action: 'امكانية التفاعل ',
};


    return (
      <div className="container-fluid mt-2">
        <div className="row admin_page_header">
          <div className="admin_page_title">  صلاحيات الموظف </div>
          <div className="admin_page_subtitle mt-2">
            الموظفين /  صلاحيات الموظفين
          </div>
        </div>
        {error && <p style={{ color: "red", margin: "5px" }}>{error}</p>}

        <div className="admin_page_content col-12 row mb-4">
          <div className="mb-3">
            <label htmlFor="employeeSelect" className="form-label">
              اختيار الموظف
            </label>
            <select
              className="form-select"
              id="employeeSelect"
              value={selectedEmployeeId}
              onChange={handleEmployeeChange}
            >
              <option value="">اختر اسم الموظف</option>
              {employees.map((employee) => (
                <option key={employee.id} value={employee.id}>
                  {employee.user.first_name} {employee.user.last_name}
                </option>
              ))}
            </select>
          </div>

          <div className="p-4">
            <form onSubmit={handleSubmit}>
              <div className="row mt-5">
                <div className="table-responsive">
                  <table className="table employee-custom-table">
                    <thead>
                      <tr>
                        <th className="admin_rules_thead">العناصر</th>
                        <th className="admin_rules_thead">تفاصيل الصلاحيات</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>المجال:</td>
                        <td>
                          <div class="admi_toggle_switch">
                            {renderToggleFields("fields", formData.fields)}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>الافرع:</td>
                        <td>
                          <div class="admi_toggle_switch">
                            {renderToggleFields("branches", formData.branches)}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>التقييمات:</td>
                        <td>
                          <div class="admi_toggle_switch">
                            {renderToggleFields("criteria", formData.criteria)}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>المتسابقين:</td>
                        <td>
                          <div class="admi_toggle_switch">
                            {renderToggleFields("clients", formData.clients)}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>المحكمين:</td>
                        <td>
                          <div class="admi_toggle_switch">
                            {renderToggleFields("referees", formData.referees)}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>الموظفين:</td>
                        <td>
                          <div class="admi_toggle_switch">
                            {renderToggleFields(
                              "employees",
                              formData.employees
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>الدعم الفني:</td>
                        <td>
                          <div class="admi_toggle_switch">
                            {renderToggleFields(
                              "support_times",
                              formData.support_times
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>العمل:</td>
                        <td>
                          <div class="admi_toggle_switch">
                            {renderToggleFields("works", formData.works)}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>التجاري:</td>
                        <td>
                          <div class="admi_toggle_switch">
                            {renderToggleFields(
                              "commerces",
                              formData.commerces
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>سوشيال ميديا:</td>
                        <td>
                          <div class="admi_toggle_switch">
                            {renderToggleFields(
                              "social_accounts",
                              formData.social_accounts
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>الارشيف:</td>
                        <td>
                          <div class="admi_toggle_switch">
                            {renderToggleFields("archives", formData.archives)}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>التقييم:</td>
                        <td>
                          <div class="admi_toggle_switch">
                            {renderToggleFields(
                              "client_evaluations",
                              formData.client_evaluations
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>الاعلانات:</td>
                        <td>
                          <div class="admi_toggle_switch">
                            {renderToggleFields("ads", formData.ads)}
                          </div>
                        </td>
                      </tr>
                      <tr>
                    <td>التقارير:</td>
                    <td>
                      <div class="admi_toggle_switch">
                        {renderToggleFields("reports", formData.reports)}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>الترحيل:</td>
                    <td>
                      <div class="admi_toggle_switch">
                        {renderToggleFields("deportations", formData.deportations)}
                      </div>
                    </td>
                  </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row">
                <div className="d-flex justify-content-center mb-5">
                  <button
                    type="submit"
                    className="user_info_btn_produce mt-3 p-2"
                  >
                    حفظ
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
};

export default NewEmployeeRule;
