import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { logout } from "../auth/Login";
import CreatableSelect from "react-select/creatable";
import REGIONS from "../../common/REGIONS";
import CITIES from "../../common/CITIES";

import "./referee.css";

const EvaluationsCompleted = () => {
  const [clients, setClients] = useState([]);
  const [referee, setReferee] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);

  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("personal");

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [showReasonInput, setShowReasonInput] = useState(false);
  const [wetsite, setWrbsite] = useState([]);
  const [files, setFiles] = useState([]);
  const [videos, setVideos] = useState([]);

  const [reason, setReason] = useState("");
  const [filteredCities, setFilteredCities] = useState([]);

  const [currentRefereeType, setCurrentRefereeType] = useState("");

  const reasonRef = useRef(null);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const reasonValue = reasonRef.current.value;
    setReason(reasonValue);
  };

  const token = localStorage.getItem("token");
  const user_type = localStorage.getItem("user_type");

  useEffect(() => {
    if (!token) {
      logout(navigate);
    }
    if (user_type !== "referee") {
      navigate(-1);
    }

    fetchClientsAndReferees();
    if (reason) {
      handleAccept("reject");
    }
  }, [navigate, reason]);

  const fetchClientsAndReferees = () => {
    const url = "https://te.prize.pap.sa/api/completed/sorting/referee";
    const refereeId = localStorage.getItem("user_id");

    let requestConfig = {
      method: "post",
      url: url,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: refereeId ? { referee_id: refereeId } : null,
    };

    axios(requestConfig)
      .then((response) => {
        setReferee(response.data.referee);
        if (response.data.client) {
          setClients(response.data.client);
          const filesArray = JSON.parse(
            response.data.client.commerce.files_document
          );
          setFiles(filesArray);
          const videosArray = JSON.parse(response.data.client.commerce.videos);
          setVideos(videosArray);
          const websiteArray = JSON.parse(
            response.data.client.commerce.website_sale
          );
          setWrbsite(websiteArray);
        } else {
          console.error("Error fetching data:", response.data.error);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleReviewClick = (client, refereeType) => {
    console.log(refereeType, client);
    setSelectedClient(client);
    setCurrentRefereeType(refereeType);
    setIsEditModalOpen(true);
    if (client?.user.region) {
      const cities = CITIES.filter(
        (city) => city.region_id === parseInt(client?.user.region)
      );
      setFilteredCities(cities);
    }
    if (
      refereeType === "grade_references" ||
      refereeType === "social_media_references" ||
      refereeType === "final_grade_referee" ||
      refereeType === "final_social_media_referee"
    ) {
      fetchCriteria(client.id, referee ? referee.id : null);
    }
  };

  const closeModal = () => {
    setIsEditModalOpen(false);
    setShowReasonInput(false);
  };

  const handleAccept = (type) => {
    const data = {
      action: type,
      clientId: selectedClient ? selectedClient?.id : null,
      refereeId: referee ? referee.id : null,
      reason: type === "reject" ? reason : null,
    };

    axios({
      method: "post",
      url: "https://te.prize.pap.sa/api/completed/sorting/referee/action",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response) => {
        console.log(response.data.success);
        if (response.data.data) {
          console.log(response.data.data);
        }
        fetchClientsAndReferees();
        setReason("");
        closeModal();
      })
      .catch((error) => {
        if (error.response) {
          const errors = error.response.data.errors;
          let errorMessage = "An unknown error occurred";

          if (errors && Array.isArray(errors)) {
            errorMessage = errors.join("\n");
          }

          console.log("Error:", errorMessage);
          alert(errorMessage);
        } else {
          console.error("Error:", error.message);
        }
      });
  };

  const [criteria, setCriteria] = useState([]);

  const fetchCriteria = async (clientId, refereeId) => {
    try {
      const response = await axios.post(
        "https://te.prize.pap.sa/api/completed/degree/referee/criterion",
        {
          clientId: clientId,
          refereeId: refereeId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCriteria(response.data.criterions);
    } catch (error) {
      console.error("Error fetching criteria:", error);
    }
  };

  const submitGrades = async (event) => {
    event.preventDefault();

    const criteriaData = criteria.map((criterion) => ({
      criterionId: criterion.id,
      score: parseInt(
        document.getElementById(`criterion-${criterion.id}`).value,
        10
      ),
    }));

    try {
      const response = await axios.post(
        "https://te.prize.pap.sa/api/completed/degree/referee/evaluation",
        {
          refereeId: referee.id,
          clientId: selectedClient?.id,
          criteria: criteriaData,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      closeModal();
      fetchClientsAndReferees();
      console.log(response.data.success);
    } catch (error) {
      console.error("Error submitting grades:", error);
    }
  };

  const submitSocial_media = async (event) => {
    event.preventDefault();

    const criteriaData = criteria.map((criterion) => ({
      criterionId: criterion.id,
      score: parseInt(
        document.getElementById(`criterion-${criterion.id}`).value,
        10
      ),
    }));

    try {
      const response = await axios.post(
        "https://te.prize.pap.sa/api/completed/degree/referee/social-media/evaluation",
        {
          refereeId: referee.id,
          clientId: selectedClient?.id,
          criteria: criteriaData,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      closeModal();
      fetchClientsAndReferees();
    } catch (error) {
      console.error("Error submitting social media:", error);
    }
  };

  const submitFinal_evaluation = async (event) => {
    event.preventDefault();

    const criteriaData = criteria.map((criterion) => ({
      criterionId: criterion.id,
      score: parseInt(
        document.getElementById(`criterion-${criterion.id}`).value,
        10
      ),
    }));

    try {
      await axios.post(
        "https://te.prize.pap.sa/api/completed/degree/referee/final/evaluation",
        {
          refereeId: referee.id,
          clientId: selectedClient?.id,
          criteria: criteriaData,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      closeModal();
      fetchClientsAndReferees();
    } catch (error) {
      console.error("Error submitting social media:", error);
    }
  };

  const submitFinal_social_evaluation = async (event) => {
    event.preventDefault();

    const criteriaData = criteria.map((criterion) => ({
      criterionId: criterion.id,
      score: parseInt(
        document.getElementById(`criterion-${criterion.id}`).value,
        10
      ),
    }));

    try {
      await axios.post(
        "https://te.prize.pap.sa/api/completed/degree/referee/final/social-media/evaluation",
        {
          refereeId: referee.id,
          clientId: selectedClient?.id,
          criteria: criteriaData,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      closeModal();
      fetchClientsAndReferees();
    } catch (error) {
      console.error("Error submitting social media:", error);
    }
  };

  const FieldModal = ({ isOpen, onClose, field }) => {
    if (!isOpen) return null;

    const renderActionContent = () => {
      switch (currentRefereeType) {
        case "sort_references":
          return (
            <div className="row">
              <div className="d-flex justify-content-center">
                <div className="form-group">
                  <button
                    className="btn btn-success mx-1"
                    onClick={() => handleAccept("accept")}
                  >
                    قبول
                  </button>
                </div>

                <div className="form-group mx-1 ">
                  <button
                    className="btn btn-danger"
                    onClick={() => setShowReasonInput(!showReasonInput)}
                  >
                    رفض
                  </button>
                </div>
              </div>

              {isEditModalOpen && showReasonInput && (
                <form onSubmit={handleFormSubmit}>
                  <textarea
                    rows="3"
                    className="form-control"
                    ref={reasonRef}
                  ></textarea>

                  <button type="submit" className="refree_degree_final">
                    ارسال
                  </button>
                </form>
              )}
            </div>
          );

        case "grade_references":
          const groupedCriteria = criteria.reduce((acc, criterion) => {
            acc[criterion.branch_name] = acc[criterion.branch_name] || [];
            acc[criterion.branch_name].push(criterion);
            return acc;
          }, {});

          const validateScore = (event, maxScore) => {
            const value = parseInt(event.target.value, 10);
            if (value > maxScore) {
              event.target.value = maxScore;
            }
          };

          return (
            <div className="container">
              
                {Object.keys(groupedCriteria).map((branchName) => (
                  <div key={branchName} className="mb-4">
                    <div className="mb-4 mt-3 referr_title_final">
                      {branchName}
                    </div>
                    {groupedCriteria[branchName].map((criterion) => (
                      <div className="row d-flex align-items-center mb-5">
                        <div className="col-md-6 mb-3" key={criterion.id}>
                          <div className="form-floating">
                            <input
                              type="number"
                              className="form-control"
                              min="0"
                              max={criterion.score}
                              id={`criterion-${criterion.id}`}
                              onChange={(e) =>
                                validateScore(e, criterion.score)
                              }
                              defaultValue={criterion.evaluation || ""}
                            />
                          </div>
                        </div>
                        <div className="score_and_text col-md-6">
                          <div
                            htmlFor={`criterion-${criterion.id}`}
                            className="referee_name_user"
                          >
                            اسم التقييم : {criterion.name}
                          </div>
                          <div
                            htmlFor={`criterion-${criterion.id}`}
                            className="referee_name_user"
                          >
                            الدرجة النهائية : {criterion.score}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
               
            </div>
          );

        case "social_media_references":
          const social_mediaCriteri = criteria.reduce((acc, criterion) => {
            acc[criterion.branch_name] = acc[criterion.branch_name] || [];
            acc[criterion.branch_name].push(criterion);
            return acc;
          }, {});

          const validateScoresSocial_media = (event, maxScore) => {
            const value = parseInt(event.target.value, 10);
            if (value > maxScore) {
              event.target.value = maxScore;
            }
          };

          return (
            <div className="container">
             
                {Object.keys(social_mediaCriteri).map((branchName) => (
                  <div key={branchName} className="mb-4">
                    <div className="mb-4 mt-3 referr_title_final">
                      {branchName}
                    </div>
                    {social_mediaCriteri[branchName].map((criterion) => (
                      <div className="row d-flex align-items-center mb-5">
                        <div className="col-md-6 mb-3" key={criterion.id}>
                          <div className="form-floating">
                            <input
                              type="number"
                              className="form-control"
                              min="0"
                              max={criterion.score}
                              id={`criterion-${criterion.id}`}
                              onChange={(e) =>
                                validateScoresSocial_media(e, criterion.score)
                              }
                              defaultValue={criterion.evaluation || ""}
                            />
                          </div>
                        </div>
                        <div className="score_and_text col-md-6">
                          <div
                            htmlFor={`criterion-${criterion.id}`}
                            className="referee_name_user"
                          >
                            اسم التقييم : {criterion.name}
                          </div>
                          <div
                            htmlFor={`criterion-${criterion.id}`}
                            className="referee_name_user"
                          >
                            الدرجة النهائية : {criterion.score}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
               
            </div>
          );

        case "final_grade_referee":
          const final_evaluationCriteri = criteria.reduce((acc, criterion) => {
            acc[criterion.branch_name] = acc[criterion.branch_name] || [];
            acc[criterion.branch_name].push(criterion);
            return acc;
          }, {});

          const validateScoresFinal_evaluation = (event, maxScore) => {
            const value = parseInt(event.target.value, 10);
            if (value > maxScore) {
              event.target.value = maxScore;
            }
          };

          return (
            <div className="container">
             
                {Object.keys(final_evaluationCriteri).map((branchName) => (
                  <div key={branchName} className="mb-4 row">
                    <div className="mb-4 mt-3 referr_title_final">
                      {branchName}
                    </div>

                    {final_evaluationCriteri[branchName].map((criterion) => (
                      <div className="row d-flex align-items-center mb-5">
                        <div className="col-md-6 mb-3" key={criterion.id}>
                          <div className="form-floating">
                            <input
                              type="number"
                              className="form-control"
                              min="0"
                              max={criterion.score}
                              id={`criterion-${criterion.id}`}
                              onChange={(e) =>
                                validateScoresFinal_evaluation(
                                  e,
                                  criterion.score
                                )
                              }
                              defaultValue={criterion.evaluation || ""}
                            />
                          </div>
                        </div>
                        <div className="score_and_text col-md-6">
                          <div
                            htmlFor={`criterion-${criterion.id}`}
                            className="referee_name_user"
                          >
                            اسم التقييم : {criterion.name}
                          </div>
                          <div
                            htmlFor={`criterion-${criterion.id}`}
                            className="referee_name_user"
                          >
                            الدرجة النهائية : {criterion.score}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
               
            </div>
          );

        case "final_social_media_referee":
          const final_social_media_referee = criteria.reduce(
            (acc, criterion) => {
              acc[criterion.branch_name] = acc[criterion.branch_name] || [];
              acc[criterion.branch_name].push(criterion);
              return acc;
            },
            {}
          );

          const validateScoresFinal_social_evaluation = (event, maxScore) => {
            const value = parseInt(event.target.value, 10);
            if (value > maxScore) {
              event.target.value = maxScore;
            }
          };

          return (
            <div className="container">
             
                {Object.keys(final_social_media_referee).map((branchName) => (
                  <div key={branchName} className="mb-4">
                    <div className="mb-4 mt-3 referr_title_final">
                      {branchName}
                    </div>
                    {final_social_media_referee[branchName].map((criterion) => (
                      <div className="row d-flex align-items-center mb-5">
                        <div className="col-md-6 mb-3" key={criterion.id}>
                          <div className="form-floating">
                            <input
                              type="number"
                              className="form-control"
                              min="0"
                              max={criterion.score}
                              id={`criterion-${criterion.id}`}
                              onChange={(e) =>
                                validateScoresFinal_social_evaluation(
                                  e,
                                  criterion.score
                                )
                              }
                              defaultValue={criterion.evaluation || ""}
                            />
                          </div>
                        </div>
                        <div className="score_and_text col-md-6">
                          <div
                            htmlFor={`criterion-${criterion.id}`}
                            className="referee_name_user"
                          >
                            اسم التقييم : {criterion.name}
                          </div>
                          <div
                            htmlFor={`criterion-${criterion.id}`}
                            className="referee_name_user"
                          >
                            الدرجة النهائية : {criterion.score}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
               
            </div>
          );

        default:
          return null;
      }
    };
    return (
      <div
        className={isEditModalOpen ? "modal d-block" : "modal"}
        tabIndex="-1"
        style={{ display: isEditModalOpen ? "block" : "none" }}
      >
        <div className="modal-dialog modal-lg modal-dialog-scrollable">
          <div className="modal-content" style={{ marginTop: "11vh" }}>
            <div className="modal-header">
              <div className="col-auto">
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeModal}
                ></button>
              </div>
            </div>

            <div className="modal-body">
              <ul className="nav nav-tabs referee_model_nav p-4  d-flex justify-content-center">
                <div className="w-100 text-center mb-3 text-white">
                  الجائزة - تقييم المشترك
                </div>
                <li className="nav-item">
                  <button
                    className={`nav-link refree_nav_item ${activeTab === "personal" ? "active" : ""
                      }`}
                    onClick={() => setActiveTab("personal")}
                  >
                    الملف الشخصي
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link refree_nav_item ${activeTab === "company" ? "active" : ""
                      }`}
                    onClick={() => setActiveTab("company")}
                  >
                    الملف التجاري{" "}
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link refree_nav_item ${activeTab === "action" ? "active" : ""
                      }`}
                    onClick={() => setActiveTab("action")}
                  >
                    التقييم
                  </button>
                </li>
              </ul>

              {activeTab === "personal" && (
                <div className="mt-3">
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label>الرقم:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedClient?.user?.id ?? ""}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label>الخطوة:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedClient?.step?.name}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label>الاسم الاول :</label>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedClient?.user?.first_name}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label>اسم العائلة:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedClient?.user?.last_name}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label>اسم الأب:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedClient?.user?.father_name}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label>اسم الجد:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedClient?.user?.grandfather_name}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>الهويه الوطنيه:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedClient?.user?.ssn}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label>رقم الجوال:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedClient?.user?.phone}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label>رقم الجوال 2:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedClient?.user?.phone2 || ""}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label>البريد الالكتروني:</label>
                        <input
                          type="email"
                          className="form-control"
                          value={selectedClient?.user?.email}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label>الجنس:</label>
                        <select
                          className="form-control"
                          id="gender"
                          value={selectedClient?.user?.gender}
                          disabled={true}
                        >
                          <option value="" disabled selected>
                            حدد
                          </option>
                          <option value="male">ذكر</option>
                          <option value="female">أنثى</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label>الجنسية:</label>
                        <select
                          className="form-control"
                          id="nationality"
                          value={selectedClient?.user?.nationality}
                          disabled={true}
                        >
                          <option value="" disabled selected>
                            حدد الجنسية
                          </option>
                          <option value="sa">السعودية</option>
                          <option value="son_or_dagauter_home">
                            ابن / ابنة مواطنة
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label>تاريخ الميلاد:</label>
                        <input
                          type="date"
                          className="form-control"
                          value={selectedClient?.user?.birth_date}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label>المؤهل:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedClient?.qualification?.name}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label>المنطقة:</label>
                        <select
                          className="form-control"
                          id="region"
                          value={selectedClient?.user?.region}
                          disabled={true} // Disable the select element
                        >
                          <option value="" disabled>
                            اختر المنطقة
                          </option>
                          {REGIONS.map((region) => (
                            <option
                              key={region.region_id}
                              value={region.region_id}
                            >
                              {region.name_ar}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label>المدينة:</label>
                        <select
                          className="form-control"
                          id="city"
                          value={selectedClient?.user?.city}
                          disabled={true}
                        >
                          <option value="" disabled selected>
                            اختر المدينة
                          </option>
                          {filteredCities.map((city) => (
                            <option key={city.city_id} value={city.city_id}>
                              {city.name_ar}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">العمل</div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label> المهنة:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedClient?.work?.occupation}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label>جهة العمل الحالية:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedClient?.work?.current_workplace}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>جهه العمل السابقه:</label>
                        <CreatableSelect
                          isMulti
                          value={
                            selectedClient &&
                            selectedClient?.work &&
                            selectedClient?.work
                              ?.previous_workplaces /* ...parse JSON logic... */
                          }
                          isClearable={true}
                          isDisabled={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label>سنوات الخبره:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedClient?.work?.years_of_experience}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label>الجهة الدراسية :</label>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedClient?.work?.academic}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label> المهارات:</label>
                        <CreatableSelect
                          isMulti
                          value={
                            selectedClient &&
                            selectedClient?.work &&
                            selectedClient?.work
                              ?.skills /* ...parse JSON logic... */
                          }
                          placeholder="اكتب اسم مهارة"
                          isClearable={true}
                          isDisabled={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label>مستوي الدراسي:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedClient?.work?.academic_level}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label> المنصب :</label>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedClient?.work?.position}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label> نبذة مختصرة عن نفسك:</label>
                        <textarea
                          type="text"
                          className="form-control"
                          value={selectedClient?.work?.personal_summary}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 mt-3">التواصل الشحصي</div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label> تويتر:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedClient?.social_account?.twitter}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label> سناب شات:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedClient?.social_account?.snapchat}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label> الانستغرام:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedClient?.social_account?.instagram}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label> تليجرام:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedClient?.social_account?.telegram}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label> واتس اب:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedClient?.social_account?.whatsapp}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label> تيك توك:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedClient?.social_account?.tiktok}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label> يوتيوب:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedClient?.social_account?.youtube}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label> لينكد ان:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedClient?.social_account?.linkendin}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label> اخري:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedClient?.social_account?.other}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {activeTab === "company" && (
                <div className="mt-3">
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label>المجال :</label>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedClient?.field.title}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label>اسم الفرع:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedClient?.branch.name}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label> نوع وثيقة العمل:</label>
                        <select
                          className="form-control"
                          id="type_work_documnet"
                          value={
                            selectedClient &&
                            selectedClient?.commerce &&
                            selectedClient?.commerce?.type_work_documnet
                          }
                          readOnly
                        >
                          <option value="">حدد</option>
                          <option value="commerce">سجل تجاري</option>
                          <option value="freelancer">وثيقة عمل حر</option>
                          <option value="production_family">
                            رخصة الاسر المنتجة
                          </option>
                          <option value="information">معروف</option>
                          <option value="not_found">لا يوجد</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label> رقم وثيقة العمل:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedClient?.commerce?.number_documnet}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label> تايخ بدءالنشاط :</label>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedClient?.commerce?.start_documnet}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label> المدة منذ بدء النشاط :</label>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedClient?.commerce?.duration_activity}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label> اسم النشاط :</label>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedClient?.commerce?.name_activity}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label> تايخ انتهاء الوثيقة :</label>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedClient?.commerce?.end_documnet}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label> المؤهلات :</label>
                        <CreatableSelect
                          isMulti
                          value={
                            selectedClient &&
                            selectedClient?.commerce &&
                            selectedClient?.commerce
                              ?.courses_qualification /* ...parse JSON logic... */
                          }
                          isClearable={true}
                          isDisabled={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label> المنصة المستخدمة للبيع:</label>
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>الرقم</th>
                              <th>اسم المنصة</th>
                              <th>رابط المنصة</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Array.isArray(wetsite) &&
                              wetsite.map((webSite) => (
                                <tr key={webSite.id}>
                                  <td>{webSite.id}</td>
                                  <td>
                                    <select
                                      className="form-control"
                                      id="website_sale"
                                      value={webSite.key}
                                      readOnly
                                    >
                                      <option value="" disabled>
                                        حدد منصة
                                      </option>
                                      <option value="special_commerce">
                                        متجر الكتروني خاص
                                      </option>
                                      <option value="instegram">
                                        حساب انستغرام
                                      </option>
                                      <option value="twitter">
                                        حساب تويتر
                                      </option>
                                      <option value="other">اخرى</option>
                                      <option value="not_found">لا يوجد</option>
                                    </select>
                                  </td>
                                  <td>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={webSite.description}
                                        readOnly
                                        placeholder="ادخل وصف"
                                      />
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label> الملفات و المستندات:</label>
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>الرقم </th>
                              <th>اسم الملف</th>
                              <th>وصف الملف</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Array.isArray(files) &&
                              files.map((file) => (
                                <tr key={file.id}>
                                  <td>{file.id}</td>
                                  <td>
                                    <a
                                      className="user_work_file"
                                      href={
                                        "https://pub-834d7d165e3e40ecb0b474482259582d.r2.dev/" +
                                        file.path
                                      }
                                      target="_blank"
                                    >
                                      {file.name}
                                    </a>
                                  </td>
                                  <td>
                                    <div className="form-group mt-2">
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={file.description}
                                        placeholder="ادخل وصف"
                                      />
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label> روابط الفيديو:</label>
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>الرقم </th>
                              <th>اسم الفيديو</th>
                              <th>وصف الفيديو</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Array.isArray(videos) &&
                              videos.map((video) => (
                                <tr key={video.id}>
                                  <td>{video.id}</td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={video.name}
                                      placeholder="ادخل رابط الفيديو"
                                    />
                                  </td>
                                  <td>
                                    <div className="form-group ">
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={video.description}
                                        placeholder="ادخل وصف"
                                      />
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label> مؤسسه خيريه:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedClient?.commerce?.charity}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label>
                          {" "}
                          نبذة تفصيلية عن الفكرة او النشاط الخاص بك :
                        </label>
                        <textarea
                          type="text"
                          className="form-control"
                          value={selectedClient?.commerce?.summary}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <div className="form-group">
                          <label> تويتر:</label>
                          <input
                            type="text"
                            className="form-control"
                            value={
                              selectedClient?.commerce_social_account?.twitter
                            }
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="form-group">
                          <label> سناب شات:</label>
                          <input
                            type="text"
                            className="form-control"
                            value={
                              selectedClient?.commerce_social_account?.snapchat
                            }
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <div className="form-group">
                          <label> الانستغرام:</label>
                          <input
                            type="text"
                            className="form-control"
                            value={
                              selectedClient?.commerce_social_account?.instagram
                            }
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="form-group">
                          <label> تليجرام:</label>
                          <input
                            type="text"
                            className="form-control"
                            value={
                              selectedClient?.commerce_social_account?.telegram
                            }
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <div className="form-group">
                          <label> واتس اب:</label>
                          <input
                            type="text"
                            className="form-control"
                            value={
                              selectedClient?.commerce_social_account?.whatsapp
                            }
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="form-group">
                          <label> تيك توك:</label>
                          <input
                            type="text"
                            className="form-control"
                            value={
                              selectedClient?.commerce_social_account?.tiktok
                            }
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <div className="form-group">
                          <label> يوتيوب:</label>
                          <input
                            type="text"
                            className="form-control"
                            value={
                              selectedClient?.commerce_social_account?.youtube
                            }
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="form-group">
                          <label> لينكد ان:</label>
                          <input
                            type="text"
                            className="form-control"
                            value={
                              selectedClient?.commerce_social_account?.linkendin
                            }
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <div className="form-group">
                          <label> موقع الكتروني:</label>
                          <input
                            type="text"
                            className="form-control"
                            value={
                              selectedClient?.commerce_social_account?.webSite
                            }
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="form-group">
                          <label> اخري:</label>
                          <input
                            type="text"
                            className="form-control"
                            value={
                              selectedClient?.commerce_social_account?.other
                            }
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              )}
              {activeTab === "action" && (
                <div
                  className="modal-body"
                  style={{ maxHeight: "60vh", overflowY: "auto" }}
                >
                  {renderActionContent()}
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="refree_btn_close"
                onClick={closeModal}
              >
                اغلاق
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="container-fluid mt-2">
      <div className="row admin_page_header">
        <div className="admin_page_title">استعراض المرشحين</div>
        <div className="admin_page_subtitle mt-2">
          المرشحين / استعراض المرشحين
        </div>
      </div>
      <div className="admin_page_content col-12 row">
        <div className="col-12 row d-flex justify-content-between">
          <div className="col-md-4">
            <div className="search-container">
              <input
                type="text"
                placeholder=" ابحث عن الطلب...."
                //   value={searchQuery}
                //   onChange={(e) => setSearchQuery(e.target.value)}
                className="search-input admin_candi_search_input form-control"
              />
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="table-responsive col-12">
            <table className="table admin_table">
              <thead className="admin_table_thead">
                <tr>
                  <th>#</th>
                  <th>الاسم الاول</th>
                  <th>اسم العائلة</th>
                  <th>رقم الهوية</th>
                  <th>رقم الجوال</th>
                  <th>البريد الالكتروني</th>
                  <th> التقييم</th>
                  <th>الإجراء الأساسي</th>
                </tr>
              </thead>
              <tbody>
                {clients.length > 0 ? (
                  clients.map((client) => (
                    <tr key={client.id}>
                      <td>{client.user.id}</td>
                      <td>{client.user.first_name}</td>
                      <td>{client.user.last_name}</td>
                      <td>{client.user.ssn}</td>
                      <td>{client.user.phone}</td>
                      <td>{client.user.email}</td>
                      <td>
                        {referee.referee_type === "sort_references" ? (
                          <span
                            className="text-warning text-wrap"
                            style={{ fontSize: "12px" }}
                          >
                            لم تقم بالمراجعة بعد
                          </span>
                        ) : 
                          <span
                            className="text-success text-wrap"
                            style={{ fontSize: "12px" }}
                          >
                            تم التقييم
                          </span>
                       
                        }
                      </td>

                      <td>
                        <button
                          className="refree_btn_rev"
                          onClick={() =>
                            handleReviewClick(client, referee.referee_type)
                          }
                        >
                          مراجعة
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" className="text-center">
                      لا يوجد متسابقين.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {selectedClient && (
        <FieldModal
          isOpen={isEditModalOpen}
          onClose={closeModal}
          field={selectedClient}
          isEditMode={true}
        />
      )}
    </div>
  );
};

export default EvaluationsCompleted;
