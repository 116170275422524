import React, { useState, useEffect } from "react";
import axios from "axios";
import "./arbitiators.css";
import { useNavigate, useLocation } from "react-router-dom";
import { logout } from "../../auth/Login";
import REGIONS from "../../../common/REGIONS";
import CITIES from "../../../common/CITIES";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
const EditArbitrator = () => {
  const location = useLocation();
  const { refereeId } = location.state;
  const [validationErrors, setValidationErrors] = useState({
    first_name: null,
    last_name: null,
    ssn: null,
    phone: null,
    phone2: null,
    email: null,
    password: null,
    gender: null,
    nationality: null,
    birth_date: null,
    region: null,
    city: null,
    qualification: null,
    referee_type: null,
    sorting_stage: null,
    branches_id: null,
  });
  const navigate = useNavigate();
  const [sortingStages, setSortingStages] = useState([]);
  const [branches, setBranches] = useState([]);

  const [filteredCities, setFilteredCities] = useState([]);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    ssn: "",
    phone: "",
    phone2: "",
    email: "",
    password: "",
    gender: "",
    nationality: "",
    birth_date: "",
    region: "",
    city: "",
    qualification: "",
    referee_type: "",
    sorting_stage: [],
    branches_id: [],
  });
  const [error, setError] = useState("");
  const [region, setRegion] = useState(formData.region);
  const [city, setCity] = useState(formData.city);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const user_type = localStorage.getItem("user_type");
    if (!token) {
      logout(navigate);
    }

    if (user_type === "referee" || user_type === "client") {
      navigate(-1);
    }
    const fetchData = async () => {
      try {
        const [branchesResponse, refereeDetailsResponse] = await Promise.all([
          // axios.get('https://te.prize.pap.sa/api/sorting/stage', { headers: { Authorization: `Bearer ${token}` } }),
          axios.get("https://te.prize.pap.sa/api/branches", {
            headers: { Authorization: `Bearer ${token}` },
          }),
          refereeId
            ? axios.get(`https://te.prize.pap.sa/api/referees/${refereeId}`, {
                headers: { Authorization: `Bearer ${token}` },
              })
            : null,
        ]);

        // setSortingStages(sortingStagesResponse.data.sorting_stage);

        setBranches(branchesResponse.data.branches);

        if (refereeId && refereeDetailsResponse) {
          const { user, ...refereeDetails } =
            refereeDetailsResponse.data.referee;
          if (user.region) {
            const cities = CITIES.filter(
              (city) => city.region_id === parseInt(user.region)
            );
            setFilteredCities(cities);
            setCity("");
          }

          setFormData({
            ...user,
            ...refereeDetails,
            region: user.region,
            city: user.city,
            sorting_stage: refereeDetails.sorting_stages
              ? refereeDetails.sorting_stages.map((stage) => stage.id)
              : [],
            branches_id: refereeDetails.branches
              ? refereeDetails.branches.map((branch) => branch.id)
              : [],
          });
          setRegion(user.region);
          setCity(user.city);
        }
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to load data");
      }
    };

    fetchData();
  }, [navigate, refereeId]);

  const sortingStageOptions = sortingStages.map((stage) => ({
    value: stage.id,
    label: stage.name,
  }));
  const branchOptions = branches.map((branch) => ({
    value: branch.id,
    label: branch.name,
  }));

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));

    if (name === "ssn" && value.length !== 10) {
      setValidationErrors({
        ...validationErrors,
        ssn: ["SSN must be 10 characters long"],
      });
    } else {
      setValidationErrors({
        ...validationErrors,
        ssn: null,
      });
    }
  };

  const handleRegionChange = (e) => {
    const selectedRegion = e.target.value;
    setRegion(selectedRegion);
    const cities = CITIES.filter(
      (city) => city.region_id === parseInt(selectedRegion)
    );
    setFilteredCities(cities);
    setCity("");
    setFormData({ ...formData, region: selectedRegion, city: "" });
  };

  const handleCityChange = (e) => {
    const selectedCity = e.target.value;
    setCity(selectedCity);
    setFormData({ ...formData, city: selectedCity });
  };

  const handleArrayChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: Array.from(e.target.selectedOptions, (option) => option.value),
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const token = localStorage.getItem("token");

    try {
      await axios.put(
        `https://te.prize.pap.sa/api/referees/${refereeId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      navigate("/admin/dashboard/arbitrators");
      setValidationErrors({});
    } catch (err) {
      if (err.response && err.response.data.errors) {
        setValidationErrors(err.response.data.errors);
      } else {
        setError("Failed to update referee. Please check the details.");
        console.error("Error updating referee:", err);
      }
    }
  };
  const calculateAge = (birthDate) => {
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const m = today.getMonth() - birth.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birth.getDate())) {
      age--;
    }
    return age;
  };
  const handleDateChange = (date) => {
    // Format the date to YYYY-MM-DD
    const formattedDate = date
      ? `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
          2,
          "0"
        )}-${String(date.getDate()).padStart(2, "0")}`
      : "";

    setFormData({ ...formData, birth_date: formattedDate });
  };

  const handleSortingStageChange = (selectedOptions) => {
    const values = selectedOptions.map((option) => option.value);
    setFormData({ ...formData, sorting_stage: values });
  };

  const handleBranchesChange = (selectedOptions) => {
    const values = selectedOptions.map((option) => option.value);
    setFormData({ ...formData, branches_id: values });
  };

  const selectedSortingStages = sortingStageOptions.filter((option) =>
    formData.sorting_stage.includes(option.value)
  );

  // Convert formData.branches_id IDs to the format by Select component
  const selectedBranches = branchOptions.filter((option) =>
    formData.branches_id.includes(option.value)
  );

  return (
    <div className="container-fluid mt-2">
      <div className="row admin_page_header">
        <div className="admin_page_title">تعديل المحكمين</div>
        <div className="admin_page_subtitle mt-2">المحكمين / تعديل محكمين</div>
      </div>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <div className="admin_page_content col-12 row mb-4">
        <div className="form-container">
          <form onSubmit={handleSubmit}>
            <div className="form-row  row">
              <div className="form-group mb-5 col-md-6 col-xl-4">
                <label className="form-label">الاسم الاول :</label>
                <input
                  className="form-field form-control"
                  type="text"
                  name="first_name"
                  placeholder="First Name"
                  value={formData.first_name}
                  onChange={handleChange}
                />
                {validationErrors.first_name && (
                  <span className="error-message text-danger">
                    {validationErrors.first_name[0]}
                  </span>
                )}
              </div>
              <div className="form-group mb-5 col-md-6 col-xl-4">
                <label className="form-label">اسم العائلة:</label>
                <input
                  className="form-field form-control"
                  type="text"
                  name="last_name"
                  placeholder="Last Name"
                  value={formData.last_name}
                  onChange={handleChange}
                />
                {validationErrors.last_name && (
                  <span className="error-message text-danger">
                    {validationErrors.last_name[0]}
                  </span>
                )}
              </div>
              <div className="form-group mb-5 col-md-6 col-xl-4">
                <label className="form-label"> رقم الهوية الوطنية:</label>
                <input
                  className="form-field form-control"
                  type="text"
                  name="ssn"
                  placeholder="SSN"
                  value={formData.ssn}
                  onChange={handleChange}
                  maxLength={10}
                />
                <span className="character-count">
                  {(formData.ssn || "").length}/10
                </span>
                {validationErrors.ssn && (
                  <span className="error-message text-danger">
                    {validationErrors.ssn[0]}
                  </span>
                )}
              </div>
            </div>

            <div className="form-row row ">
              <div className="form-group mb-5 col-md-6 col-xl-4">
                <label className="form-label">الجوال:</label>
                <input
                  className="form-field form-control"
                  type="text"
                  name="phone"
                  placeholder="Phone Number"
                  value={formData.phone}
                  onChange={handleChange}
                  maxLength={9}
                  minLength={9}
                />
                {validationErrors.phone && (
                  <span className="error-message text-danger">
                    {validationErrors.phone[0]}
                  </span>
                )}
              </div>
              <div className="form-group mb-5 col-md-6 col-xl-4">
                <label className="form-label">الجوال 2:</label>
                <input
                  className="form-field form-control"
                  type="text"
                  name="phone2"
                  placeholder="Secondary Phone"
                  value={formData.phone2}
                  onChange={handleChange}
                  maxLength={9}
                  minLength={9}
                />
                {validationErrors.phone2 && (
                  <span className="error-message text-danger">
                    {validationErrors.phone2[0]}
                  </span>
                )}
              </div>
              <div className="form-group mb-5 col-md-6 col-xl-4">
                <label className="form-label">البريد الالكتروني:</label>
                <input
                  className="form-field form-control"
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                />
                {validationErrors.email && (
                  <span className="error-message text-danger">
                    {validationErrors.email[0]}
                  </span>
                )}
              </div>
            </div>

            <div className="form-row row">
              <div className="form-group mb-5 col-md-6 col-xl-4">
                <label className="form-label">الجنس:</label>
                <select
                  className="form-select"
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                >
                  <option value="">اختر الجنس</option>
                  <option value="male">ذكر</option>
                  <option value="female">أنثى</option>
                </select>
                {validationErrors.gender && (
                  <span className="error-message text-danger">
                    {validationErrors.gender[0]}
                  </span>
                )}
              </div>
              <div className="form-group mb-5 col-md-6 col-xl-4">
                <label className="form-label">الجنسية:</label>
                <select
                  className="form-select"
                  name="nationality"
                  value={formData.nationality}
                  onChange={handleChange}
                >
                  <option value="">Select Nationality</option>
                  <option value="sa">السعودية</option>
                  <option value="son_or_daughter_home">
                    ابن / ابنة مواطنة
                  </option>
                </select>
                {validationErrors.nationality && (
                  <span className="error-message text-danger">
                    {validationErrors.nationality[0]}
                  </span>
                )}
              </div>
              <div className="form-group mb-5 col-md-6 col-xl-4 row">
                <label className="form-label">تاريخ الميلاد:</label>
                <ReactDatePicker
                  className="form-control"
                  selected={
                    formData.birth_date ? new Date(formData.birth_date) : null
                  }
                  onChange={handleDateChange}
                  dateFormat="yyyy-MM-dd"
                  maxDate={new Date()}
                  showYearDropdown
                  scrollableYearDropdown
                />
                {validationErrors.birth_date && (
                  <span className="text-danger">
                    {validationErrors.birth_date[0]}
                  </span>
                )}
                {formData.birth_date && (
                  <p>العمر: {calculateAge(formData.birth_date)} سنه</p>
                )}
              </div>
            </div>

            <div className="form-row row">
              <div className="form-group mb-5 col-md-6 col-xl-4 col-md-6 col-xl-3">
                <label htmlFor="region">المنطقة:</label>
                <select
                  className="form-control"
                  id="region"
                  value={region}
                  onChange={handleRegionChange}
                >
                  <option value="">اختر المنطقة</option>
                  {REGIONS.map((region) => (
                    <option key={region.region_id} value={region.region_id}>
                      {region.name_ar}
                    </option>
                  ))}
                </select>
                {validationErrors.region && (
                  <span className="error-message text-danger">
                    {validationErrors.region[0]}
                  </span>
                )}
              </div>
              <div className="form-group mb-5 col-md-6 col-xl-4 col-md-6 col-xl-3">
                <label htmlFor="city">المدينة:</label>
                <select
                  className="form-control"
                  id="city"
                  value={city}
                  onChange={handleCityChange}
                >
                  <option value="">اختر المدينة</option>
                  {filteredCities.map((city) => (
                    <option key={city.city_id} value={city.city_id}>
                      {city.name_ar}
                    </option>
                  ))}
                </select>
                {validationErrors.city && (
                  <span className="error-message text-danger">
                    {validationErrors.city[0]}
                  </span>
                )}
              </div>
              <div className="form-group mb-5 col-md-6 col-xl-4">
                <label className="form-label">المؤهل:</label>
                <select
                  className="form-select"
                  name="qualification"
                  value={formData.qualification}
                  onChange={handleChange}
                >
                  <option value="" disabled selected>
                    حدد
                  </option>
                  <option value="1">امي</option>
                  <option value="2">ابتدائي</option>
                  <option value="3">متوسط</option>
                  <option value="4">ثانوي</option>
                  <option value="5">دبلوم</option>
                  <option value="6">بكالوريوس</option>
                  <option value="7">ماجستير</option>
                  <option value="8">دكتوراه</option>
                </select>
                {validationErrors.qualification && (
                  <span className="error-message text-danger">
                    {validationErrors.qualification[0]}
                  </span>
                )}
              </div>
            </div>

            <div>
              <label className="form-label">نوع المحكم:</label>
              <select
                className="form-select"
                name="referee_type"
                value={formData.referee_type}
                onChange={handleChange}
              >
                <option value="">اختر نوع المحكم</option>
                <option value="sort_references">محكم فرز</option>
                <option value="grade_references">محكم تقييم</option>
                <option value="social_media_references">
                  محكم سوشيال ميديا
                </option>
                <option value=" final_grade_referee">محكم درجات نهائي</option>
                <option value=" final_social_media_referee">
                  محكم سوشيال ميديا نهائي
                </option>
              </select>
              {validationErrors.referee_type && (
                <span className="error-message text-danger">
                  {validationErrors.referee_type[0]}
                </span>
              )}
            </div>

            {/* <div className="checkbox-group">
                <label className="form-label">Sorting Stage:</label>
                <Select
                  isMulti
                  options={sortingStageOptions}
                  value={selectedSortingStages} // Set the value to the selectedSortingStages
                  onChange={handleSortingStageChange}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
                {validationErrors.sorting_stage && (
                  <span className="error-message text-danger">
                    {validationErrors.sorting_stage[0]}
                  </span>
                )}
              </div> */}

            <div className="checkbox-group ">
              <label className="form-label">الفرع</label>
              <Select
                isMulti
                options={branchOptions}
                value={selectedBranches} // Set the value to the selectedBranches
                onChange={handleBranchesChange}
                className="basic-multi-select"
                classNamePrefix="select"
              />
              {validationErrors.branches_id && (
                <span className="error-message text-danger">
                  {validationErrors.branches_id[0]}
                </span>
              )}
            </div>

            <div className="d-flex justify-content-center mb-5 mt-5">
              <button type="submit" className="ms-2 user_info_btn_produce p-2">
                تعديل
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditArbitrator;
