import React, { useState, useEffect } from "react";
import axios from "axios";
import "./newEmployee.css";
import { useNavigate } from "react-router-dom";
import { logout } from "../../auth/Login";
import REGIONS from "../../../common/REGIONS";
import CITIES from "../../../common/CITIES";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./styles.css";

const NewEmployee = () => {
  const [validationErrors, setValidationErrors] = useState({});
  const navigate = useNavigate();
  const [region, setregion] = useState("");
  const [city, setcity] = useState("");
  const [filteredCities, setFilteredCities] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    ssn: "",
    phone: "",
    phone2: "",
    email: "",
    password: "",
    gender: "",
    nationality: "",
    birth_date: "",
    region: "",
    city: "",
    qualification: "",

    fields: {
      index: false,
      store: false,
      update: false,
      destroy: false,
    },
    branches: {
      index: false,
      store: false,
      update: false,
      destroy: false,
    },
    criteria: {
      index: false,
      store: false,
      update: false,
      destroy: false,
    },
    clients: {
      index: false,
      store: false,
      update: false,
      destroy: false,
    },
    referees: {
      index: false,
      store: false,
      update: false,
      destroy: false,
    },
    employees: {
      index: false,
      permission: false,
      store: false,
      update: false,
      destroy: false,
    },
    support_times: {
      index: false,
      store: false,
      show: false,
      destroy: false,
    },
    works: {
      index: false,
      store: false,
      update: false,
      destroy: false,
    },
    commerces: {
      index: false,
      store: false,
      update: false,
      destroy: false,
    },
    social_accounts: {
      index: false,
      store: false,
      update: false,
      destroy: false,
    },
    archives: {
      index: false,
      show: false,
      store: false,
      update: false,
      destroy: false,
    },
    client_evaluations: {
      index: false,
      store: false,
      update: false,
      destroy: false,
    },
    ads: {
      index: false,
      store: false,
      update: false,
      destroy: false,
    },
    reports: {
      action: false,
    },
    deportations: {
      action: false,
    },
  });
  const [error, setError] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    const user_type = localStorage.getItem("user_type");
    if (!token) {
      logout(navigate);
    }

    if (user_type === "referee" || user_type === "client") {
      navigate(-1);
    }
  }, [navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name.includes(".")) {
      const [parentKey, childKey] = name.split(".");
      setFormData({
        ...formData,
        [parentKey]: {
          ...formData[parentKey],
          [childKey]: value,
        },
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const token = localStorage.getItem("token");

    const modifiedFormData = {
      ...formData,
      phone: `966${formData.phone}`,
      phone2: formData.phone2 ? `966${formData.phone2}` : "", 
    };

    try {
      await axios.post(
        "https://te.prize.pap.sa/api/employee",
        modifiedFormData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate("/admin/dashboard/employees");
      setValidationErrors({});
    } catch (err) {
      if (err.response && err.response.data.errors) {
        setValidationErrors(err.response.data.errors);
      } else {
        setError("Failed to create employee. Please check the details.");
      }
    }
  };

  const renderToggleFields = (parentField, fields) => {
    return Object.keys(fields).map((field, index) => (
      <div
        className="mb-3 d-flex align-items-center justify-content-between"
        key={index}
      >
        <label className="form-label">{fieldMapping[field] || field}:</label>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            id={`flexSwitchCheck${index}`}
            name={`${parentField}.${field}`}
            checked={formData[parentField][field]}
            onChange={(e) =>
              handleChange({
                target: {
                  name: `${parentField}.${field}`,
                  value: e.target.checked,
                },
              })
            }
          />
          <label
            className="form-check-label"
            htmlFor={`flexSwitchCheck${index}`}
          ></label>
        </div>
      </div>
    ));
  };
  const fieldMapping = {
    index: "عرض",
    show: "عرض تفاصيل",
    store: "إضافة",
    update: "تحديث",
    destroy: "حذف",
    permission: "الصلاحيات",
    action: 'امكانية التفاعل ',
  };

  const handleRegionChange = (e) => {
    const selectedRegion = e.target.value;
    setregion(selectedRegion);
    const cities = CITIES.filter(
      (city) => city.region_id === parseInt(selectedRegion)
    );
    setFilteredCities(cities);

    setcity("");
    setFormData({ ...formData, region: selectedRegion, city: "" });
  };

  const handleCityChange = (e) => {
    const selectedCity = e.target.value;
    setcity(selectedCity);
    setFormData({ ...formData, city: selectedCity });
  };

  const calculateAge = (birthDate) => {
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const m = today.getMonth() - birth.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birth.getDate())) {
      age--;
    }
    return age;
  };

  const handleDateChange = (date) => {
    // Format the date to YYYY-MM-DD
    const formattedDate = date
      ? `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
          2,
          "0"
        )}-${String(date.getDate()).padStart(2, "0")}`
      : "";

    setFormData({ ...formData, birth_date: formattedDate });
  };

  return (
    <div className="container-fluid mt-2">
      <div className="row admin_page_header">
        <div className="admin_page_title">اضافة موظف جديد</div>
        <div className="admin_page_subtitle mt-2">
          الموظفين / اضافة موظف جديد
        </div>
      </div>
      {error && <p style={{ color: "red", margin: "5px" }}>{error}</p>}
      <div className="admin_page_content col-12 row mb-4">
        <form onSubmit={handleSubmit}>
          <div className="row">
            {/* First Name */}
            <div className="col-md-4">
              <div className="form-group mb-3">
                <label className="form-label">الاسم الاول:</label>
                <i
                  className="fa-solid fa-star user_star"
                  style={{ color: "#fd1212" }}
                ></i>
                <input
                  className="form-control"
                  type="text"
                  name="first_name"
                  placeholder="الاسم الاول"
                  onChange={handleChange}
                />
                {validationErrors.first_name && (
                  <span className="error-message text-danger">
                    {validationErrors.first_name[0]}
                  </span>
                )}
              </div>
            </div>

            {/* Last Name */}
            <div className="col-md-4 mb-3">
              <div className="form-group">
                <label className="form-label">اسم العائة:</label>
                <i
                  className="fa-solid fa-star user_star"
                  style={{ color: "#fd1212" }}
                ></i>
                <input
                  className="form-control"
                  type="text"
                  name="last_name"
                  placeholder="اسم العائلة"
                  onChange={handleChange}
                />
                {validationErrors.last_name && (
                  <span className="error-message text-danger">
                    {validationErrors.last_name[0]}
                  </span>
                )}
              </div>
            </div>

            {/* SSN */}
            <div className="col-md-4 mb-3">
              <div className="form-group">
                <label className="form-label"> رقم الهوية الوطنية::</label>
                <i
                  className="fa-solid fa-star user_star"
                  style={{ color: "#fd1212" }}
                ></i>
                <input
                  className="form-control"
                  type="text"
                  name="ssn"
                  placeholder="رقم الهوية الوطنية:"
                  onChange={handleChange}
                  maxLength={10}
                />
                <span className="character-count">
                  {formData.ssn.length}/10
                </span>
                {validationErrors.ssn && (
                  <span className="error-message text-danger">
                    {validationErrors.ssn[0]}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-4 mb-3">
              <div className="form-group">
                <label className="form-label">رقم الجوال:</label>
                <i
                  className="fa-solid fa-star user_star"
                  style={{ color: "#fd1212" }}
                ></i>
                <input
                  className="form-control"
                  type="text"
                  name="phone"
                  placeholder="5xxxxxxxx"
                  onChange={handleChange}
                  maxLength={9}
                  minLength={9}
                />
                {validationErrors.phone && (
                  <span className="error-message text-danger">
                    {validationErrors.phone[0]}
                  </span>
                )}
              </div>
            </div>

            {/* Secondary Phone (Optional) */}
            <div className="col-md-4">
              <div className="form-group">
                <label className="form-label"> رقم الجوال 2</label>
                <input
                  className="form-control"
                  type="text"
                  name="phone2"
                  placeholder="5xxxxxxxx"
                  onChange={handleChange}
                  maxLength={9}
                  minLength={9}
                />
                {validationErrors.phone2 && (
                  <span className="error-message text-danger">
                    {validationErrors.phone2[0]}
                  </span>
                )}
              </div>
            </div>

            {/* Email */}
            <div className="col-md-4">
              <div className="form-group">
                <label className="form-label">البريد الالكتروني:</label>
                <i
                  className="fa-solid fa-star user_star"
                  style={{ color: "#fd1212" }}
                ></i>
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  placeholder="البريد الإلكتروني"
                  onChange={handleChange}
                />
                {validationErrors.email && (
                  <span className="error-message text-danger">
                    {validationErrors.email[0]}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            {/* Password */}
            <div className="col-md-4 mb-3">
              <div className="form-group">
                <label className="form-label">كلمه السر:</label>
                <i
                  className="fa-solid fa-star user_star"
                  style={{ color: "#fd1212" }}
                ></i>
                <input
                  className="form-control"
                  type="password"
                  name="password"
                  placeholder="كلمة المرور"
                  onChange={handleChange}
                />
                {validationErrors.password && (
                  <span className="error-message text-danger">
                    {validationErrors.password[0]}
                  </span>
                )}
              </div>
            </div>

            {/* Gender */}
            <div className="col-md-4">
              <div className="form-group">
                <label className="form-label">الجنس:</label>
                <i
                  className="fa-solid fa-star user_star"
                  style={{ color: "#fd1212" }}
                ></i>
                <select
                  className="form-select"
                  name="gender"
                  onChange={handleChange}
                >
                  <option value="">اختر الجنس</option>
                  <option value="male">ذكر</option>
                  <option value="female">أنثى</option>
                  {/* Add more options as needed */}
                </select>
                {validationErrors.gender && (
                  <span className="error-message text-danger">
                    {validationErrors.gender[0]}
                  </span>
                )}
              </div>
            </div>

            {/* Nationality */}
            <div className="col-md-4 mb-3">
              <div className="form-group">
                <label className="form-label">الجنسية:</label>
                <i
                  className="fa-solid fa-star user_star"
                  style={{ color: "#fd1212" }}
                ></i>
                <select
                  className="form-select"
                  name="nationality"
                  onChange={handleChange}
                >
                  <option value="">اختر الجنسية</option>
                  <option value="sa">السعودية</option>
                  <option value="son_or_daughter_home">
                    ابن / ابنة مواطنة
                  </option>
                  {/* Add more options as needed */}
                </select>
                {validationErrors.nationality && (
                  <span className="error-message text-danger">
                    {validationErrors.nationality[0]}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-4 mb-3">
              <div className="form-group row">
                <label className="form-label">تاريخ الميلاد:</label>

                <ReactDatePicker
                  className="form-control"
                  selected={
                    formData.birth_date ? new Date(formData.birth_date) : null
                  }
                  onChange={handleDateChange}
                  dateFormat="yyyy-MM-dd"
                  maxDate={new Date()}
                  showYearDropdown
                  scrollableYearDropdown
                />

                {validationErrors.birth_date && (
                  <span className="text-danger">
                    {validationErrors.birth_date[0]}
                  </span>
                )}
                {formData.birth_date && (
                  <p>العمر: {calculateAge(formData.birth_date)} سنه</p>
                )}
                {validationErrors.birth_date && (
                  <span className="error-message text-danger">
                    {validationErrors.birth_date[0]}
                  </span>
                )}
              </div>
            </div>

            {/* Region */}
            <div className="col-md-4 mb-3">
              <div className="form-group">
                <label htmlFor="region">المنطقة</label>
                <i
                  className="fa-solid fa-star user_star"
                  style={{ color: "#fd1212" }}
                ></i>
                <select
                  className="form-control"
                  id="region"
                  value={region} // set the selected value here
                  onChange={handleRegionChange}
                >
                  <option value="">اختر المنطقة</option>
                  {REGIONS.map((region) => (
                    <option key={region.region_id} value={region.region_id}>
                      {region.name_ar}
                    </option>
                  ))}
                </select>
                {validationErrors.region && (
                  <span className="error-message text-danger">
                    {validationErrors.region[0]}
                  </span>
                )}
              </div>
            </div>

            {/* City */}
            <div className="col-md-4 mb-3">
              <div className="form-group">
                <label htmlFor="city">المدينة</label>
                <i
                  className="fa-solid fa-star user_star"
                  style={{ color: "#fd1212" }}
                ></i>
                <select
                  className="form-control"
                  id="city"
                  value={city}
                  onChange={handleCityChange}
                >
                  <option value="">اختر المدينة</option>
                  {filteredCities.map((city) => (
                    <option key={city.city_id} value={city.city_id}>
                      {city.name_ar}
                    </option>
                  ))}
                </select>
                {validationErrors.city && (
                  <span className="error-message text-danger">
                    {validationErrors.city[0]}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div>
            <label className="form-label">المؤهل:</label>
            <i
              className="fa-solid fa-star user_star"
              style={{ color: "#fd1212" }}
            ></i>
            <select
              className="form-select"
              name="qualification"
              onChange={handleChange}
            >
              <option value="" disabled selected>
                حدد
              </option>
              <option value="1">امي</option>
              <option value="2">ابتدائي</option>
              <option value="3">متوسط</option>
              <option value="4">ثانوي</option>
              <option value="5">دبلوم</option>
              <option value="6">بكالوريوس</option>
              <option value="7">ماجستير</option>
              <option value="8">دكتوراه</option>
            </select>
            {validationErrors.qualification && (
              <span className="error-message text-danger">
                {validationErrors.qualification[0]}
              </span>
            )}
          </div>

          <div className="row mt-5">
            <div className="table-responsive">
              <table className="table employee-custom-table">
                <thead>
                  <tr>
                    <th className="admin_rules_thead">العناصر</th>
                    <th className="admin_rules_thead">تفاصيل الصلاحيات</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>المجال:</td>
                    <td>
                      <div class="admi_toggle_switch">
                        {renderToggleFields("fields", formData.fields)}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>الافرع:</td>
                    <td>
                      <div class="admi_toggle_switch">
                        {renderToggleFields("branches", formData.branches)}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>التقييمات:</td>
                    <td>
                      <div class="admi_toggle_switch">
                        {renderToggleFields("criteria", formData.criteria)}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>المتسابقين:</td>
                    <td>
                      <div class="admi_toggle_switch">
                        {renderToggleFields("clients", formData.clients)}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>المحكمين:</td>
                    <td>
                      <div class="admi_toggle_switch">
                        {renderToggleFields("referees", formData.referees)}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>الموظفين:</td>
                    <td>
                      <div class="admi_toggle_switch">
                        {renderToggleFields("employees", formData.employees)}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>الدعم الفني:</td>
                    <td>
                      <div class="admi_toggle_switch">
                        {renderToggleFields(
                          "support_times",
                          formData.support_times
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>العمل:</td>
                    <td>
                      <div class="admi_toggle_switch">
                        {renderToggleFields("works", formData.works)}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>التجاري:</td>
                    <td>
                      <div class="admi_toggle_switch">
                        {renderToggleFields("commerces", formData.commerces)}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>سوشيال ميديا:</td>
                    <td>
                      <div class="admi_toggle_switch">
                        {renderToggleFields(
                          "social_accounts",
                          formData.social_accounts
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>الارشيف:</td>
                    <td>
                      <div class="admi_toggle_switch">
                        {renderToggleFields("archives", formData.archives)}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>التقييم:</td>
                    <td>
                      <div class="admi_toggle_switch">
                        {renderToggleFields(
                          "client_evaluations",
                          formData.client_evaluations
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>الاعلانات:</td>
                    <td>
                      <div class="admi_toggle_switch">
                        {renderToggleFields("ads", formData.ads)}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>التقارير:</td>
                    <td>
                      <div class="admi_toggle_switch">
                        {renderToggleFields("reports", formData.reports)}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>الترحيل:</td>
                    <td>
                      <div class="admi_toggle_switch">
                        {renderToggleFields("deportations", formData.deportations)}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="row">
            <div className="d-flex justify-content-center mb-5">
              <button type="submit" className="user_info_btn_produce mt-3 p-2">
                انشاء
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewEmployee;
