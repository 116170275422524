import React, { useState, useEffect } from "react";
import axios from "axios";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Typography from "@mui/material/Typography";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SettingsIcon from "@mui/icons-material/Settings";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import CompanyLogo from "../../public/assets/logo.png";
import { logout } from "../../pages/auth/Login";
import { json, useNavigate } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import SupportAgentRoundedIcon from "@mui/icons-material/SupportAgentRounded";

import "./layout.css";
// Define drawerWidth and other constants
const drawerWidth = 240;

// Define mixins for opened and closed drawer states
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const LinkStyled = styled(RouterLink)({
  display: "block",
  color: "white",
  textDecoration: "none",
  margin: "10px",
  fontSize: "10px",
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  anchor: "right",
  backgroundColor: "#2a3042",
  "& .MuiDrawer-paper": {
    backgroundColor: "#2a3042",
  },
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": {
      ...openedMixin(theme),
      backgroundColor: "#2a3042",
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": {
      ...closedMixin(theme),
      backgroundColor: "#2a3042",
    },
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const CompanyLogoImg = styled("img")(({ theme, open }) => ({
  width: open ? "40%" : "0",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  marginBottom: theme.spacing(2),
}));

const AccordionStyled = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  background: "transparent", // Keep previous styles
  border: "none", // Remove border
  boxShadow: "none",
}));

const AccordionSummaryStyled = styled((props) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  border: "none", // Remove border
  color: "white",
}));

const AccordionDetailsStyled = styled(MuiAccordionDetails)(({ theme }) => ({
  border: "none", // Remove border
}));

const SideBar = ({ open, handleDrawerClose }) => {
  const theme = useTheme();
  const [expandedAccordion, setExpandedAccordion] = useState(null);
  const [userType, setUserType] = useState("");
  const [employee_premission, setEmployeePremission] = useState({});
  const [referee, setReferee] = useState({});
  const [notSeenSupportCount, setNotSeenSupportCount] = useState(0);
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const user_id = localStorage.getItem("user_id");
      const response = await axios.get(
        `https://te.prize.pap.sa/api/employees/${user_id}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      setEmployeePremission(response.data.employee);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        console.log(`خطاء: ${err.response.data.error}`);
      } else {
        console.log("حدث خطأ أثناء جلب الحقول.");
      }
    }
  };

  const fetchRefereeData = async () => {
    try {
      const user_id = localStorage.getItem("user_id");
      const response = await axios.get(`https://te.prize.pap.sa/api/referees/show/${user_id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
      setReferee(response.data.referee);
     
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        console.log(`خطاء: ${err.response.data.error}`);
      } else {
        console.log("حدث خطأ أثناء جلب الحقول.");
      }
    }
  };

  const fetchsupportCount = async () => {
    try {
      const response = await axios.get(`https://te.prize.pap.sa/api/support/time/count/not-seen`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
      setNotSeenSupportCount(response.data.supportTimesCount);
     
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        console.log(`خطاء: ${err.response.data.error}`);
      } else {
        console.log("حدث خطأ أثناء جلب الحقول.");
      }
    }
  };

  useEffect(() => {
    const storedUserType = localStorage.getItem("user_type");
    const token = localStorage.getItem("token");

    if (!token) {
      logout(navigate);
    }

    if (storedUserType === "client") {
      navigate(-1);
      logout(navigate);
    }
    if (storedUserType == "employee") {
      fetchData();
      fetchsupportCount();
    }
    if (storedUserType == "super_admin") {
      fetchsupportCount();
    }

    if (storedUserType == "referee") {
      fetchRefereeData();
    }

    setUserType(storedUserType || "");
  }, [navigate]);

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : null);
  };

  return (
    <Drawer variant="permanent" open={open} anchor="right">
      <DrawerHeader>
        <IconButton
          onClick={handleDrawerClose}
          sx={{ color: "white", "&:hover": { backgroundColor: "transparent" } }}
        >
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
        <CompanyLogoImg
          theme={theme}
          open={open}
          src={CompanyLogo}
          alt="Company Logo"
        />
      </DrawerHeader>
      <Divider />
      {userType === "referee" && (
        <>
          <LinkStyled to="/referee/dashboard" underline="none">
            <SettingsIcon className="" />
            {open && "لوحة التحكم"}
          </LinkStyled>

          <AccordionStyled
            expanded={expandedAccordion === "panel1"}
            onChange={handleChangeAccordion("panel1")}
          >
            <AccordionSummaryStyled
              expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <DashboardIcon />
              {open && (
                <Typography className="admin_side_title">المرشحين</Typography>
              )}
            </AccordionSummaryStyled>
            <AccordionDetailsStyled>
              <Typography>
                <LinkStyled to="/referee/dashboard/candidates" underline="none">
                  {"بانتظار التقييم"}
                </LinkStyled>
                {referee.referee_type != 'sort_references' && (
                    <LinkStyled to="/referee/dashboard/complete/nomination" underline="none">
                    {"التقييمات المنفذة"}
                    </LinkStyled>
                )}
               

              </Typography>
            </AccordionDetailsStyled>
          </AccordionStyled>
        
        </>
      )}
      {userType === "employee" && (
        <>
          <LinkStyled to="/admin/dashboard" underline="none">
            <HomeIcon />

            {open && "لوحة التحكم"}
          </LinkStyled>
          <AccordionStyled
            expanded={expandedAccordion === "panel1"}
            onChange={handleChangeAccordion("panel1")}
          >
            <AccordionSummaryStyled
              expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <PersonIcon className="" />
              {open && (
                <Typography className="admin_side_title">المرشحين</Typography>
              )}
            </AccordionSummaryStyled>
            <AccordionDetailsStyled>
              <Typography>
                {employee_premission.clients?.index == true && (
                  <LinkStyled to="/admin/dashboard/candidates" underline="none">
                    {" عرض الكل"}
                  </LinkStyled>
                )}
                {employee_premission.clients?.store == true && (
                  <LinkStyled
                    to="/admin/dashboard/candidates/new"
                    underline="none"
                  >
                    {" إضافة جديد"}
                  </LinkStyled>
                )}
              </Typography>
            </AccordionDetailsStyled>
          </AccordionStyled>
          <AccordionStyled
            expanded={expandedAccordion === "panel2"}
            onChange={handleChangeAccordion("panel2")}
          >
            <AccordionSummaryStyled
              expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <AccountBoxIcon className="" />
              {open && (
                <Typography className="admin_side_title">الموظفين</Typography>
              )}
            </AccordionSummaryStyled>
            <AccordionDetailsStyled>
              <Typography>
                {employee_premission.employees?.index == true && (
                  <LinkStyled to="/admin/dashboard/employees" underline="none">
                    {" عرض الموظفين"}
                  </LinkStyled>
                )}
                {employee_premission.employees?.store == true && (
                  <LinkStyled
                    to="/admin/dashboard/employee/new"
                    underline="none"
                  >
                    {" موظف جديد"}
                  </LinkStyled>
                )}
                {employee_premission.employees?.permission == true && (
                  <LinkStyled
                    to="/admin/dashboard/employees/rules"
                    underline="none"
                  >
                    {" صلاحيات الموظفين"}
                  </LinkStyled>
                )}
              </Typography>
            </AccordionDetailsStyled>
          </AccordionStyled>
          <AccordionStyled
            expanded={expandedAccordion === "panel3"}
            onChange={handleChangeAccordion("panel3")}
          >
            <AccordionSummaryStyled
              expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <RecordVoiceOverIcon className="" />
              {open && (
                <Typography className="admin_side_title">المحكمين</Typography>
              )}
            </AccordionSummaryStyled>
            <AccordionDetailsStyled>
              <Typography>
                {employee_premission.referees?.index == true && (
                  <LinkStyled
                    to="/admin/dashboard/arbitrators"
                    underline="none"
                  >
                    {" عرض الكل"}
                  </LinkStyled>
                )}
                {employee_premission.referees?.store == true && (
                  <LinkStyled
                    to="/admin/dashboard/arbitrator/new"
                    underline="none"
                  >
                    {" إضافة جديد"}
                  </LinkStyled>
                )}
              </Typography>
            </AccordionDetailsStyled>
          </AccordionStyled>
          <AccordionStyled
            expanded={expandedAccordion === "panel4"}
            onChange={handleChangeAccordion("panel4")}
          >
            <AccordionSummaryStyled
              expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              aria-controls="panel4a-content"
              id="panel4a-header"
            >
              <DashboardRoundedIcon className="" />
              {open && (
                <Typography className="admin_side_title">
                  المجالات والفروع
                </Typography>
              )}
            </AccordionSummaryStyled>
            <AccordionDetailsStyled>
              <Typography>
                {employee_premission.fields?.index == true && (
                  <LinkStyled to="/admin/dashboard/Fields" underline="none">
                    {" عرض المجالات"}
                  </LinkStyled>
                )}
                {employee_premission.fields?.store == true && (
                  <LinkStyled to="/admin/dashboard/Fields/new" underline="none">
                    {" إضافة مجال"}
                  </LinkStyled>
                )}
                {employee_premission.branches?.index == true && (
                  <LinkStyled to="/admin/dashboard/branches" underline="none">
                    {" عرض الفروع "}
                  </LinkStyled>
                )}
                {employee_premission.branches?.store == true && (
                  <LinkStyled
                    to="/admin/dashboard/branches/new"
                    underline="none"
                  >
                    {" إضافة فرع"}
                  </LinkStyled>
                )}
                {employee_premission.criteria?.index == true && (
                  <LinkStyled to="/admin/dashboard/standards" underline="none">
                    {" عرض المعايير"}
                  </LinkStyled>
                )}
                {employee_premission.criteria?.store == true && (
                  <LinkStyled
                    to="/admin/dashboard/standards/new"
                    underline="none"
                  >
                    {" إضافة معيار"}
                  </LinkStyled>
                )}
              </Typography>
            </AccordionDetailsStyled>
          </AccordionStyled>

          {employee_premission.support_times?.index == true && (
            <LinkStyled to="/support" underline="none">
              <SupportAgentRoundedIcon />
              {open && " الدعم الفني"}
              {/* Display the count if notSeenSupportCount is greater than 0 */}
              {notSeenSupportCount > 0 && (
                <span className="support-count">{notSeenSupportCount}</span>
              )}
            </LinkStyled>
          )}

          {employee_premission.archives?.index == true && (
            <LinkStyled to="/archive" underline="none">
              <LiveHelpIcon className="" />
              {open && "الارشيف"}
            </LinkStyled>
          )}
          {employee_premission.deportations?.action == true && (
                    <AccordionStyled
                    expanded={expandedAccordion === "panel5"}
                    onChange={handleChangeAccordion("panel5")}
                  >
                    <AccordionSummaryStyled
                      expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <DashboardIcon className="" />
                      {open && (
                        <Typography className="admin_side_title">الترحيل</Typography>
                      )}
                    </AccordionSummaryStyled>
                    <AccordionDetailsStyled>
                      <Typography>
                        <LinkStyled
                          to="/admin/dashboard/client/deportation"
                          underline="none"
                        >
                          {"ترحيل  إلى المرحلة النهائية"}
                        </LinkStyled>
                      </Typography>
                    </AccordionDetailsStyled>
                  </AccordionStyled>
          )}
          {employee_premission.reports?.action == true && (
                     <LinkStyled to="/reports" underline="none">
                     <LiveHelpIcon className="" />
                     {open && "التقارير"}
                   </LinkStyled>
          )}

        </>
      )}
      {userType !== "referee" && userType !== "employee" && (
        <>
          <LinkStyled to="/admin/dashboard" underline="none">
            <HomeIcon />

            {open && "لوحة التحكم"}
          </LinkStyled>
          <AccordionStyled
            expanded={expandedAccordion === "panel1"}
            onChange={handleChangeAccordion("panel1")}
          >
            <AccordionSummaryStyled
              expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <PersonIcon className="" />
              {open && (
                <Typography className="admin_side_title">المرشحين</Typography>
              )}
            </AccordionSummaryStyled>
            <AccordionDetailsStyled>
              <Typography>
                <LinkStyled to="/admin/dashboard/candidates" underline="none">
                  {" عرض الكل"}
                </LinkStyled>
                <LinkStyled
                  to="/admin/dashboard/candidates/new"
                  underline="none"
                >
                  {" إضافة جديد"}
                </LinkStyled>
              </Typography>
            </AccordionDetailsStyled>
          </AccordionStyled>
          <AccordionStyled
            expanded={expandedAccordion === "panel2"}
            onChange={handleChangeAccordion("panel2")}
          >
            <AccordionSummaryStyled
              expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <AccountBoxIcon className="" />
              {open && (
                <Typography className="admin_side_title">الموظفين</Typography>
              )}
            </AccordionSummaryStyled>
            <AccordionDetailsStyled>
              <Typography>
                <LinkStyled to="/admin/dashboard/employees" underline="none">
                  {" عرض الموظفين"}
                </LinkStyled>
                <LinkStyled to="/admin/dashboard/employee/new" underline="none">
                  {" موظف جديد"}
                </LinkStyled>
                <LinkStyled
                  to="/admin/dashboard/employees/rules"
                  underline="none"
                >
                  {" صلاحيات الموظفين"}
                </LinkStyled>
              </Typography>
            </AccordionDetailsStyled>
          </AccordionStyled>
          <AccordionStyled
            expanded={expandedAccordion === "panel3"}
            onChange={handleChangeAccordion("panel3")}
          >
            <AccordionSummaryStyled
              expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <RecordVoiceOverIcon className="" />
              {open && (
                <Typography className="admin_side_title">المحكمين</Typography>
              )}
            </AccordionSummaryStyled>
            <AccordionDetailsStyled>
              <Typography>
                <LinkStyled to="/admin/dashboard/arbitrators" underline="none">
                  {" عرض الكل"}
                </LinkStyled>
                <LinkStyled
                  to="/admin/dashboard/arbitrator/new"
                  underline="none"
                >
                  {" إضافة جديد"}
                </LinkStyled>
              </Typography>
            </AccordionDetailsStyled>
          </AccordionStyled>
          <AccordionStyled
            expanded={expandedAccordion === "panel4"}
            onChange={handleChangeAccordion("panel4")}
          >
            <AccordionSummaryStyled
              expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              aria-controls="panel4a-content"
              id="panel4a-header"
            >
              <DashboardRoundedIcon className="" />
              {open && (
                <Typography className="admin_side_title">
                  المجالات والفروع
                </Typography>
              )}
            </AccordionSummaryStyled>
            <AccordionDetailsStyled>
              <Typography>
                <LinkStyled to="/admin/dashboard/Fields" underline="none">
                  {" عرض المجالات"}
                </LinkStyled>
                <LinkStyled to="/admin/dashboard/Fields/new" underline="none">
                  {" إضافة مجال"}
                </LinkStyled>

                <LinkStyled to="/admin/dashboard/branches" underline="none">
                  {" عرض الفروع "}
                </LinkStyled>
                <LinkStyled to="/admin/dashboard/branches/new" underline="none">
                  {" إضافة فرع"}
                </LinkStyled>
                <LinkStyled to="/admin/dashboard/standards" underline="none">
                  {" عرض المعايير"}
                </LinkStyled>
                <LinkStyled
                  to="/admin/dashboard/standards/new"
                  underline="none"
                >
                  {" إضافة معيار"}
                </LinkStyled>
              </Typography>
            </AccordionDetailsStyled>
          </AccordionStyled>

          <AccordionStyled
            expanded={expandedAccordion === "panel5"}
            onChange={handleChangeAccordion("panel5")}
          >
            <AccordionSummaryStyled
              expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <DashboardIcon className="" />
              {open && (
                <Typography className="admin_side_title">الترحيل</Typography>
              )}
            </AccordionSummaryStyled>
            <AccordionDetailsStyled>
              <Typography>
                <LinkStyled
                  to="/admin/dashboard/client/deportation"
                  underline="none"
                >
                  {"ترحيل  إلى المرحلة النهائية"}
                </LinkStyled>
              </Typography>
            </AccordionDetailsStyled>
          </AccordionStyled>
          <LinkStyled to="/support" underline="none">
            <SupportAgentRoundedIcon />
            {open && " الدعم الفني"}
            {/* Always display the count, even if it's zero */}
            <span className="support-count">{notSeenSupportCount}</span>
          </LinkStyled>

          <LinkStyled to="/archive" underline="none">
            <LiveHelpIcon className="" />
            {open && "الارشيف"}
          </LinkStyled>

          <LinkStyled to="/reports" underline="none">
            <LiveHelpIcon className="" />
            {open && "التقارير"}
          </LinkStyled>
        </>
      )}
    </Drawer>
  );
};

export default SideBar;
