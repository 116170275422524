import React, { Suspense , useEffect } from "react";
import { Routes, Route  , useNavigate} from "react-router-dom";
import { CircleSpinner } from "react-spinners-kit";

import Dashboard from "../pages/dashboard/Dashboard";
import Employees from './../pages/dashboard/employees/Employees';
import EditEmployee from './../pages/dashboard/employees/EditEmployees';
import NewEmployee from './../pages/dashboard/employees/NewEmployee';
import NewEmployeeRule from './../pages/dashboard/employees/EmployeeRules';
import Candidates from './../pages/dashboard/Candidates/Candidates';
import NewCandidate from './../pages/dashboard/Candidates/NewCandidate';
import Deportation from './../pages/dashboard/Client/deportation';
import NewArbitator from './../pages/dashboard/Arbitrators/NewArbitator';
import Fields from './../pages/dashboard/fields/Fields';
import NewField from "../pages/dashboard/fields/NewFields";
import Branches from './../pages/dashboard/fields/Branches';
import NewBranches from "../pages/dashboard/fields/NewBranches";
import Standards from './../pages/dashboard/fields/Standards';
import NewStandards from "../pages/dashboard/fields/NewStandards";
import Referee from './../pages/referee/Referee';
import Clients from "../pages/referee/Clients";
import Support from "../pages/dashboard/Support";
import Arbitrators from "../pages/dashboard/Arbitrators/Arbitrators";
import EditArbitrator from "../pages/dashboard/Arbitrators/EditArbitrator";
import EditCandidate from "../pages/dashboard/Candidates/EditCandidate";
import Archives from "../pages/dashboard/Archives";
import  Profile  from "../pages/auth/Profile";
import NewPassword from "../pages/auth/NewPassword";
import Reports from "../pages/dashboard/Reports/Reports";
import EvaluationsCompleted from "../pages/referee/EvaluationsCompleted";
import Verification from "../pages/veryfication/Verification";
const FallbackSpinner = () => (
  <div className="grid place-items-center h-screen bg-black">
    <CircleSpinner size={60} />
  </div>
);

const LazyLoadComponent = (Component) => (
  <Suspense fallback={<FallbackSpinner />}>
    <Component />
  </Suspense>
);


const MyRoutes = () => {
  
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
 console.log('asd');
    if (!token ) {
      navigate('/');
    }
  }, [navigate]);
  
  return (
    <Routes>
      <Route path="/admin/dashboard" element={LazyLoadComponent(Dashboard)} />
      <Route path="/support" element={LazyLoadComponent(Support)} />
      <Route path="/archive" element={LazyLoadComponent(Archives)} />
      <Route path="/profile" element={LazyLoadComponent(Profile)} />
      <Route path="/admin/dashboard/employees" element={LazyLoadComponent(Employees)} />
      <Route path="/admin/dashboard/employee/new" element={LazyLoadComponent(NewEmployee)} />
      <Route path="/admin/dashboard/employee/edit" element={LazyLoadComponent(EditEmployee)} />
      <Route path="/admin/dashboard/employees/rules" element={LazyLoadComponent(NewEmployeeRule)} />
      <Route path="/admin/dashboard/candidates" element={LazyLoadComponent(Candidates)} />
      <Route path="/admin/dashboard/candidates/new" element={LazyLoadComponent(NewCandidate)} />
      <Route path= "/admin/dashboard/candidates/edit/:clientId" element={LazyLoadComponent(EditCandidate)} />
      <Route path="/admin/dashboard/client/deportation" element={LazyLoadComponent(Deportation)} />
      <Route path="/admin/dashboard/arbitrators" element={LazyLoadComponent(Arbitrators)} />
      <Route path="/admin/dashboard/arbitrator/new" element={LazyLoadComponent(NewArbitator)} />
      <Route path="/admin/dashboard/arbitrator/edit" element={LazyLoadComponent(EditArbitrator)} />
      <Route path="/admin/dashboard/Fields" element={LazyLoadComponent(Fields)} />
      <Route path="/admin/dashboard/Fields/new" element={LazyLoadComponent(NewField)} />
      <Route path="/admin/dashboard/branches" element={LazyLoadComponent(Branches)} />
      <Route path="/admin/dashboard/Branches/new" element={LazyLoadComponent(NewBranches)} />
      <Route path="/admin/dashboard/standards" element={LazyLoadComponent(Standards)} />
      <Route path="/admin/dashboard/standards/new" element={LazyLoadComponent(NewStandards)} />
      <Route path="/referee/dashboard" element={LazyLoadComponent(Referee)} />
      <Route path="/referee/dashboard/candidates" element={LazyLoadComponent(Clients)} />
      <Route path="/referee/dashboard/complete/nomination" element={LazyLoadComponent(EvaluationsCompleted)} />
      <Route path="/new/password" element={LazyLoadComponent(NewPassword)} />
      <Route path="/reports" element={LazyLoadComponent(Reports)} />
     
     

    </Routes>
  );
};

export default MyRoutes;