import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './arbitiators.css';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../auth/Login';
import REGIONS from "../../../common/REGIONS";
import CITIES from "../../../common/CITIES";
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';

const NewArbitator = () => {


  const [validationErrors, setValidationErrors] = useState({});
  const navigate = useNavigate();
  const [sortingStages, setSortingStages] = useState([]);
  const [branches, setBranches] = useState([]);

  const [region, setregion] = useState("");
  const [city, setcity] = useState("");
  const [filteredCities, setFilteredCities] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    ssn: '',
    phone: '',
    phone2: '',
    email: '',
    password: '',
    gender: '',
    nationality: '',
    birth_date: '',
    region: '',
    city: '',
    qualification: '',
    referee_type: '',
    sorting_stage: [],
    branches_id: [],
  });
  const [error, setError] = useState('');


  useEffect(() => {
    const token = localStorage.getItem('token');
    const user_type = localStorage.getItem('user_type');
    if (!token) {

      logout(navigate);
    }

    if (user_type === "referee" || user_type === "client") {
      navigate(-1);
    }
    const fetchSortingStages = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://te.prize.pap.sa/api/sorting/stage', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setSortingStages(response.data.sorting_stage);
      } catch (err) {
        console.error('Error fetching sorting stages:', err);
      }
    };

    const fetchBranches = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://te.prize.pap.sa/api/branches', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setBranches(response.data.branches);
      } catch (err) {
        console.error('Error fetching branches:', err);
      }
    };

    fetchSortingStages();
    fetchBranches();
  }, [navigate]);

  const sortingStageOptions = sortingStages.map(stage => ({ value: stage.id, label: stage.name }));
  const branchOptions = branches.map(branch => ({ value: branch.id, label: branch.name }));

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });


    if (name === 'ssn' && value.length !== 10) {
      setValidationErrors({
        ...validationErrors,
        ssn: ['رقم الهويه يجب ان يكون 10 ارقام']
      });
    } else {
      setValidationErrors({
        ...validationErrors,
        ssn: null
      });
    }
  };


  const handleRegionChange = (e) => {
    const selectedRegion = e.target.value;
    setregion(selectedRegion);
    const cities = CITIES.filter(city => city.region_id === parseInt(selectedRegion));
    setFilteredCities(cities);

    setcity("");
    setFormData({ ...formData, region: selectedRegion, city: "" });
  };

  const handleCityChange = (e) => {
    const selectedCity = e.target.value;
    setcity(selectedCity);
    setFormData({ ...formData, city: selectedCity });

  };



  const handleArrayChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: Array.from(e.target.selectedOptions, option => option.value)
    }));
  };

const handleSubmit = async (event) => {
  event.preventDefault();
  const token = localStorage.getItem("token");

  // Create a new object to modify phone numbers without altering the original formData state
  const modifiedFormData = {
    ...formData,
    // Check if phone and phone2 fields are not empty and prefix them with +966
    phone: formData.phone ? `+966${formData.phone}` : formData.phone,
    phone2: formData.phone2 ? `+966${formData.phone2}` : formData.phone2,
  };

  try {
    await axios.post("https://te.prize.pap.sa/api/referee", modifiedFormData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    navigate("/admin/dashboard/arbitrators");
    setValidationErrors({});
  } catch (err) {
    if (err.response && err.response.data.errors) {
      // Server-side validation errors
      setValidationErrors(err.response.data.errors);
    } else {
      setError("Failed to create referee. Please check the details.");
      console.error("Error creating referee:", err);
    }
  }
};


  const handleCheckboxChange = (event, itemId, fieldName) => {
    if (event.target.checked) {
      // Add itemId to the formData array
      setFormData(prevState => ({
        ...prevState,
        [fieldName]: [...prevState[fieldName], itemId]
      }));
    } else {
      // Remove itemId from the formData array
      setFormData(prevState => ({
        ...prevState,
        [fieldName]: prevState[fieldName].filter(item => item !== itemId)
      }));
    }
  };

  const calculateAge = (birthDate) => {
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const m = today.getMonth() - birth.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birth.getDate())) {
      age--;
    }
    return age;
  }
  const handleSortingStageChange = (selectedOptions) => {
    const values = selectedOptions.map(option => option.value);
    setFormData({ ...formData, sorting_stage: values });
  };

  const handleBranchesChange = (selectedOptions) => {
    const values = selectedOptions.map(option => option.value);
    setFormData({ ...formData, branches_id: values });
  };

  const handleDateChange = (event) => {
    const date = new Date(event.target.value);
    if (!isNaN(date)) {
      const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
      setFormData({ ...formData, birth_date: formattedDate });
    } else {
      console.error("handleDateChange: Invalid date");
    }
  };



  return (
    <div className="container-fluid mt-2">
      <div className="row admin_page_header">
        <div className="admin_page_title">اضافة جديد</div>
        <div className="admin_page_subtitle mt-2">المحكمين / اضافة جديد</div>
      </div>

      {error && <p style={{ color: "red", margin: "5px" }}>{error}</p>}
      <div className="admin_page_content col-12 row mb-4">
        <div className="form-container">
          <form onSubmit={handleSubmit}>
            <div class="row">
              <div className="form-group mb-5 col-md-6 col-xl-4">
                <label className="form-label">الاسم الاول</label>
                <i
                        className="fa-solid fa-star user_star"
                        style={{ color: "#fd1212" }}
                      ></i>
                <input
                  className="form-field form-control"
                  type="text"
                  name="first_name"
                  placeholder="الاسم الأول"
                  onChange={handleChange}
                  
                />
                {validationErrors.first_name && (
                  <span className="error-message text-danger">
                    {validationErrors.first_name[0]}
                  </span>
                )}
              </div>
              <div className="form-group mb-5 col-md-6 col-xl-4">
                <label className="form-label">اسم العائلة</label>
                <i
                        className="fa-solid fa-star user_star"
                        style={{ color: "#fd1212" }}
                      ></i>
                <input
                  className="form-field form-control"
                  type="text"
                  name="last_name"
                  placeholder="Last Name"
                  onChange={handleChange}
                  
                />
                {validationErrors.last_name && (
                  <span className="error-message text-danger">
                    {validationErrors.last_name[0]}
                  </span>
                )}
              </div>
              <div className="form-group mb-5 col-md-6 col-xl-4">
                <label className="form-label"> رقم الهوية الوطنية:</label>
                <i
                        className="fa-solid fa-star user_star"
                        style={{ color: "#fd1212" }}
                      ></i>
                <input
                  className="form-field form-control"
                  type="text"
                  name="ssn"
                  placeholder="رقم الهوية الوطنية "
                  onChange={handleChange}
                  
                  maxLength={10}
                />
                <span className="character-count">
                  {formData.ssn.length}/10
                </span>
                {validationErrors.ssn && (
                  <span className="error-message text-danger">
                    {validationErrors.ssn[0]}
                  </span>
                )}
              </div>
            </div>

            <div className="form-row row">
              <div className="form-group mb-5 col-md-6 col-xl-4">
                <label className="form-label">رقم الجوال :</label>
                <i
                        className="fa-solid fa-star user_star"
                        style={{ color: "#fd1212" }}
                      ></i>
                <input
                  className="form-field form-control"
                  type="text"
                  name="phone"
                  placeholder="رقم الجوال"
                  onChange={handleChange}
                  maxLength={9}
                  minLength={9}
                  
                />
                {validationErrors.phone && (
                  <span className="error-message text-danger">
                    {validationErrors.phone[0]}
                  </span>
                )}
              </div>
              <div className="form-group mb-5 col-md-6 col-xl-4">
                <label className="form-label">رقم الجوال البديل</label>
                <input
                  className="form-field form-control"
                  type="text"
                  name="phone2"
                  placeholder="رقم الجوال البديل"
                  onChange={handleChange}
                />
                {validationErrors.phone2 && (
                  <span className="error-message text-danger">
                    {validationErrors.phone2[0]}
                  </span>
                )}
              </div>
              <div className="form-group mb-5 col-md-6 col-xl-4">
                <label className="form-label">البريد الالكتروني</label>
                <i
                        className="fa-solid fa-star user_star"
                        style={{ color: "#fd1212" }}
                      ></i>
                <input
                  className="form-field form-control"
                  type="email"
                  name="email"
                  placeholder="البريد الالكتروني"
                  onChange={handleChange}
                  
                />
                {validationErrors.email && (
                  <span className="error-message text-danger">
                    {validationErrors.email[0]}
                  </span>
                )}
              </div>
            </div>

            <div className="form-row row">
              <div className="form-group mb-5 col-md-6 col-xl-4">
                <label className="form-label">كلمة المرور</label>
                <i
                        className="fa-solid fa-star user_star"
                        style={{ color: "#fd1212" }}
                      ></i>
                <input
                  className="form-field form-control"
                  type="password"
                  name="password"
                  placeholder="رمز التحقق"
                  onChange={handleChange}
                  
                />
                {validationErrors.password && (
                  <span className="error-message text-danger">
                    {validationErrors.password[0]}
                  </span>
                )}
              </div>
              <div className="form-group mb-5 col-md-6 col-xl-4">
                <label className="form-label">الجنس:</label>
                <i
                        className="fa-solid fa-star user_star"
                        style={{ color: "#fd1212" }}
                      ></i>
                <select
                  className="form-field form-control"
                  name="gender"
                  onChange={handleChange}
                  
                >
                  <option value="">اختر الجنس</option>
                  <option value="male">ذكر</option>
                  <option value="female">أنثى</option>
                </select>
                {validationErrors.gender && (
                  <span className="error-message text-danger">
                    {validationErrors.gender[0]}
                  </span>
                )}
              </div>
              <div className="form-group mb-5 col-md-6 col-xl-4">
                <label className="form-label">الجنسية:</label>
                <i
                        className="fa-solid fa-star user_star"
                        style={{ color: "#fd1212" }}
                      ></i>
                <select
                  className="form-field form-control"
                  name="nationality"
                  onChange={handleChange}
                  
                >
                  <option value="">اختار الجنسية</option>
                  <option value="sa">السعودية</option>
                  <option value="son_or_daughter_home">
                    ابن / ابنة مواطنة
                  </option>
                </select>
                {validationErrors.nationality && (
                  <span className="error-message text-danger">
                    {validationErrors.nationality[0]}
                  </span>
                )}
              </div>
            </div>

            <div className="form-row row mb-5">
              <div className="form-group mb-5 col-md-6 col-xl-4">
                <label>تاريخ الميلاد:</label>
                <i
                        className="fa-solid fa-star user_star"
                        style={{ color: "#fd1212" }}
                      ></i>
                <input
                  type="date"
                  className="form-control"
                  value={formData.birth_date || ''}
                  onChange={handleDateChange}
                  max={new Date().toISOString().split('T')[0]}
                />


                {validationErrors.birth_date && (
                  <span className="text-danger">
                    {validationErrors.birth_date[0]}
                  </span>
                )}
                {formData.birth_date && (
                  <p>العمر: {calculateAge(formData.birth_date)} سنه</p>
                )}
              </div>
              <div className="form-group mb-5 col-md-6 col-xl-4">
                <label htmlFor="region">المنطقة:</label>
                <i
                        className="fa-solid fa-star user_star"
                        style={{ color: "#fd1212" }}
                      ></i>
                <select
                  className="form-control"
                  id="region"
                  value={region}
                  onChange={handleRegionChange}
                >
                  <option value="">اختر المنطقة</option>
                  {REGIONS.map((region) => (
                    <option key={region.region_id} value={region.region_id}>
                      {region.name_ar}
                    </option>
                  ))}
                </select>
                {validationErrors.region && (
                  <span className="error-message text-danger">
                    {validationErrors.region[0]}
                  </span>
                )}
              </div>
              <div className="form-group mb-5 col-md-6 col-xl-4">
                <label htmlFor="city">المدينة:</label>
                <i
                        className="fa-solid fa-star user_star"
                        style={{ color: "#fd1212" }}
                      ></i>
                <select
                  className="form-control"
                  id="city"
                  value={city}
                  onChange={handleCityChange}
                >
                  <option value="">اختر المدينة</option>
                  {filteredCities.map((city) => (
                    <option key={city.city_id} value={city.city_id}>
                      {city.name_ar}
                    </option>
                  ))}
                </select>
                {validationErrors.city && (
                  <span className="error-message text-danger">
                    {validationErrors.city[0]}
                  </span>
                )}
              </div>

              <div className="form-group mb-5 col-md-6">
                <label className="form-label">المؤهل:</label>
                <i
                        className="fa-solid fa-star user_star"
                        style={{ color: "#fd1212" }}
                      ></i>
                <select
                  className="form-select"
                  name="qualification"
                  onChange={handleChange}
                  
                >
                  <option value="" disabled selected>
                    حدد
                  </option>
                  <option value="1">امي</option>
                  <option value="2">ابتدائي</option>
                  <option value="3">متوسط</option>
                  <option value="4">ثانوي</option>
                  <option value="5">دبلوم</option>
                  <option value="6">بكالوريوس</option>
                  <option value="7">ماجستير</option>
                  <option value="8">دكتوراه</option>
                </select>
                {validationErrors.qualification && (
                  <span className="error-message text-danger">
                    {validationErrors.qualification[0]}
                  </span>
                )}
              </div>

              <div className="form-group mb-5 col-md-6">
                <label className="form-label">نوع المحكم:</label>
                <i
                        className="fa-solid fa-star user_star"
                        style={{ color: "#fd1212" }}
                      ></i>
                <select
                  className="form-select"
                  name="referee_type"
                  onChange={handleChange}
                  
                >
                  <option value="">اختار نوع المحكم</option>
                  <option value="sort_references">محكم فرز</option>
                  <option value="grade_references">محكم تقييم</option>
                  <option value="social_media_references">
                    محكم سوشيال ميديا
                  </option>
                  <option value=" final_grade_referee">
                    محكم درجات نهائي 
                  </option>
                  <option value=" final_social_media_referee">
                  محكم سوشيال ميديا نهائي 
                  </option>
                 ,
                </select>
                {validationErrors.referee_type && (
                  <span className="error-message text-danger">
                    {validationErrors.referee_type[0]}
                  </span>
                )}
              </div>

              {/* <div className="checkbox-group col-md-6 ">
                <label className="form-label">Sorting Stage:</label>
                <Select
                  isMulti
                  options={sortingStageOptions}
                  onChange={handleSortingStageChange}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
                {validationErrors.sorting_stage && (
                  <span className="error-message text-danger">
                    {validationErrors.sorting_stage[0]}
                  </span>
                )}
              </div> */}

              <div className="checkbox-group col-md-6">
                <label className="form-label">الافرع:</label>
                <Select
                  isMulti
                  options={branchOptions}
                  onChange={handleBranchesChange}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
                {validationErrors.branches_id && (
                  <span className="error-message text-danger">
                    {validationErrors.branches_id[0]}
                  </span>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-center mb-5">
              <button type="submit" className="ms-2 user_info_btn_produce p-2">
                حفظ
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewArbitator;
