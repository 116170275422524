import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../context/UserContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CompanyLogo from '../../public/assets/logo.png';
import Swal from 'sweetalert2';


import "./auth.css";
export const Login = () => {
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { setUserName } = useUser();
  const [phoneTouched, setPhoneTouched] = useState(false);
  const [passwordTouched, setPasswordTouched] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {

      navigate(-1);
    }
  }, [navigate]);


  const handlePhoneBlur = () => {
    setPhoneTouched(true);
  };

  const handlePasswordBlur = () => {
    setPasswordTouched(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");

    try {
      const response = await axios.post("https://te.prize.pap.sa/api/login", {
        phone,
        password,
      });

      localStorage.setItem("user_type", response.data.user_type);
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("user_id", response.data.user_id);
      localStorage.setItem("userName", response.data.user_name);

      setUserName(response.data.user_name);

      switch (response.data.user_type) {
        case "super_admin":
          toast.success("تم التسجيل بنجاح", {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          window.location.href = "/admin/dashboard";
          break;
        case "employee":
          toast.success("تم التسجيل بنجاح", {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          if (response.data.is_first_login == 0) {
            window.location.href = "new/password";
            break;
          }

          window.location.href = "/admin/dashboard";
          break;
        case "referee":

          if (response.data.is_first_login == 0) {
            window.location.href = "new/password";
            break;
          }
          window.location.href = "referee/dashboard/candidates";
          break;

        case "client":
          setError("You are not authorized to access this application.");
          logout(navigate);
          break;

        default:
          setError("You are not authorized to access this application.");
          localStorage.removeItem("token");
          localStorage.removeItem("user_type");
          localStorage.removeItem("user_id");
          localStorage.removeItem("user_name");
          return;
      }
    } catch (err) {
      if (err.response.data.error) {
      
        toast.error(err.response.data.error, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setError(err.response.data.error);
      } else {
        toast.error("جميع الحقول مطلوبة", {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      }
    }
  };

  const sendCode = async (phoneNumber, navigate) => {
    try {
      const response = await axios.post('https://te.prize.pap.sa/api/admin/reset/password', {
        phone: phoneNumber
      });
      navigate('/verification', { state: { phone: phoneNumber } });
    } catch (error) {
      console.error('Error:', error.response ? error.response.data.error : error.message);
      throw new Error(error.response ? error.response.data.error : 'حدث خطأ في الشبكة. الرجاء المحاولة مرة أخرى.');
    }
  };
  
  
  const handleClick = () => {
    Swal.fire({
      title: 'هل تريد ارسال كود تأكيد إلى جوالك؟',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
        placeholder: 'أدخل رقم هاتفك'
      },
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'نعم',
      cancelButtonText: 'لا',
      showLoaderOnConfirm: true,
      preConfirm: (phoneNumber) => {
        if (!phoneNumber) {
          Swal.showValidationMessage('تحتاج إلى إدخال رقم هاتفك');
          return false;
        } else {
          return sendCode(phoneNumber, navigate).catch(error => {
            Swal.showValidationMessage(`Request failed: ${error.message}`);
          });
        }
      },
      inputValidator: (value) => {
        return !value && 'تحتاج إلى إدخال رقم هاتفك!'
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'تم الارسال!',
          text: 'تم ارسال كود التأكيد إلى جوالك.',
          icon: 'success',
        });
      }
    });
  };
  
  return (
    <div>
      <ToastContainer />
      <div className="container mt-5">
        <div className="row user_row_contain d-flex">
          <div className="admin_login_form ">
            <div className="w-100 admin_logo_welcom row d-flex justify-content-center align-items-center">
              <div className="col-8 p-3 text-white">
                <div className="admin_login_welcom">
                  اهلا بعودتك !
                </div>
                <div className="admin_login_welcom2">
                  قم بتسجيل الدخول لحسابك
                </div>
              </div>
              <div className="col-3 p-3">
                <img src={CompanyLogo} alt="" className="admin_login_logo" />
              </div>
            </div>
            <form onSubmit={handleSubmit} className="p-3">
              <div className="form-group mb-3">
                <label className="admin_login_label">رقم الجوال</label>
                <input
                  className="form-control"
                  type="text"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  onBlur={handlePhoneBlur}
                />
                {phoneTouched && !phone && <div className="error-message  text-danger mt-2 ">رجاء ادخل رقم الجوال</div>}
              </div>
              <div className="form-group mb-3">
                <label className="admin_login_label">كلمة المرور</label>
                <input
                  className="form-control"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onBlur={handlePasswordBlur}
                />
                {passwordTouched && !password && <div className="error-message text-danger mt-2"> رجاء ادخل كلمة المرور </div>}
              </div>
              <div>
              <div className="form-group mb-2 mt-3">
      <label htmlFor="flexCheckDefault" className="mx-2 admin_login_label" onClick={handleClick}>نسيت كلمة المرور</label>
    </div>

</div>

              <button type="submit" className="w-100 admin_login_sibmit">
                تسجيل الدخول
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export const logout = async (navigate) => {
  try {

    const client_id = localStorage.getItem("client_id");

    if (client_id) {
      localStorage.removeItem("token");
      localStorage.removeItem("user_type");
      localStorage.removeItem("user_id");
      localStorage.removeItem("userName");
      localStorage.removeItem("client_id");
      localStorage.removeItem("user_login");
      window.location.href = "/";
    }

    const token = localStorage.getItem("token");

    await axios.post(
      `https://te.prize.pap.sa/api/logout`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );



    localStorage.removeItem("token");
    localStorage.removeItem("user_type");
    localStorage.removeItem("user_id");
    localStorage.removeItem("user_name");
    localStorage.removeItem("user_login");

    window.location.href = "/";
  } catch (err) {
    console.error("Logout failed", err);
  }



  localStorage.removeItem("token");
  localStorage.removeItem("user_type");
  localStorage.removeItem("user_id");
  localStorage.removeItem("user_name");
  localStorage.removeItem("user_login");

  window.location.href = "/";

};
