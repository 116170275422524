import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { logout } from "../auth/Login";
import "./profile.css";

const Profile = () => {
  const [activeSection, setActiveSection] = useState("عامة");
  const [userData, setUserData] = useState(null);
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    // Retrieve client_id from localStorage
    const client_id = localStorage.getItem("user_id");

    if (client_id) {
      // Fetch user data using the client_id
      axios
        .get(`https://te.prize.pap.sa/api/user/profile/${client_id}`)
        .then((response) => {
          setUserData(response.data);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });
    }
  }, []); // The empty dependency array ensures that this effect runs only once, similar to componentDidMount

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangePassword = (event) => {
    event.preventDefault();
    const user_id = localStorage.getItem("user_id");

    axios
      .post("https://te.prize.pap.sa/api/client/cheangePassword", {
        user_id,
        currentPassword: formData.currentPassword,
        password: formData.newPassword,
        confirmPassword: formData.confirmPassword,
      })
      .then((response) => {
        logout(navigate);
      })
      .catch((error) => {
        if (error.response) {
          let errorMessage = error.response.data.error;

          if (errorMessage === "Current password is incorrect") {
            errorMessage = "كلمه المرور الحإلى غير متطابق";
          } else if (errorMessage === "confirmPassword must be same password") {
            errorMessage =
              "رمز التحقق التأكيدي غير متطابق، الرجاء إعادة الكتابة";
          } else {
            errorMessage = "الرجاء إعادة التغيير! جميع الحقول مطلوبة";
          }

          Swal.fire({
            title: "Error!",
            text: errorMessage,
            icon: "error",
            confirmButtonText: "حسنا", // 'OK' button text
          });
        }
      });
  };

  const handleButtonClick = (section) => {
    setActiveSection(section);
  };
  const userPossessionArabic = {
    super_admin: "مدير عام",
    employee: "موظف",
    client: "عميل",
    referee: "محكم",
  };
  const refereeTypeArabic = {
    sort_references: "فرز",
    grade_references: "تقييم درجات",
    social_media_references: "مراجع وسائل التواصل الاجتماعي",
    final_grade_referee: "تقييم درجات نهائية",
    final_social_media_referee: "مراجع وسائل التواصل الاجتماعي  نهائية",
  };

  return (
    <div className="container pt-2">
      <div className="row mt-5">
        <div className="user_account_links row">
          <div className="user_account_buttons row">
            <div className="col-md-6">
              {" "}
              <div className="user_account_title ">معلومات عامة</div>
              {userData && (
                <>
                  <div className="mt-3">
                    <div className="user_account_public_contain">
                      الاسم:{" "}
                      <span className="user_account_data">
                        {" "}
                        {userData.user_name}
                      </span>
                    </div>
                  </div>
                  <div className="mt-3">
                    <div className="user_account_public_contain">
                      الصفة:{" "}
                      <span className="user_account_data">
                        {userPossessionArabic[userData.user_possession]}
                      </span>
                    </div>
                  </div>
                  {userData.user_possession === "referee" &&
                    userData.referee_type && (
                      <div className="mt-3">
                        <div className="user_account_public_contain">
                          نوع الحكم:{" "}
                          <span className="user_account_data">
                            {refereeTypeArabic[userData.referee_type]}
                          </span>
                        </div>
                      </div>
                    )}

                  <div className="col-12 mt-3">
                    <div className="user_account_public_contain">
                      العنوان:{" "}
                      <span className="user_account_data">
                        {userData.nationality}  {userData.city}
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="col-md-6">
              <div className="user_account_title col-md-6">معلومات التواصل</div>
              {userData && (
                <>
                  <div className="mt-3">
                    <div className="user_account_public_contain">
                      رقم الهاتف:{" "}
                      <span className="user_account_data">
                        {" "}
                        {userData.phone}
                      </span>
                    </div>
                  </div>
                  <div className="mt-3">
                    <div className="user_account_public_contain">
                      رقم الهاتف البديل:{" "}
                      <span className="user_account_data">
                        {" "}
                        {userData.phone2}{" "}
                      </span>
                    </div>
                  </div>
                  <div className="mt-3">
                    <div className="user_account_public_contain">
                      البريد الالكتروني:{" "}
                      <span className="user_account_data">
                        {userData.email}
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="user_account_title">الامان</div>
            <form onSubmit={handleChangePassword}>
              <div className="row user_account_form_policy">
                <div className="form-group mt-4 mb-3">
                  <label htmlFor="currentPassword">كلمة المرور الحالية</label>
                  <input
                    type="password"
                    className="form-control"
                    id="currentPassword"
                    name="currentPassword"
                    placeholder="كلمة المرور الحالية"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="newPassword">كلمة المرور الجديدة</label>
                  <input
                    type="password"
                    className="form-control"
                    id="newPassword"
                    name="newPassword"
                    placeholder="كلمة المرور الجديدة"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="confirmPassword">تأكيد كلمة المرور</label>
                  <input
                    type="password"
                    className="form-control"
                    id="confirmPassword"
                    name="confirmPassword"
                    placeholder="تأكيد كلمة المرور"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="w-100 d-flex align-items-center">
                  <button className="user_account_btn" type="submit">
                    تحديث كلمة المرور
                  </button>
                  <div className="mx-2 user_account_confirm_message">
                    بعد تحديث كلمة المرور ستحتاج إلى تسجيل الدخول مرة أخرى
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
