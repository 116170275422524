import React from "react";

const MyFooter = () => {
    return (
      <div
        className="row d-flex align-items-center"
        style={{
          height: "80px",
          backgroundColor: "transparent",
          color: "gray",
        }}
      >
        <div className="col-6 admin_footer_copy" style={{ fontWeight: "bold" }}>
          جميع الحقوق محفوظة © 2024
        </div>
        <div
          className="col-6  d-flex justify-content-end admin_footer_copy"
          style={{ fontWeight: "bold" }}
        >
          جائزة الأمير عبد الإله بن عبد العزيز
        </div>
      </div>
    );
};

export default MyFooter;
